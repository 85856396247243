import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import SelectGameModal from '../../components/SelectGameModal';

/**
 * Encapsulates the button and the modal.
 */
class SelectGameContainer extends Component {
    static propTypes = {
        onSelectGame: PropTypes.func.isRequired,
        selectedGame: PropTypes.object          // if there is no game selected, this will be null
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            selectGameDialogOpen: false,
        }
    }

    onClickSelectGame = () => {
        this.setState({
            selectGameDialogOpen: true
        });
    };
    handleCloseSelectGame = () => {
        this.setState({
            selectGameDialogOpen: false
        });
    };
    handleSelectGame = gameInfo => {
        this.setState({
            selectGameDialogOpen: false,
        });

        this.props.onSelectGame(gameInfo);
    };


    render() {
        const { selectedGame } = this.props;
        const { selectGameDialogOpen } = this.state;

        return (
            <React.Fragment>
                <Button variant="outlined" color="primary" onClick={this.onClickSelectGame}>
                    Select Game
                </Button>
                <SelectGameModal
                    open={selectGameDialogOpen}
                    selectedGame={selectedGame ? selectedGame.gameId : '-1'}
                    onCancel={this.handleCloseSelectGame}
                    onSelectGame={this.handleSelectGame}
                />
            </React.Fragment>
        );
    }
}

export default SelectGameContainer;
