import { db } from './firebase';

// Used to load details of a game that aren't in our feed.  e.g. searching for a new game.
// We definitely call gameDetailLoaded to make sure it gets into our redux state,
// but we also return the new game info from our Promise in case the caller needs to 
// know when the data has been returned to the client.
export const loadGameDetail = (gameId, gameDetailLoaded) => {
    return db.collection('games').doc(gameId).get().then(doc => {
        if (doc.exists) {
            const gameInfo = Object.assign({ id: gameId }, doc.data());        // add the id into the object
            gameDetailLoaded(gameInfo);
            return Promise.resolve(gameInfo);
        } else {
            console.error('No such game: ', gameId);
            return Promise.reject('Invalid gameId');
        }
    }).catch(err => {
        console.error('Error retrieving gameInfo');
        return Promise.reject(err);
    });
}
