import { GAMEDETAIL_LOADED } from '../actions/gamelist';

/*
 * Most game information is loaded via the feed in DataLoad.  However, if the user wants to add a new game,
 * we need information about the game (game name and picture) before it gets added to the feed.  So we can
 * make one-off requests to load individual games, and store that data here, and then merge it into the
 * firestore feed list.
 *
 * gameDetail:
 * { gameId: [gameInfo], gameId: [gameInfo] }
 */
const initialState = {};
export default function gameDetailReducer(state = initialState, action) {
    switch (action.type) {
        case GAMEDETAIL_LOADED:
            return Object.assign({}, state, { [action.gameInfo.id]: action.gameInfo });

        default:
            return state;
    }
}
