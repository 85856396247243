import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    hr: {
        fontSize: '12px',
        textAlign: 'center',
        marginTop: '24px',
        marginBottom: '24px',
        borderTop: '1px solid #ccccd1',
        height: '1px'
    },
    hrText: {
        display: 'inline-block',
        padding: '0 12px',
        transform: 'translateY(-60%)',
        backgroundColor: '#fff'
    }
    
};

function HrDiv(props) {
    const { classes, label } = props;

    return (
        <div className={classes.hr}>
            <div className={classes.hrText}>{label}</div>
        </div>
    );
}

HrDiv.propTypes = {
    label: PropTypes.string.isRequired,
};

export default withStyles(styles)(HrDiv);
