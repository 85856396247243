import React from 'react';
import './GoogleButton.css';

function GoogleButton(props) {
    const { onClick } = props;

    return (
        <button onClick={onClick} className="google_btn">
            <span className="google_icon_wrapper">
                <img className="google_icon" alt="" src="./googleicon.svg"/>
            </span>
            <span className="google_text">Sign in with Google</span>
        </button>
    );
}

export default GoogleButton;
