// https://github.com/GoogleCloudPlatform/stackdriver-errors-js

import StackdriverErrorReporter from 'stackdriver-errors-js';
import config from '../firebase/FirebaseConfig';

let errorHandler = null;

if (process.env.NODE_ENV === 'production') {
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
        key: config.apiKey, // no idea why the renamed this ...
        projectId: config.projectId,
        // Other optional arguments can be supplied, see above.
    });
} /*else {
    errorHandler = { report: console.error };
}*/

// The stackdriver library doesn't log errors to console.  I want to see exceptions in the log, even in production for now.
export default function wrapper(error) {
    console.error(error);
    if (errorHandler) errorHandler.report(error);
}

//export default errorHandler;