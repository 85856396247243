
// We will use the reducer pattern to iterate through the list and compose a new list, with all the 
// appropriate data in one place.
// feedList:   [ session, session ]
// playerList: { playerId: {...}, playerId: {...} }
// gameList:   { gameId:   {...}, gameId:   {...} }
export const mergeData = (feedList, playerList, gameList) => {

    const sessionReducer = (session) => {
        const newScores = Object.keys(session.scores).map(key => {
            return scoreReducer(session.scores[key]);
        })
        
        // We add the id fields to the game and player objects so that we have them throughout the app
        return Object.assign({}, session, { scores: newScores, gameInfo: Object.assign({id: session.gameId}, gameList[session.gameId]) });
    }

    const scoreReducer = (score) => {
        return Object.assign({}, score, { playerInfo: Object.assign({id: score.playerId}, playerList[score.playerId]) });
    }

    const result = feedList.map(session => {
        return sessionReducer(session);
    });

    //console.log('mergeData feedlist: \n', JSON.stringify(result, null, 2));
    return result;
};
