import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IMAGE_THUMBNAIL_URL } from '../../constants';

const styles = {
    titleText: {
        flexGrow: 1,
        marginRight: 0,
        color: '#fff',
        marginLeft: 16,
        overflow: 'hidden'
    }
};

class GridBox extends React.Component {

    static propTypes = {
        imgUrl: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    };

    render() {
        const {classes, imgUrl, title, link} = this.props;

        return (
            <div className="gridbox_wrapper">
            <div className="gridbox">
                <Link to={link}>
                    <img className="gridbox_img" src={`${IMAGE_THUMBNAIL_URL}${imgUrl}`} alt={title} />
                    <div className="gridbox_title">
                        <Typography variant="subtitle1" color="inherit" className={classes.titleText} noWrap={true}>
                            {title}
                        </Typography>
                    </div>
                </Link>
            </div>
            </div>
        );
    }
}

export default withStyles(styles)(GridBox);