import React, { Component } from 'react';
import { auth } from '../firebase';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value
});

const INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    error: null
};

class PasswordChangeForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { passwordOne } = this.state;

        auth.doPasswordUpdate(passwordOne)
            .then(() => {
                this.setState(() => ({ ...INITIAL_STATE }));
            })
            .catch(error => {
                this.setState(byPropKey('error', error));
            });

        event.preventDefault();
    };

    render() {
        const { passwordOne, passwordTwo, error } = this.state;

        const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

        return (
            <div>
                <h1>Change Password</h1>
                <div className="apphome_authform_content">
                    <form onSubmit={this.onSubmit}>
                        <div className="auth_form">
                            <Input className="formControl"
                                value={passwordOne}
                                onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
                                type="password"
                                placeholder="New Password"
                            />
                            <Input className="formControl"
                                value={passwordTwo}
                                onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
                                type="password"
                                placeholder="Confirm New Password"
                            />
                            <Button className="formControl" variant="contained" color="primary" disabled={isInvalid} type="submit">
                                Reset My Password
                            </Button>

                            {error && <p>{error.message}</p>}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default PasswordChangeForm;
