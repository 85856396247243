
import { SAVE_CLEARED, SAVE_STARTED, SAVE_COMPLETED, DELETE_STARTED, DELETE_COMPLETED } from '../actions/actions.js';

/*
 */
const initialState = {
    isSaving: false,
    lastSavedSessionId: null,
    lastSavedSession: null,
    isDeleting: false,
    lastDeletedSessionId: null,
    isCompleted: false
};
export default function savestateReducer(state = initialState, action) {

    switch (action.type) {
        case SAVE_CLEARED:
            // do not clear lastSaveSession or id
            return Object.assign({}, state, {
                isSaving: false,
                isDeleting: false,
                isCompleted: false
            });

        case SAVE_STARTED: 
            return Object.assign({}, state, {
                isSaving: true,
                lastSavedSessionId: null,
                lastSavedSession: null,
                isDeleting: false,
                lastDeletedSessionId: null,
                isCompleted: false,
            });

        case SAVE_COMPLETED:
            return Object.assign({}, state, {
                isSaving: true,
                lastSavedSessionId: action.sessionId,
                lastSavedSession: Object.assign({}, action.sessionInfo, { mockSession: true }),  // we add a little mock flag so that we know if it is the real thing or not
                isDeleting: false,
                lastDeletedSessionId: null,
                isCompleted: true
            });

        case DELETE_STARTED:
            return Object.assign({}, state, {
                isSaving: false,
                lastSavedSessionId: null,
                lastSavedSession: null,
                isDeleting: true,
                lastDeletedSessionId: null,
                isCompleted: false
            });

        case DELETE_COMPLETED:
            return Object.assign({}, state, {
                isSaving: false,
                lastSavedSessionId: null,
                lastSavedSession: null,
                isDeleting: true,
                lastDeletedSessionId: action.sessionId,
                isCompleted: true
            });

    default:
        return state;
    }
}