import React from 'react';

import { auth } from '../firebase';

const SignOutButton = () => (
    <div>
        <h1>Signout</h1>
        <button type="button" onClick={auth.doSignOut}>
            Sign Out
        </button>
    </div>
);

export default SignOutButton;
