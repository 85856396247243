import { connect } from 'react-redux';
import { compose } from 'recompose';
import { firebaseConnect, firestoreConnect, isLoaded } from 'react-redux-firebase';
import { purgeUserInfo } from '../../redux/actions/actions';
import Settings from './Settings';

const mapStateToProps = state => {
    return {
        userInfo: isLoaded(state.firebase.profile) ? state.firebase.profile : {}
    };
}

const mapDispatchToProps = dispatch => {
    return {
        purgeUserInfo: () => dispatch(purgeUserInfo())
    };
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect()
)(Settings);