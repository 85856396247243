import { connect } from 'react-redux';
import FeedList from './FeedList';

const mapStateToProps = (state, props) => {

    let gameId = props.gameId;
    let playerId = props.playerId;
/*
    if (!playerId) {
        playerId = state.firebase.profile.pid;
    }*/

    // having a gameId instructs us to index stats by gameId, and takes precedence over playerId
    if (gameId) {
        playerId = null;
    }

    return {
        gameId,
        playerId,
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

const FeedListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(FeedList);

export default FeedListContainer;
