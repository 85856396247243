import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as routes from '../../constants/routes';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import StatsListContainer from './StatsListContainer';
import FeedItem from './FeedItem';
import { TYPE_BYGAME, TYPE_BYPLAYER } from './StatsList';
import Cache from '../../utils/Cache';
import GameInfoTab from './GameInfoTab';
import Footer from '../../components/Footer';
//import TopGames from './TopGames';
import './Feed.css';

function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired
};

const DEFAULT_TAB = 0;

const styles = theme => {
    //console.error(JSON.stringify(theme, null, 2));
    return {
        tab: {
            backgroundColor: theme.palette.secondary.main,
            textTransform: 'lowercase'
        },
        paper: {
            padding: 0
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2)
        }
    };
};

// We are going to use localStorage to keep track of which tab is open, so that as we
// navigate through Games/Players it always goes to the same tab.
const CACHE_LAST_TAB = 'CACHE_LAST_TAB';

class FeedList extends React.Component {
    constructor(props) {
        super(props);

        let val = 0;
        if (props.defaultTabIndex) {
            val = props.defaultTabIndex;
        } else {
            val = Cache.get(CACHE_LAST_TAB, DEFAULT_TAB);
        }
        this.state = {
            value: val
        };
    }

    handleChange = (event, value) => {
        this.setState({ value });
        Cache.add(CACHE_LAST_TAB, value);
    };

    handleChangeIndex = index => {
        this.setState({ value: index });
        Cache.add(CACHE_LAST_TAB, index);
    };

    render() {
        const { gameId, playerId, activityFeed, activityFeedStats, includeImages, classes, theme } = this.props;

        // A gameId means we are displaying game information and playerId should be null
        // use this to do the game layout.
        let lastView = null;
        let lastLabel = null;
        if (gameId && !playerId) {
            lastView = <GameInfoTab gameId={gameId} />;
            lastLabel = 'Game Info';
        } else {
            lastView = (
                <StatsListContainer
                    stats={activityFeedStats.byPlayer}
                    gameId={gameId}
                    playerId={playerId}
                    type={TYPE_BYPLAYER}
                />
            );
            lastLabel = 'By Player';
        }

        // TODO:  the link to add session should include the gameId and/or playerId and auto select them on addSession

        const list = activityFeed.map((session, index) => {
            return <FeedItem session={session} key={index} includeImages={includeImages} />;
        });
        if (list.length === 0) {
            list.push(
                <Typography key="0" variant="body1" color="primary">
                    No games.
                </Typography>
            );
        }

        return (
            <div className="feedlist">
                <Paper square className={classes.paper}>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        centered={true}
                    >
                        <Tab className={classes.tab} label="By Date" />
                        <Tab className={classes.tab} label="By Game" />
                        <Tab className={classes.tab} label={lastLabel} />
                    </Tabs>
                </Paper>
                <SwipeableViews
                    className={classes.paper}
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={this.state.value}
                    onChangeIndex={this.handleChangeIndex}
                >
                    <TabContainer className={classes.paper} dir={theme.direction}>
                        {list}
                    </TabContainer>
                    <TabContainer dir={theme.direction}>
                        <StatsListContainer
                            stats={activityFeedStats.byGame}
                            gameId={gameId}
                            playerId={playerId}
                            type={TYPE_BYGAME}
                        />
                    </TabContainer>
                    <TabContainer dir={theme.direction}>{lastView}</TabContainer>
                </SwipeableViews>

                <Fab color="primary" aria-label="Add" className={classes.fab} component={Link} to={routes.ADD_SESSION}>
                    <AddIcon />
                </Fab>
                <Footer />
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FeedList);
