import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { firebaseConnect, firestoreConnect, isLoaded } from 'react-redux-firebase';
import { updateStats } from '../redux/actions/stats';
import Loading from '../components/Loading';
import FTUInit from '../views/activityfeed/FTUInit';

/**
 * This entire file creates a HOC that requests our main data.  These requests
 * are all async, and will fill up the state over time.  I'm not putting
 * anything into the props, so there shouldn't be any additional renders
 * (at this level) caused by the data loads.
 */
class DataLoad extends React.Component {
    render() {
        // TODO:  given this, I should be able to go remove a lot of "isLoaded(...)" references in various controllers.
        const { loaded, playerId, profileLoaded } = this.props;

        // If we have profile, but no playerId, then this is a new user, and we need to wait for the cloud function to init the new user
        if (profileLoaded && (!playerId || playerId === undefined)) {
            return <FTUInit />;
        }

        if (!loaded) {
            return <Loading />;
        }

        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

const mapStateToProps = state => {
    // let's make it easier and don't render if we don't have these lists ...
    const loaded =
        isLoaded(state.firebase.profile) && // TODO:  this should maybe be a bit higher??
        isLoaded(state.firestore.data.feedList) &&
        isLoaded(state.firestore.data.gameList) &&
        isLoaded(state.firestore.data.playerList) &&
        isLoaded(state.firestore.data.userRelationships);

    // Odd Note:  this can't be empty or the firebaseConnect() will never fire
    return {
        userId: isLoaded(state.firebase.auth) ? state.firebase.auth.uid : null,
        playerId: isLoaded(state.firebase.profile) ? state.firebase.profile.pid : null,
        loaded,
        profileLoaded: isLoaded(state.firebase.profile)
        /*        feedList: state.firestore.ordered.feedList,
        gameList: state.firestore.ordered.gameList,
        playerList: state.firestore.ordered.playerList*/
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // This isn't quite right ... may need a subcomponent that only renders when the feed is fully loaded ...
        updateStats: statsData => dispatch(updateStats(statsData))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect(props => {
        let result = [];

        if (props.userId) {
            result = [
                {
                    collection: 'feeds',
                    doc: props.userId,
                    subcollections: [{ collection: 'sessions' }],
                    storeAs: 'feedList',
                    orderBy: [['date', 'desc'], ['lastUpdated', 'desc']]
                },
                {
                    collection: 'feeds',
                    doc: props.userId,
                    subcollections: [{ collection: 'games' }],
                    storeAs: 'gameList'
                },
                {
                    collection: 'feeds',
                    doc: props.userId,
                    subcollections: [{ collection: 'players' }],
                    storeAs: 'playerList'
                }
            ];
        }
        if (props.playerId) {
            result.push({
                collection: 'relationships',
                doc: props.playerId,
                storeAs: 'userRelationships'
            });
        }

        return result;
    })
)(DataLoad);
