// Requires props to have a expansionsLoaded() bound as a dispatch function.
// Optionally provide prop expansionsList with the current set of expansions to avoid duplicate queries.
export const loadExpansions = props => gameId => {
    const { firestore, expansionsList, expansionsLoaded } = props;
    /**
     * We could do this, but the storeAs doesn't do well for nested collections (with the same name).
     * We also don't need listeners on this data, it really doesn't change very often (ever??).
     *
    props.firestore.get({
        collection: 'games',
        doc: gameId,
        subcollections: [{ collection: 'expansions' }],
        storeAs: `gameExpansions-${gameId}`
    })*/

    if (expansionsList) {
        if (expansionsList[gameId]) {
            console.warn(`We already have expansions loaded for ${gameId}.`);
            return;
        }
    } else {
        console.warn('You did not provide expansionsList as part of props for loadExpansions()');
    }

    firestore
        .collection('games')
        .doc(gameId)
        .collection('expansions')
        .get()
        .then(snapshot => {
            const expansions = [];

            if (snapshot.empty) {
                console.error('There are no expansions for gameId ', gameId);
                props.expansionsLoaded({ [gameId]: expansions });
                return;
            }

            snapshot.forEach(doc => {
                //console.log(doc.id, '=>', doc.data());
                expansions.push(Object.assign({}, { id: doc.id }, doc.data()));
            });
            expansionsLoaded({ [gameId]: expansions });
        })
        .catch(err => {
            console.error('Error retrieving expansions for gameId: ', gameId, err);
        });
};

// TODO: not used yet ... should be used after feed loads!
export const loadMultipleExpansions = props => arrayGameIds => {
    arrayGameIds.forEach(gameId => {
        loadExpansions(props)(gameId);
    });
};

// Used to load details of a game that aren't in our feed.  e.g. searching for a new game.
export const loadGameDetail = props => gameId => {
    const { firestore, gameDetailLoaded } = props;
    firestore.collection('games').doc(gameId).get().then(doc => {
        if (doc.exists) {
            gameDetailLoaded(doc.data());
        } else {
            console.error('No such game: ', gameId)
        }
    }).catch(err => {
        console.error('Error retrieving gameInfo');
    });
}

// Requires ...
export const saveNewSession = props => (sessionInfo, onCompleted, onError) => {
    const { firestore } = props;
    firestore
        .add(
            { collection: 'sessions' },
            Object.assign({}, sessionInfo, { lastUpdated: firestore.FieldValue.serverTimestamp() })
        )
        .then(doc => {
            console.log(`Added new session with id [${doc.id}]`);
            onCompleted(doc.id, sessionInfo);
        })
        .catch(err => {
            onError('Error saving new Session', err.message);
        });
};
export const saveEditSession = props => (sessionId, sessionInfo, onCompleted, onError) => {
    const { firestore } = props;
    firestore
        .set(
            { collection: 'sessions', doc: sessionId },
            Object.assign({}, sessionInfo, { lastUpdated: firestore.FieldValue.serverTimestamp() })
        )
        .then(() => {
            onCompleted(sessionId, sessionInfo);
        })
        .catch(err => {
            onError('Error Editing Session', err.message);
        });
};

export const deleteSession = props => (sessionId, onCompleted, onError) => {
    props.firestore
        .delete({ collection: 'sessions', doc: sessionId })
        .then(() => {
            onCompleted(sessionId);
        })
        .catch(err => {
            onError('Error Deleting Session', err.message);
        });
};

export const addToWishlist = props => gameInfo => {
    addToList(props, gameInfo, 'gamesWishlist');
};
export const removeFromWishlist = props => gameInfo => {
    removeFromList(props, gameInfo, 'gamesWishlist');
};
export const addToOwnedlist = props => gameInfo => {
    // Note the extra specialHack parameter here ... not clean, but trying to at least be obvious :)
    return addToList(props, gameInfo, 'gamesOwnedlist', true);
};
export const removeFromOwnedlist = props => gameInfo => {
    removeFromList(props, gameInfo, 'gamesOwnedlist');
};

const addToList = (props, gameInfo, listName, specialHack) => {
    const { firestore, userId } = props;
    console.log('addToList', props, gameInfo, listName);

    firestore
        .runTransaction(txn => {
            txn.update(firestore.collection('users').doc(userId), {
                [listName]: firestore.FieldValue.arrayUnion(gameInfo.id)
            });
            // special hack for addToOwnedlist() ... remove from wishlist when adding to owned list
            if (specialHack) {
                txn.update(firestore.collection('users').doc(userId), {
                    gamesWishlist: firestore.FieldValue.arrayRemove(gameInfo.id)
                });
            }
            // { collection: 'feeds', doc: playerId, subcollections: [{ collection: 'games', doc: gameInfo.id }]}
            txn.set(
                firestore
                    .collection('feeds')
                    .doc(userId)
                    .collection('games')
                    .doc(gameInfo.id),
                gameInfo
            );

            return Promise.resolve({ status: 'all good now' }); // throws an error if we don't return a promise ... not sure exactly what to return
        })
        .then(() => {
            console.log(`GameId ${gameInfo.id} added to wishlist.`);
            return 'game added to wishlist';
        })
        .catch(err => {
            //onError('Error Deleting Session', err.message, err.code);
            console.error('Error in addToWishlist:  ', err);
            //debugger;
            return err;
        });
}

const removeFromList = (props, gameInfo, listName) => {
    const { firestore, userId } = props;

    // TODO:  query the feed/sessions and see if we can delete the game
    firestore
        .update({ collection: 'users', doc: userId }, { [listName]: firestore.FieldValue.arrayRemove(gameInfo.id) })
        .then(() => {
            console.log(`GameId ${gameInfo.id} removed from wishlist.`);
        })
        .catch(err => {
            //onError('Error Deleting Session', err.message, err.code);
            console.error('Error in removeFromWishlist:  ', err);
        });
}