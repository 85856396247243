import React, { Component } from 'react';
import './App.css';
import AppRoutes from './AppRoutes';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './MeeplesMaterialTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';

class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <AppRoutes />
            </MuiThemeProvider>
        );
    }
}

export default App;
