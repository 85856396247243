import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

const styles = theme => ({
    close: {
        padding: theme.spacing(0.5)
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
});

class SystemSnackbar extends Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,

        variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
        message: PropTypes.string, // if null, we won't display anything
        handleClose: PropTypes.func.isRequired // caller responsible for clearing message content
    };

    render() {
        const { classes, variant, message, handleClose } = this.props;

        if (!message) {
            return '';
        }

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={message != null}
                autoHideDuration={null}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    className: classes[variant] // pass the color to the SnackbarContent object
                }}
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Alert severity={variant}>{message}</Alert>
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        );
    }
}

export default withStyles(styles)(SystemSnackbar);
