import { createStore, applyMiddleware } from 'redux';
import firebase from 'firebase/app';
import { createFirestoreInstance } from 'redux-firestore';
import thunk from 'redux-thunk';
import { initialState, rootReducer } from '../reducers/index';
import { logger, crashReporter } from '../middleware/middleware';
import havocapi from '../middleware/havocapi';

import { compose } from 'redux';

console.log('Initializing Redux: Starting');

const enhancers = [];
//const reduxDevToolsExtension = window.devToolsExtension;
const reduxDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
if (process.env.NODE_ENV === 'development' && typeof reduxDevToolsExtension === 'function') {
    enhancers.push(reduxDevToolsExtension());
}

// Put the dev tool extension last, as it wraps actions in a "PERFORM_ACTION" action wrapper.
// https://stackoverflow.com/questions/49436872/what-is-perform-action-action-type-inside-redux
const composedEnhancers = compose(
    applyMiddleware(thunk, havocapi, logger, crashReporter),
    ...enhancers
);


const store = createStore(rootReducer, initialState, composedEnhancers);

export default store;

// react-redux-firebase config
const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

export const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
};

