import React, { Component } from 'react';
import { compose } from 'recompose';
import { firebaseConnect } from 'react-redux-firebase';
import { Link, withRouter } from 'react-router-dom';
import { SignInLink } from './SignIn';
import * as routes from '../constants/routes';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Login from './LoginContainer';

const SignUpPage = () => (
    <div className="apphome_authform">
        <div className="apphome_authform_title">
            <Typography variant="h4" color="primary">
                Sign Up
            </Typography>
        </div>
        <div className="apphome_authform_content">
            <Login />
            <div className="login_with_email_label">
                <Typography variant="button" color="primary">
                    or sign up with your email address
                </Typography>
            </div>

            <RoutedSignUpForm />
            <SignInLink />
        </div>
    </div>
);

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value
});

const INITIAL_STATE = {
    nickName: '',
    email: '',
    password: '',
    passwordVerify: '',
    error: null
};

class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password, nickName } = this.state;
        const { history, firebase } = this.props;

        firebase
            .createUser({ email, password }, { email, nickName })
            .then(userData => {
                console.log('createUser', userData);
                history.push(routes.FEED);
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState(byPropKey('error', error));
            });

        // TODO:  eh?  what does this do?  do we need it??
        event.preventDefault();
    };

    render() {
        const { nickName, email, password, passwordVerify, error } = this.state;

        const isInvalid = password !== passwordVerify || password === '' || email === '' || nickName === '';

        return (
            <form onSubmit={this.onSubmit}>
                <div className="auth_form">
                    <Input
                        className="formControl"
                        value={nickName}
                        onChange={event => this.setState(byPropKey('nickName', event.target.value))}
                        type="text"
                        placeholder="Name"
                    />
                    <Input
                        className="formControl"
                        value={email}
                        onChange={event => this.setState(byPropKey('email', event.target.value))}
                        type="text"
                        placeholder="Email Address"
                    />
                    <Input
                        className="formControl"
                        value={password}
                        onChange={event => this.setState(byPropKey('password', event.target.value))}
                        type="password"
                        placeholder="Password"
                    />
                    <Input
                        className="formControl"
                        value={passwordVerify}
                        onChange={event => this.setState(byPropKey('passwordVerify', event.target.value))}
                        type="password"
                        placeholder="Confirm Password"
                    />
                    <Button
                        className="formControl"
                        variant="contained"
                        color="primary"
                        disabled={isInvalid}
                        type="submit"
                    >
                        Sign Up
                    </Button>

                    {error && <p>{error.message}</p>}
                </div>
            </form>
        );
    }
}
const RoutedSignUpForm = compose(withRouter, firebaseConnect())(SignUpForm);

const SignUpLink = () => (
    <Typography variant="body1" color="inherit">
        Don't have an account? <Link to={routes.SIGN_UP}>Sign Up</Link>
    </Typography>
);

export default SignUpPage;

export { SignUpForm, SignUpLink };
