import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import GamesIcon from '@material-ui/icons/LibraryBooks';
import * as routes from '../constants/routes';
import { Link } from 'react-router-dom';

const styles = {
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    meeplesImage: {
        height: 30
    }
};

class MyAppBar extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        playerList: PropTypes.object.isRequired,
        gameList: PropTypes.object.isRequired,
        sessionList: PropTypes.object.isRequired
    };

    /*
    componentDidMount() {
        const {loadMyknows} = this.props;

        loadMyknows();
    }
    */

    // the match param comes from props passed down by react-router
    mapPathToTitle(match, playerList, gameList, sessionList) {
        switch (match.path) {
            case routes.FEED:
                return 'Your Activity Feed';

            case routes.SETTINGS:
                return 'Settings';

            case routes.ADD_SESSION:
                return 'Add';

            case routes.EDIT_SESSION:
                return 'Edit';

            case routes.FRIENDS:
                return 'Friends';

            case routes.PLAYER_INFO:
                let playerInfo = playerList[match.params.id];
                if (playerInfo) {
                    return `${playerInfo.nickName}'s Activity Feed`;
                }
                return 'Activity Feed';

            case routes.GAME_INFO:
                let gameInfo = gameList[match.params.id];
                if (gameInfo) {
                    return gameInfo.name;
                }
                return 'Game Information';

            case routes.SESSION_INFO:
                let sessionInfo = sessionList[match.params.id];
                if (sessionInfo) {
                    let gameInfo = gameList[sessionInfo.gameId];
                    if (gameInfo) {
                        return gameInfo.name;
                    }
                }
                return 'Gaming Session';

            case routes.GAMELIST:
                return 'Your Games';

            default:
                return '';
        }
    }
    //                             <img className={classes.meeplesImage} src={'/Meeples.svg'} alt="Meeples" />

    render() {
        const { classes, match, playerList, gameList, sessionList } = this.props;
        const title = this.mapPathToTitle(match, playerList, gameList, sessionList);
        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <Button
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="Meeples"
                            component={Link}
                            to={routes.FEED}
                        >
                            <div className="meeplesImage" />
                        </Button>
                        <Typography variant="h6" color="inherit" className={`${classes.grow} appbar_title_meeples`}>
                            Meeples
                        </Typography>
                        <Typography variant="h6" color="inherit" className={`${classes.grow} appbar_title`}>
                            {title}
                        </Typography>
                        <Button color="inherit" component={Link} to={routes.FRIENDS}>
                            <PeopleIcon />
                        </Button>
                        <Button color="inherit" component={Link} to={routes.GAMELIST}>
                            <GamesIcon />
                        </Button>
                        <Button color="inherit" component={Link} to={routes.SETTINGS}>
                            <SettingsIcon />
                        </Button>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        playerList: state.firestore.data.playerList || {},
        gameList: state.firestore.data.gameList || {},
        sessionList: state.firestore.data.feedList || {}
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyAppBar));
