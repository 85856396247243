import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import About from './containers/About';
import SettingsContainer from './views/settings/SettingsContainer';
import ViewSessionContainer from './views/viewsession/ViewSessionContainer';
import ViewGameContainer from './containers/ViewGameContainer';
import ViewPlayerContainer from './containers/ViewPlayerContainer';
import EditSession from './views/addsession/EditSession';
import NewSession from './views/addsession/NewSession';
import ActivityFeedContainer from './views/activityfeed/ActivityFeedContainer';
import FriendsListContainer from './views/friendslist/FriendsListContainer';
import GameListContainer from './views/gamelist/GameListContainer';
import AppMain from './AppMain';
import NotFound from './NotFound';
import PageBody from './components/PageBody';
import MktgPage from './AppHome/MktgPage';
import SignIn from './AppHome/SignIn';
import SignOut from './AppHome/SignOut';
import SignUp from './AppHome/SignUp';
import PasswordForget from './AppHome/PasswordForget';
import PasswordChange from './AppHome/PasswordChange';
import * as routes from './constants/routes';

const MktgRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        <MktgPage>
            <Component {...props}/>
        </MktgPage>
    )} />
);

const AppRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        <PageBody match={props.match}>
            <Component {...props}/>
        </PageBody>
    )} />
);

const AppRoutes = props => {
    /**
     * See https://github.com/ReactTraining/react-router/issues/3928
     * Go down to "ryanflorence commented on Feb 25, 2017"
     */
    return (
        <main>
            <Switch>
                <MktgRoute exact path={routes.LANDING} component={AppMain} />
                <MktgRoute path={routes.SIGN_IN} component={SignIn} />
                <MktgRoute path={routes.SIGN_OUT} component={SignOut} />
                <MktgRoute path={routes.LOGIN} component={SignIn} />
                <MktgRoute path={routes.LOGOUT} component={SignOut} />
                <MktgRoute path={routes.SIGN_UP} component={SignUp} />
                <MktgRoute path={routes.PASSWORD_FORGET} component={PasswordForget} />
                <MktgRoute path={routes.PASSWORD_CHANGE} component={PasswordChange} />

                <AppRoute path={routes.FEED} component={ActivityFeedContainer} />
                <AppRoute path={routes.SETTINGS} component={SettingsContainer} />
                <AppRoute path="/app/about" component={About} />
                <AppRoute path={routes.SESSION_INFO} component={ViewSessionContainer} />
                <AppRoute path={routes.GAME_INFO} component={ViewGameContainer} />
                <AppRoute path={routes.PLAYER_INFO} component={ViewPlayerContainer} />
                <AppRoute path={routes.ADD_SESSION} component={NewSession} />
                <AppRoute path={routes.EDIT_SESSION} component={EditSession} />
                <AppRoute path={routes.FRIENDS} component={FriendsListContainer} />
                <AppRoute path={routes.GAMELIST} component={GameListContainer} />

                <Route component={NotFound} />
            </Switch>
        </main>
    );
};

// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
// this version worked fine
export default withRouter(AppRoutes);