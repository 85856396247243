import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SelectPlayerModal from '../../components/SelectPlayerModal';
import PlayerAvatar from '../../components/PlayerAvatar';

const styles = theme => ({
    appBar: {
        position: 'relative'
    },
    flex: {
        flex: 1
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 100,
        align: 'right',
        textAlign: 'right'
    },
    buttonRow: {
        paddingTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    saveButton: {
        //paddingRight: '30px',
        '& button': {
            marginRight: '30px'
        }
    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} />
  ));

class EditScoreDialog extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
        handleDeleteScore: PropTypes.func.isRequired,
        score: PropTypes.object.isRequired,
        playerList: PropTypes.array.isRequired // used when adding a new player
    };

    constructor(props) {
        super(props);

        const { playerId, nickName, score, position, isWinner } = this.props.score;

        this.state = {
            // if no player selected, default the select player dialog open
            selectPlayerIsOpen: !playerId ? true : false,

            // init our state from the data passed in props
            playerId,
            nickName,
            score,
            position,
            isWinner
        };
    }

    onScoreChange = event => {
        this.setState({ score: event.target.value });
    };

    onPositionChange = event => {
        this.setState({ position: event.target.value });
    };

    handleToggleWinner = () => {
        const { isWinner } = this.state;
        this.setState({ isWinner: !isWinner });
    };

    /* You can no longer change the selected player when editing a row.  Pick a player when creating a row.
    handleClickOpen = () => {
        this.setState({ selectPlayerIsOpen: true });
    };*/

    handleCloseSelectPlayerDialog = () => {
        const { handleClose } = this.props;
        this.setState({ selectPlayerIsOpen: false });
        handleClose();
    };

    onSelectPlayer = playerInfo => {
        this.setState({
            selectPlayerIsOpen: false,
            playerId: playerInfo.id,
            nickName: playerInfo.nickName
        });
    };

    onDeleteScore = () => {
        const { handleDeleteScore, handleClose } = this.props;
        if (this.props.score.playerId) {
            // if this is null, then it was a new player row, and we don't delete it, we just close the dialog
            handleDeleteScore();
        } else {
            handleClose();
        }
    };

    onSave = () => {
        const { handleSave } = this.props;
        const { playerId, nickName, score, position, isWinner } = this.state;

        handleSave({
            playerId,
            nickName,
            score,
            position,
            isWinner
        });
    };

    render() {
        const { classes, isOpen, playerList, handleClose } = this.props;
        const { selectPlayerIsOpen, playerId, nickName, score, position, isWinner } = this.state;
        let name = nickName;
        if (!playerId) {
            name = 'Click to select Player';
        }

        //console.log('EditScoreDialog.render()', this.state);

        // We don't actually have a playerInfo object, but PlayerAvatar just needs a few fields ...
        const playerInfo = { playerId, nickName };

        return (
            <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Edit
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem>
                        {playerId ? (
                            <ListItemAvatar>
                                <PlayerAvatar playerId={playerId} nolink/>
                            </ListItemAvatar>
                        ) : '' }
                        <ListItemText primary={name} />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="Score" />
                        <ListItemSecondaryAction>
                            <TextField
                                id="textinput-score"
                                type="number"
                                className={classes.textField}
                                margin="dense"
                                value={score}
                                onChange={this.onScoreChange}
                                inputProps={{ align: 'right' }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="Position" />
                        <ListItemSecondaryAction>
                            <TextField
                                id="textinput-position"
                                type="number"
                                className={classes.textField}
                                margin="dense"
                                value={position}
                                onChange={this.onPositionChange}
                                inputProps={{ align: 'right' }}
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="Winner" />
                        <ListItemSecondaryAction>
                            <Switch color="primary" onChange={this.handleToggleWinner} checked={isWinner} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>

                {selectPlayerIsOpen ? (
                    <SelectPlayerModal
                        open={selectPlayerIsOpen}
                        selectedPlayer={playerId}
                        playerList={playerList}
                        onCancel={this.handleCloseSelectPlayerDialog}
                        onSelectPlayer={this.onSelectPlayer}
                    />
                ) : (
                    ''
                )}

                <div className={classes.buttonRow}>
                    <div className={classes.saveButton}>
                        <Button color="secondary" variant="contained" onClick={this.onDeleteScore}>
                            Remove Player
                        </Button>
                        <Button color="primary" variant="contained" onClick={this.onSave}>
                            Save
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles)(EditScoreDialog);
