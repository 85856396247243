import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => {
    //console.error(JSON.stringify(theme, null, 2));
    return ({
        header: {
            backgroundColor: theme.palette.secondary.main,
            height: 50,
            paddingLeft: 20,
            paddingRight: 20
        },
        winnerRow: {
            backgroundColor: theme.palette.primary.main,
            height: 50,
            color: 'white'
        },
        loserRow: {
            backgroundColor: theme.palette.secondary.main,
            height: 50
        }
    });
}

class ViewScores extends Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        scores: PropTypes.array.isRequired
    };

    getScoresArray = () => {
        const { scores } = this.props;

        return Object.keys(scores).map(key => {
            return scores[key];
        }).sort((a, b) => a.position - b.position);
    }

    render() {
        const { classes } = this.props;

        const playerScores = this.getScoresArray().map((score, index) => {
            return (
                <tr key={index+1}  className={score.isWinner ? classes.winnerRow : classes.loserRow}>
                    <td className='vievscores_td'>
                        <Typography variant="body1" color="inherit">
                            {score.playerInfo.nickName}
                        </Typography>
                    </td>
                    <td className='vievscores_td' align='right'>
                        <Typography variant="body1" color="inherit">
                            {score.score}
                        </Typography>
                    </td>
                </tr>
            );
        });

        return (
            <div className='viewscores'>
                <table className='viewscores_table'>
                    <thead>
                        <tr key='1' className={classes.header}>
                            <td className='vievscores_td'>
                                <Typography variant="body1" color="inherit">PLAYER</Typography>
                            </td>
                            <td className='vievscores_td' align='right'>
                                <Typography variant="body1" color="inherit">SCORE</Typography>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {playerScores}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ViewScores);
