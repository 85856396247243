import { connect } from 'react-redux';
import { mergeData } from '../utils/FeedMerge';
import { generateStats } from '../utils/GenerateStats';
import ViewPlayer from './ViewPlayer';

const mapStateToProps = (state, props) => {
    const playerId = props.match.params.id;
    // TODO:  url params can be modified by user easily - how do we validate?

    // TODO:  filter this to only games involving playerId ...
    const feedList = state.firestore.ordered.feedList || [];
    const gameList = state.firestore.data.gameList || {};
    const playerList = state.firestore.data.playerList || {};

    // Filter the feed to only this game ...
    const filteredFeed = feedList.filter(sessionInfo => {
        if (sessionInfo.scores[playerId]) return true;
        return false;
    });
    const mergedFilteredFeed = mergeData(filteredFeed, playerList, gameList);
    const activityFeedStats = generateStats(mergedFilteredFeed, gameList, playerList);
    
    return {
        feedList: mergedFilteredFeed,
        activityFeedStats,
        playerId
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

const ViewPlayerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewPlayer);

export default ViewPlayerContainer;
