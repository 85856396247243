import React from 'react';
import PropTypes from 'prop-types';
import FeedListContainer from '../views/activityfeed/FeedListContainer';
import Loading from '../components/Loading';

class ViewPlayer extends React.Component {

    static propTypes = {
        feedList: PropTypes.array,              // will be null if it isn't loaded yet
        activityFeedStats: PropTypes.object,
        playerId: PropTypes.string.isRequired,
    };

    render() {
        const { feedList, activityFeedStats, playerId } = this.props;

        if (!feedList) {
            return <Loading />
        }

        return (
            <div className="afDiv">
                <FeedListContainer playerId={playerId} activityFeed={feedList} activityFeedStats={activityFeedStats} includeImages={true}/>
            </div>
        );
    };
}

export default ViewPlayer;
