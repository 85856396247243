import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { errorEncountered } from '../redux/actions/actions';
import firebase from 'firebase/app';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Slide from '@material-ui/core/Slide';
import Loading from './Loading';

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} />
  ));

class AddPlayerModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
        handleSaveNewPlayer: PropTypes.func,

        savedPlayerInfo: PropTypes.object,
        errorMsg: PropTypes.string,

        errorEncountered: PropTypes.func.isRequired,
        fullScreen: PropTypes.bool.isRequired // see withMobileDialog below
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            validationError: false,
            nickName: '',
            email: '',
            isSaving: false
        };
    }

    handleFirstNameChange = event => {
        this.setState({
            nickName: event.target.value
        });
    };
    handleEmailChange = event => {
        this.setState({
            email: event.target.value
        });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        const { handleClose } = this.props;

        this.setState({ open: false });
        handleClose();
    };

    handleSave = () => {
        const { handleSaveNewPlayer, errorEncountered } = this.props;
        const { nickName, email } = this.state;

        // Simple validation - we only need a nick name
        if (!nickName || nickName.length === 0) {
            this.setState({ validationError: true });
            return;
        }

        this.setState({ isSaving: true });
        const ref = this;

        var addMessage = firebase.functions().httpsCallable('addPlayer');
        addMessage({ nickName, email })
            .then(function(result) {
                // Read result of the Cloud Function.
                var results = result.data;
                console.log('addPlayer result: ', results);

                ref.setState({ isSaving: false, open: false, nickName: '', email: '' });  // reset the state so that we don't dirty the next addPlayer ...
                if (handleSaveNewPlayer) {
                    handleSaveNewPlayer(results);
                }
            })
            .catch(function(error) {
                console.error('AddPlayer failed!  ', error);
                errorEncountered('Error adding Friend', error.message);
                ref.setState({ isSaving: false, open: true });
            });

        //this.setState({ open: false });
    };

    render() {
        const { fullScreen, open } = this.props;
        const { validationError, isSaving } = this.state;

        return (
            <div>
                <Dialog
                    open={open}
                    fullScreen={fullScreen}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Add Friend</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To add a Friend, simply enter a nickname. If you enter an email address, we will try to
                            connect you up with them.
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            id="nickname"
                            label="Nickname"
                            type="text"
                            fullWidth
                            onChange={this.handleFirstNameChange}
                            required={true}
                            error={validationError}
                            autoFocus
                            disabled={isSaving}
                        />
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            disabled={isSaving}
                            onChange={this.handleEmailChange}
                        />
                    </DialogContent>
                    {isSaving ? (
                        <Loading />
                    ) : (
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleSave} color="primary">
                                Add
                            </Button>
                        </DialogActions>
                    )}
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        errorEncountered: (userMessage, devMessage) => dispatch(errorEncountered(userMessage, devMessage))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withMobileDialog()(AddPlayerModal));
