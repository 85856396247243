import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Loading.css';

const styles = {};

const Loading = (props) => {
    const { classes } = props;

    return (
        <div className="loading">
            <CircularProgress className={classes.progress} />
        </div>
    );
};

export default withStyles(styles)(Loading);

