import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SelectGameListItem from './SelectGameListItem';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import AddGameModal from './AddGameModal';
import * as AllGames from './AllGames';
import { loadGameDetail } from '../firebase/methods';
import { gameDetailLoaded } from '../redux/actions/gamelist';

const styles = theme => ({
    appBar: {
        position: 'relative'
    },
    flex: {
        flex: 1
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
    searchDiv: {
        display: 'flex',
        flexWap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    searchField: {
        width: '100%',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} />
  ));

class SelectGameModal extends Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSelectGame: PropTypes.func.isRequired,

        // Optional:  If the caller provides this list, it will override the recentGamesList
        defaultList: PropTypes.array,

        recentGamesList: PropTypes.array.isRequired,
        selectedGame: PropTypes.string, // null if no selection
    };

    constructor(props) {
        super(props);

        this.state = {
            showAddGameModal: false,
            searchTerm: '',
            searchResults: []
        };
    }

    componentDidMount() {
        AllGames.loadAllGamesData();
    }

    handleClickAddGame = () => {
        this.setState({
            showAddGameModal: true
        });
    };
    handleCloseAddGameDialog = () => {
        this.setState({
            showAddGameModal: false
        });
    };
    handleSaveNewGame = gameInfo => {
        this.handleCloseAddGameDialog();
        this.handleGameClick(gameInfo);
    };

    handleGameClick = gameInfo => {
        const { onSelectGame, onGameDetailLoad } = this.props;

        // We provide two params to onSelectGame.  The first is the result from the search, to let the UI update 
        // and close the dialog.  If this is an existing game it has all of the game details.  If this game
        // is not in our feed, then it only contains an id and a name.
        // The second param we return is a promise that will resolve when we have all the game details.  This 
        // is primarily used on the GameList view.
        let promise = null;
        if (!gameInfo.imageUrl && !gameInfo.bggid) {
            promise = loadGameDetail(gameInfo.id, onGameDetailLoad);
        } else {
            promise = Promise.resolve(gameInfo)
        }
        onSelectGame(gameInfo, promise);
    };

    onSearchInputChange = event => {
        const value = event && event.currentTarget && event.currentTarget.value;
        if (value === undefined || value === null) return;

        this.setState({
            searchTerm: value,
            searchResults: AllGames.search(value)
        });
    };

    getGameList() {
        const { defaultList, recentGamesList, selectedGame } = this.props;
        const { searchTerm, searchResults } = this.state;
        let list = defaultList ? defaultList : recentGamesList;
        if (searchTerm.length > 0) {
            list = searchResults;
        }

        const result = [];
        if (!list) {
            return result;
        }
        for (const gameInfo of list) {
            result.push(
                <SelectGameListItem
                    key={gameInfo.id}
                    selected={gameInfo.id === selectedGame}
                    gameInfo={gameInfo}
                    onClick={this.handleGameClick}
                />
            );
            result.push(<Divider key={gameInfo.id + 'divider'} />);
        }

        return result;
    }

    render() {
        const { classes, open, onCancel } = this.props;
        const { searchTerm, showAddGameModal } = this.state;

        return (
            <Dialog fullScreen open={open} onClose={onCancel} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={onCancel} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Select Game
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.searchDiv}>
                    <TextField
                        id="standard-search"
                        label="Search"
                        type="search"
                        autoFocus
                        className={classes.searchField}
                        margin="normal"
                        value={searchTerm}
                        onChange={this.onSearchInputChange}
                    />
                    <SearchIcon />
                </div>
                <Divider />
                <List>{this.getGameList()}</List>
                <AddGameModal
                    handleClose={this.handleCloseAddGameDialog}
                    handleSaveNewGame={this.handleSaveNewGame}
                    open={showAddGameModal}
                />
                <Fab color="primary" aria-label="Add" className={classes.fab} onClick={this.handleClickAddGame}>
                    <AddIcon />
                </Fab>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        recentGamesList: state.firestore.ordered.gameList || [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGameDetailLoad: gameInfo => dispatch(gameDetailLoaded(gameInfo))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SelectGameModal));