import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import SelectExpansionsModal from '../../components/SelectExpansionsModal';

/**
 * Encapsulates the button and the modal.
 */
class SelectExpansionsContainer extends Component {
    static propTypes = {
        onSelectExpansions: PropTypes.func.isRequired,
        selectedGame: PropTypes.object,          // if there is no game selected, this will be null
        selectedExpansions: PropTypes.array,      // ditto
        expansionsList: PropTypes.array          // The list of expansions for this game.
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            dialogOpen: false,
        }
    }

    onClickSelect = () => {
        this.setState({
            dialogOpen: true
        });
    };
    handleCancel = () => {
        this.setState({
            dialogOpen: false
        });
    };
    handleSave = expansionsList => {
        this.setState({
            dialogOpen: false,
        });

        this.props.onSelectExpansions(expansionsList);
    };


    render() {
        const { selectedGame, selectedExpansions, expansionsList } = this.props;
        const { dialogOpen } = this.state;

        if ( !selectedGame )  {
            return null;
        }
        if ( !expansionsList || expansionsList.length === 0 ) {
            return null;
        }

        return (
            <React.Fragment>
                <Button variant="outlined" color="primary" onClick={this.onClickSelect}>
                    Select Expansions
                </Button>
                <SelectExpansionsModal
                    open={dialogOpen}
                    selectedGame={selectedGame}
                    expansionsList={expansionsList}
                    selectedExpansions={selectedExpansions}
                    onCancel={this.handleCancel}
                    onSelectExpansions={this.handleSave}
                />
            </React.Fragment>
        );
    }
}
 
const mapStateToProps = (state, ownProps) => {

    let expansionsList = null;
    if (ownProps.selectedGame) {
        expansionsList = state.expansions[ownProps.selectedGame.id];
    }

    return {
        expansionsList
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectExpansionsContainer);
