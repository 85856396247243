import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import { SignUpLink } from './SignUp';
import { SignInLink } from './SignIn';
import * as routes from '../constants/routes';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

const PasswordForgetPage = () => (
    <div className="apphome_authform">
        <div className="apphome_authform_title">
            <Typography variant="h4" color="primary">
                Forgot Password
            </Typography>
        </div>
        <div className="apphome_authform_content">
            <PasswordForgetForm />
            <SignInLink />
            <SignUpLink />
        </div>
    </div>
);

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value
});

const INITIAL_STATE = {
    email: '',
    error: null
};

class PasswordForgetForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email } = this.state;

        auth.doPasswordReset(email)
            .then(() => {
                this.setState(() => ({ ...INITIAL_STATE }));
            })
            .catch(error => {
                this.setState(byPropKey('error', error));
            });

        event.preventDefault();
    };

    render() {
        const { email, error } = this.state;

        const isInvalid = email === '';

        return (
            <form onSubmit={this.onSubmit}>
                <div className="auth_form">
                    <Input className="formControl"
                        value={this.state.email}
                        onChange={event => this.setState(byPropKey('email', event.target.value))}
                        type="text"
                        placeholder="Email Address"
                    />
                    <Button className="formControl" variant="contained" color="primary" disabled={isInvalid} type="submit">
                        Reset My Password
                    </Button>

                    {error && <p>{error.message}</p>}
                </div>
            </form>
        );
    }
}

const PasswordForgetLink = () => (
    <Typography variant="body1" color="inherit">
        <Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
    </Typography>
);

export default PasswordForgetPage;

export { PasswordForgetForm, PasswordForgetLink };
