import ErrorLogger from '../../utils/ErrorLogger';

/**
 * Logs all actions and states after they are dispatched.
 */
const logger = store => next => action => {
    console.group(action.type);
    console.info('dispatching', action);
    let result = next(action);
    console.log('next state', store.getState(), result);
    console.groupEnd();
    return result;
};

/*
const prelogger = store => next => action => {
    console.log('prelogger entry', action);

    let result = next(action);

    console.log('prelogger exit', action, result);
    return result;
}*/

const crashReporter = store => next => action => {
    try {
        const result = next(action);
        return result;
    } catch (error) {
        console.error('Crash Reporter caught an error: ', error);
        ErrorLogger(error);
        //throw err;
    }
};

export { logger, crashReporter };
