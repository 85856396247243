import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FriendNameContainer from './FriendNameContainer';

const styles = {
    // #555555
    title: {
        color: "#555555"
    }
};

class FriendBox extends React.Component {

    static propTypes = {
        friendList: PropTypes.array.isRequired,
        friendType: PropTypes.oneOf(['friend', 'incoming', 'outgoing'])
    };

    render() {
        const { classes, friendList, friendType } = this.props;

        let title = '';
        switch (friendType) {
            default:
            case 'friend':
                title = 'Friends';
                break;
            case 'incoming':
                title = 'Friend Requests';
                break;
            case 'outgoing':
                title = 'Invites Sent';
                break;
        }

        const list = friendList.map((friend, index) => {
            return <FriendNameContainer key={index} friend={friend} friendType={friendType}/>;
        });

        return (
            <div className="friendbox">
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
                <div className="friendboxlist">
                    {list}
                </div>
            </div>
        );
    }
};


export default withStyles(styles)(FriendBox);
