import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../../components/Loading';
import FeedListContainer from './FeedListContainer'
import FTUInit from './FTUInit';
import FTUView from './FTUView';

class ActivityFeed extends React.Component {

    static propTypes = {
        isProfileLoaded: PropTypes.bool.isRequired,
        playerId: PropTypes.string,     // may be null for new users

        isFeedLoaded: PropTypes.bool.isRequired,
        activityFeed: PropTypes.array,      // null if it hasn't finished loading yet
        activityFeedStats: PropTypes.object
    };

    componentDidUpdate() {
        if (!this.loadedExpansions && this.props.isLoaded) {
            this.oneTimeExpansionsLoad();
        }
    }

    loadedExpansions = false;       // lame singleton
    oneTimeExpansionsLoad = () => {
        this.loadedExpansions = true;

        // Load expansions for games that we haven't loaded expansions for
        // TODO:  could optimize this further to only load expansions if there are sessions for that game that use expansions
        const { gameList, expansionsList, loadMultipleExpansions } = this.props;
        const expansionsNeeded = [];
        gameList.forEach(game => {
            if ( !expansionsList[game.id] ) {
                expansionsNeeded.push(game.id);
            }
        });
        if (expansionsNeeded.length > 0) {
            loadMultipleExpansions(expansionsNeeded);
        }
    }

    render() {
        const { isFeedLoaded, activityFeed, activityFeedStats, isProfileLoaded, playerId } = this.props;

        // In the case of a first time user, the feed will never load, because there is no data whatsoever.
        // Once the cloud function has initialized, we will get an empty feed.
        const isFirstTimeUser = isProfileLoaded && (!playerId || playerId === undefined);
        const isEmptyFeed = isFeedLoaded && activityFeed.length === 0;

        // If we have profile, but no playerId, then this is a new user, and we need to wait for the cloud function to init the new user
        if (isFirstTimeUser) {
            return <div className="afDiv"><FTUInit /></div>;
        }
        // If we have a playerId, but no feed content, then show the first time use experience
        if (isEmptyFeed) {
            return <div className="afDiv"><FTUView /></div>;
        }

        // This is the more usual experience ...
        if (!isFeedLoaded) {
            return <div className="afDiv"><Loading /></div>;
        }
        return (
            <div className="afDiv">
                <FeedListContainer activityFeed={activityFeed} activityFeedStats={activityFeedStats} includeImages={true}/>
            </div>
        );
    }
}

export default ActivityFeed;
