import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { SignUpLink } from './SignUp';
import { PasswordForgetLink } from './PasswordForget';
import { auth } from '../firebase';
import * as routes from '../constants/routes';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Login from './LoginContainer';

const SignInPage = ({ history }) => (
    <div className="apphome_authform">
        <div className="apphome_authform_title">
            <Typography variant="h4" color="primary">
                Login
            </Typography>
        </div>
        <div className="apphome_authform_content">
            <Login />
            <div className="login_with_email_label">
                <Typography variant="button" color="primary">
                    Or log in with email
                </Typography>
            </div>
            <SignInForm history={history} />
            <PasswordForgetLink />
            <SignUpLink />
        </div>
    </div>
);

const byPropKey = (propertyName, value) => () => ({
    [propertyName]: value
});

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null
};

class SignInForm extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;

        const { history } = this.props;

        auth.doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState(() => ({ ...INITIAL_STATE }));
                history.push(routes.FEED);
            })
            .catch(error => {
                this.setState(byPropKey('error', error));
            });

        event.preventDefault();
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <form onSubmit={this.onSubmit}>
                <div className="auth_form">
                    <Input
                        className="formControl"
                        value={email}
                        onChange={event => this.setState(byPropKey('email', event.target.value))}
                        type="text"
                        placeholder="Email Address"
                    />
                    <Input
                        className="formControl"
                        value={password}
                        onChange={event => this.setState(byPropKey('password', event.target.value))}
                        type="password"
                        placeholder="Password"
                    />
                    <Button variant="contained" color="primary" disabled={isInvalid} type="submit">
                        Sign In
                    </Button>

                    {error && <p>{error.message}</p>}
                </div>
            </form>
        );
    }
}

const SignInLink = () => (
    <Typography variant="body1" color="inherit">
        Have an account? <Link to={routes.SIGN_IN}>Login</Link>
    </Typography>
);

export default withRouter(SignInPage);

export { SignInForm, SignInLink };
