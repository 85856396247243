import { 
    ACTIVITY_FEED_SUCCESS, 
    GET_SESSION_SUCCESS,
    USER_INFO_SUCCESS, 
    USER_GAMELIST_SUCCESS,
    GET_GAME_SUCCESS,
    ADD_CUSTOM_GAME_SUCCESS
} from '../actions/actions';
import { ACTIVITY_FEED_STATS_SUCCESS } from '../actions/stats';

/**
 * LIST CACHE
 * 
 * This reducer listens to various messages and pulls in a list of 
 * Players & Games.  It then creates a hash for fast lookup.
 * 
 * We are combining Player and Game because both come in as part of 
 * the Activity Feed and we only want to iterate that list once,
 * given that the loop hits each session and score.
 * 
 * These are caches of all possible players and games that show up.
 * These lists can include games and players from other users' 
 * feeds, not just this users' data.
 */
const initialState = {
    playerList: {},         // hash of player objects: { "playerId": {...playerInfo}, ... }
    playerByAuthId: {},     // hash of players with non null authIds: { "authId": {...playerInfo}, ... }
    gameList: {}, 
};
export function listcacheReducer(state = initialState, action) {
    switch (action.type) {

        // The User is also a Player, so add them to the Player list
        case USER_INFO_SUCCESS:
        {
            const newPlayers = {};
            action.response.playswith.forEach((player) => {
                newPlayers[player.playerId] = player;
            });
            const newPlayerByAuthId = {};
            action.response.playswith.forEach((player) => {
                if (player.authId) {
                    newPlayerByAuthId[player.authId] = player;
                }
            });
            const newGames = {};
            action.response.hasplayed.forEach((game) => {
                newGames[game.gameId] = game;
            });

            // according to one page, the last duplicate wins
            return Object.assign({}, {
                playerList: {
                    [action.response.playerId]: action.response, // the user is a player too!
                    ...state.playerList,
                    ...newPlayers
                },
                playerByAuthId: {
                    ...state.playerByAuthId,
                    ...newPlayerByAuthId
                },
                gameList: { 
                    ...state.gameList,
                    ...newGames
                }
            });
        }


        case ACTIVITY_FEED_SUCCESS:
        {
            // we convert to a hash here for faster lookup (as this list could get long, and is used a lot)
            let updatedGameList = Object.assign({}, state.gameList);
            let updatedPlayerList = Object.assign({}, state.playerList);
            let updatedPlayerByAuthId = Object.assign({}, state.playerByAuthId);
            action.response.forEach((session) => {
                processSession(session, updatedGameList, updatedPlayerList, updatedPlayerByAuthId);
            });

            return Object.assign({}, state, {
                gameList: updatedGameList,
                playerList: updatedPlayerList,
                playerByAuthId: updatedPlayerByAuthId
            });
        }

        case GET_SESSION_SUCCESS: 
        {
            let updatedGameList = Object.assign({}, state.gameList);
            let updatedPlayerList = Object.assign({}, state.playerList);
            let updatedPlayerByAuthId = Object.assign({}, state.playerByAuthId);

            // action.response is a single session in this case
            processSession(action.response, updatedGameList, updatedPlayerList, updatedPlayerByAuthId);

            return Object.assign({}, state, {
                gameList: updatedGameList,
                playerList: updatedPlayerList,
                playerByAuthId: updatedPlayerByAuthId
            });
        }

        case USER_GAMELIST_SUCCESS:
        {
            let updatedGameList = Object.assign({}, state.gameList);
            [
                action.response.wishlist, 
                action.response.ownedlist, 
                action.response.otherplayedlist
            ].forEach(list => {
                list.forEach(gameInfo => {
                    updatedGameList[gameInfo.gameId] = gameInfo;
                });
            });
            return Object.assign({}, state, {
                gameList: updatedGameList,
                playerList: state.playerList,
                playerByAuthId: state.playerByAuthId
            });
        }

        case GET_GAME_SUCCESS:
        {
            let updatedGameList = Object.assign({}, state.gameList);
            updatedGameList[action.requestId] = action.response;

            return Object.assign({}, state, {
                gameList: updatedGameList,
                playerList: state.playerList,
                playerByAuthId: state.playerByAuthId
            });
        }

        case ADD_CUSTOM_GAME_SUCCESS:
        {
            let updatedGameList = Object.assign({}, state.gameList);
            updatedGameList[action.response.gameId] = action.response;

            return Object.assign({}, state, {
                gameList: updatedGameList,
                playerList: state.playerList,
                playerByAuthId: state.playerByAuthId
            });
        }
/*
        case ADD_FRIEND_SUCCESS:
        {
            let updatedPlayerList = Object.assign({}, state.playerList);
            updatedPlayerList[action.response.playerId] = action.response;

            return Object.assign({}, state, {
                playerList: updatedPlayerList,
            });
        }
*/
        case ACTIVITY_FEED_STATS_SUCCESS:
        {
            // In the event that we introduce paging or filtering on the activityFeed, the stats may end up showing players/games that
            // are not in the stats results (which would be "totaled" across all activity).  To resolve this, we will also scan the
            // activity feed stats results and add games & players to our cache.

            // TODO:  implement this when we need it! (e.g. when we add paging or filtering)

            return state;
        }

        default:
            return state;

    }
};

function processSession(session, updatedGameList, updatedPlayerList, updatedPlayerByAuthId) {

    // update the game references
    if (session.game) {
        // if one already exists, keep the one that has the Expansions (which are returned on some queries, but not on feed queries)
        if (updatedGameList[session.game.gameId]) {
            if (!updatedGameList[session.game.gameId].expansions && session.game.expansions) {
                updatedGameList[session.game.gameId] = session.game;
            }
        } else {
            updatedGameList[session.game.gameId] = session.game;
        }
    }

    // update the player references
    session.scores.forEach((score) => {
        if (score.player) {
            // it's ok if one exists already, keep the most recent
            updatedPlayerList[score.player.playerId] = score.player;

            if (score.player.authId) {
                updatedPlayerByAuthId[score.player.authId] = score.player;
            }
        }
    });
}


export default listcacheReducer;