import TwoDimMap from '../../utils/TwoDimMap';
import { ACTIVITY_FEED_STATS_SUCCESS,
        ACTIVITY_FEED_STATS_MERGE_GAMEPLAYER_INFO,
        STATS_UPDATE } from '../actions/stats';

const INITIAL_STATE = {
    rawData: {},        // data put in here before merging game/player info
    byGameId: {},       // { playerId: {statsData} }
    byPlayerId: {}
};

function statsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case STATS_UPDATE: 
            // simple for now ...
            return Object.assign({}, state, { newStats: action.statsData });

        case ACTIVITY_FEED_STATS_SUCCESS:
        {
            // Put the raw stats data into the store, we will process
            // it once we know we have the gameList and playerList
            const playerId = action.requestId;
            return Object.assign({}, null, {
                rawData: {
                    ...state.rawData,
                    [playerId]: action.response
                },
                byGameId: {...state.byGameId},
                byPlayerId: {...state.byPlayerId}
            });
        }

        case ACTIVITY_FEED_STATS_MERGE_GAMEPLAYER_INFO:
        {
            // This event is called after we have downloaded the statsList,
            // and we know we have downloaded the GameList and PlayerList.
            // We merge that information in here once, so that our render
            // functions have all the data they need when they go to draw.

            // Let's just do some safety validation here ...
            if (!state.byGameId || !action.gameList || !action.playerList) {
                throw new Error('Missing data for calculations');
            }
            const playerId = action.playerId;
            const gameList = action.gameList;
            const playerList = action.playerList;
            
            const rawData = state.rawData[playerId];

            const byGameId = new TwoDimMap();
            const byPlayerId = new TwoDimMap();

            const iMax = rawData.length;
            for (let i=0; i<iMax; i++) {
                const stats = rawData[i];

                // add references
                stats.gameInfo = gameList[stats.gameId];
                stats.playerInfo = playerList[stats.playerId];

                // this data is used when sorting tables, easier to have in object
                stats.playerName = `${stats.playerInfo.firstName} ${stats.playerInfo.lastName}`;
                stats.gameName = stats.gameInfo.name;
                stats.winRate = (stats.wins / stats.numberTimesPlayed) * 100;

                // create hash by gameId and playerId
                byGameId.addObj(stats.gameId, stats.playerId, stats);
                byPlayerId.addObj(stats.playerId, stats.gameId, stats);
            }

            return Object.assign({}, null, {
                rawData: {
                    ...state.rawData,
                },
                byGameId: {
                    ...state.byGameId,
                    [playerId]: byGameId.getRoot()
                },
                byPlayerId: {
                    ...state.byPlayerId,
                    [playerId]: byPlayerId.getRoot()
                }
            });
        }

        default:
            return state;
    }
}

export default statsReducer;
