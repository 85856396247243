import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as routes from '../constants/routes';
import { Link } from 'react-router-dom';

import './MktgPage.css';

const styles = {
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    meeplesImage: {
        height: 30
    },
    spacer: {
        height: 400
    },
    whiteText: {
        color: "white"
    }
};
/*
                <AppBar position="static">
                    <Toolbar>
                        <Button className={classes.menuButton} color="inherit" aria-label="Meeples"
                            component={Link} to={routes.FEED}>
                            <img className={classes.meeplesImage} src={'/MeeplesIcon.png'} alt="Meeples" />
                        </Button>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            Meeples
                        </Typography>
                        <Button color="inherit" component={Link} to={routes.LOGIN}>
                            Login
                        </Button>
                        <Button color="inherit" component={Link} to={routes.SIGN_UP}>
                            Sign Up
                        </Button>
                    </Toolbar>
                </AppBar>
*/
export class MktgBody extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div className="mktgpage">

                <nav className="apphome_nav">
                    <div>
                        <Button className={classes.menuButton} color="inherit" aria-label="Meeples"
                            component={Link} to={routes.LANDING}>
                            <div className="meeplesImage" />
                        </Button>
                    </div>
                    <div>
                        <Button color="inherit" component={Link} to={routes.LOGIN}>
                            <Typography className={classes.whiteText} variant="h6">
                                LOGIN
                            </Typography>
                        </Button>
                        <Button color="inherit" component={Link} to={routes.SIGN_UP}>
                            <Typography className={classes.whiteText} variant="h6">
                                SIGN UP
                            </Typography>
                        </Button>
                    </div>
                </nav>

                <div className="apphome_background">
                    <div className="apphome_wrap1">
                        <p className="apphome_title">Meeples</p>
                    </div>
                </div>

                <div className="mktgbody">
                    {this.props.children}
                </div>

                <div className={classes.spacer}>&nbsp;</div>
            </div>
        );
    }
}

export default withStyles(styles)(MktgBody);
