import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { Provider } from 'react-redux';
import configureStore, { rrfProps } from './redux/store/configureStore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

const store = configureStore;

// the ordering here is very specific, be careful if you modify
ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <BrowserRouter>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </BrowserRouter>
        </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
);

// registerServiceWorker();
serviceWorker.unregister();
