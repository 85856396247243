import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { isLoaded } from 'react-redux-firebase';
import { addToWishlist, addToOwnedlist } from '../../firebase/handlers';
import { reduceList } from '../../utils/ArrayUtils';
import GameList from './GameList';

const mapStateToProps = state => {
    // these are both arrays of gameIds
    const wishListIds = state.firebase.profile.gamesWishlist || [];
    const ownedIds = state.firebase.profile.gamesOwnedlist || [];
    let played = state.firestore.ordered.gameList || [];

    // this is an array of all games in our feed
    let wishlist = [];
    let owned = [];
    if (isLoaded(state.firebase.profile) && isLoaded(state.firestore.data.gameList)) {
        const gameLookup = state.firestore.data.gameList;
        wishlist = wishListIds.map(gameId => Object.assign({id: gameId}, gameLookup[gameId]));
        owned = ownedIds.map(gameId => Object.assign({id: gameId}, gameLookup[gameId]));

        // NOTE: this is a list of all the games in their feed, not just ones they have played ...
        // remove wishlist and owned games from the 'played' games.
        played = reduceList(played, wishlist);
        played = reduceList(played, owned);
    }

    const result = {
        wishlist,
        owned,
        played,

        fullGameList: state.firestore.ordered.gameList || [],

        // Required as properties for the handlers below
        userId: isLoaded(state.firebase.auth) ? state.firebase.auth.uid : ''
    };
    //console.error('GameListContainer', JSON.stringify(result, null, 2));
    return result;
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect(),
    withHandlers({
        addToWishlist,
        addToOwnedlist
    })
)(GameList);
