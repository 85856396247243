import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EditScoreDialog from './EditScoreDialog';
import PlayerScore from './PlayerScore';
import SelectGameContainer from './SelectGameContainer';
import SelectExpansionsContainer from './SelectExpansionsContainer';

const styles = theme => {
    //console.error(JSON.stringify(theme, null, 2));
    return {
        header: {
            backgroundColor: theme.palette.secondary.main,
            height: 50,
            paddingLeft: 20,
            paddingRight: 20
        },
        winnerRow: {
            backgroundColor: theme.palette.primary.main,
            height: 50,
            color: 'white'
        },
        loserRow: {
            backgroundColor: theme.palette.secondary.main,
            height: 50
        }
    };
};

class SessionTable extends Component {
    static propTypes = {
        scores: PropTypes.array.isRequired,
        playerList: PropTypes.array.isRequired,     // used when adding a new player
        onUpdateScore: PropTypes.func.isRequired,
        onDeleteScore: PropTypes.func.isRequired,

        onSelectGame: PropTypes.func.isRequired,
        onSelectExpansions: PropTypes.func.isRequired,
        selectedGame: PropTypes.object,             // if there is no game selected, this will be null
        selectedExpansions: PropTypes.array         // ditto
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            editingScore: {},
            editingIndex: null, // used for edit later
            reducedPlayerList: []
        };
    }

    handleAddPlayer = () => {
        const { scores, playerList } = this.props;

        // remove players that are already in our scores list, so they can't be selected again by accident
        const reducedPlayerList = playerList.reduce((accumulator, currentValue) => {
            for (let i=0; i<scores.length; i++) {
                if (scores[i].playerId === currentValue.id) {
                    return accumulator;
                }
            }
            accumulator.push(currentValue);
            return accumulator;
        }, []);
        //console.log('Reduced Player List', reducedPlayerList);

        // Try to find the next position, to save the user some data updating
        // TODO: works if they do it in order, but doesn't "fill in" if a position was missed ...?
        let nextPosition = 1;
        scores.forEach(score => {
            if (score.position >= nextPosition) {
                nextPosition = Number(score.position) + 1;
            }
        });

        this.setState({
            open: true,
            editingScore: {
                playerId: null,
                nickName: '',
                score: '',
                position: nextPosition,
                isWinner: false
            },
            reducedPlayerList,
            editingIndex: -1
        });
    };

    handleEditRow = index => {
        const { scores } = this.props;
        this.setState({ open: true, editingScore: scores[index], editingIndex: index });
    };

    handleClose = () => {
        this.setState({
            open: false,
            editingScore: null
        });
    };

    handleSave = score => {
        const { onUpdateScore } = this.props;
        const { editingIndex } = this.state;

        onUpdateScore(editingIndex, score);

        this.setState({
            open: false,
            editingScore: null,
            editingIndex: null
        });
    };

    handleDeleteScore = () => {
        const { onDeleteScore } = this.props;
        const { editingIndex } = this.state;

        onDeleteScore(editingIndex);

        this.setState({
            open: false,
            editingScore: null,
            editingIndex: null
        });
    }

    getPlayerScores(classes, scores) {
        return scores.map((score, index) => {
            return (
                <PlayerScore
                    key={index}
                    classes={classes}
                    score={score}
                    index={index}
                    onClickRow={this.handleEditRow}
                />
            );
        });
    }

    render() {
        const { classes, scores, selectedGame, selectedExpansions, onSelectGame, onSelectExpansions } = this.props;
        const { open, editingScore, reducedPlayerList } = this.state;

        return (
            <div className="sessiontable_div">
                <div className="sessiontable_btns">
                    <SelectGameContainer selectedGame={selectedGame} onSelectGame={onSelectGame} />
                    <SelectExpansionsContainer selectedGame={selectedGame} selectedExpansions={selectedExpansions} onSelectExpansions={onSelectExpansions} />

                    <Button variant="outlined" color="primary" onClick={this.handleAddPlayer}>
                        Add Player
                    </Button>
                    {open ? (
                        <EditScoreDialog
                            isOpen={open}
                            handleClose={this.handleClose}
                            handleSave={this.handleSave}
                            handleDeleteScore={this.handleDeleteScore}
                            score={editingScore}
                            playerList={reducedPlayerList}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <table className="sessiontable_table">
                    <thead>
                        <tr key="1" className={classes.header}>
                            <td className="sessiontable_td">
                                <Typography variant="body1" color="inherit">
                                    PLAYER
                                </Typography>
                            </td>
                            <td className="sessiontable_td" align="right">
                                <Typography variant="body1" color="inherit">
                                    SCORE
                                </Typography>
                            </td>
                        </tr>
                    </thead>
                    <tbody>{this.getPlayerScores(classes, scores)}</tbody>
                </table>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SessionTable);
