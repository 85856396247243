import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearSystemError } from './../redux/actions/actions';
import SystemSnackbar from './SystemSnackbar';

function SystemErrorComponent(props) {
    const { userMessage, devMessage, handleClose } = props;

    if (!userMessage) {
        return '';
    }

    const errorMessage = `${userMessage} [${devMessage}]`;

    return <SystemSnackbar variant="error" message={errorMessage} handleClose={handleClose} />;
}

SystemErrorComponent.propTypes = {
    devMessage: PropTypes.string,
    userXXXXMessage: PropTypes.string
};

const mapStateToProps = state => {
    return {
        userMessage: state.errorState.supportableError.userMessage,
        devMessage: state.errorState.supportableError.devMessage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleClose: () => dispatch(clearSystemError())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemErrorComponent);
