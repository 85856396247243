import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';

const styles = theme => {
    //console.error(JSON.stringify(theme, null, 2));
    return {
        commentdiv: {
            borderColor: theme.palette.secondary.main
        },
        commentinput: {
            fontFamily: theme.typography.body1.fontFamily,
            fontSize: theme.typography.body1.fontSize
        }
    };
};

class AddComment extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        playerId: PropTypes.string.isRequired,
        discussionId: PropTypes.string.isRequired,
        firestore: PropTypes.shape({
            add: PropTypes.func.isRequired
        }).isRequired
        /*firebase: PropTypes.shape({

        })*/
    };

    constructor(props) {
        super(props);

        this.state = {
            content: ''
        };

        this.addComment = this.addComment.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.keyPress = this.keyPress.bind(this);
    }

    handleChange(e) {
        this.setState({ content: e.target.value });
    }

    keyPress(e) {
        // if the user hits the 'enter' key
        if (e.keyCode === 13) {
            this.addComment();
        }
    }

    addComment() {
        const { playerId, discussionId, firestore } = this.props;
        const { content } = this.state;

        if (0 === content.length) {
            return;
        }

        this.props.firestore.add(
            {
                collection: 'sessionDiscussion',
                doc: discussionId,
                subcollections: [{ collection: 'comments' }]
            },
            {
                author: playerId,
                content: content,
                createDate: /*firebase.*/ firestore.FieldValue.serverTimestamp()
            }
        );
        this.setState({ content: '' });
    }

    render() {
        const { classes } = this.props;
        const { content } = this.state;

        return (
            <div className={`${classes.commentdiv} addcomment`}>
                <input
                    className={`${classes.commentinput} addcomment_input`}
                    type="text"
                    placeholder="Type your Message"
                    value={content}
                    onChange={this.handleChange}
                    onKeyDown={this.keyPress}
                />
                <IconButton color="inherit" onClick={this.addComment}>
                    <SendIcon />
                </IconButton>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        //userId: state.firebase.auth.uid,
        playerId: state.firebase.profile.pid
    };
};

const mapDispatchToProps = {};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firestoreConnect()
    //firebaseConnect()
)(withStyles(styles, { withTheme: true })(AddComment));
