import React from 'react';

const NotFound = (props) => {
    return (
        <div>
            <h1>Page Not Found!</h1>
            <p>The page '{props.location.pathname}' was not found.</p>
        </div>
    );
};

export default NotFound;
