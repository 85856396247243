// This class bifurcates the app.  If you are authenticated it renders the main app.  If you aren't, it renders our signup area.

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppHome from './AppHome/AppHome';
import * as routes from './constants/routes';

class AppMain extends React.Component {

    static propTypes = {
        authUser: PropTypes.object,     // not required, can be null as we authenticate
        history: PropTypes.object.isRequired
    };

    componentDidUpdate() {
        const { authUser, history } = this.props;

        // AppRoutes is wrapped by withAuthentication which should kick off the
        // firebase auth code.  We just need to respond to the events here ...
        if (authUser) {
            console.error('AppMain redirecting to ActivityFeed!');
            history.push(routes.FEED);
        }
    }

    render() {
        // There is an annoying flicker as this loads ...
        // We could put in some kind of pause or "loading" until we get an answer
        // back from the firebase auth system, but this is fine for now.
        return (
            <div>
                <AppHome />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    authUser: state.sessionState.authUser
});

export default connect(mapStateToProps)(AppMain);
