import React from 'react';
import PropTypes from 'prop-types';
import FriendsListList from './FriendsListList';
import Loading from '../../components/Loading';

class FriendsList extends React.Component {

    // There are many other propTypes being passed because of how FriendsListContainer works, but 
    // these are the only ones we use, so this class serves as a "props filter".
    static propTypes = {
        isLoaded: PropTypes.bool.isRequired,
        friendsList: PropTypes.array.isRequired,
        incomingFriendRequests: PropTypes.array.isRequired,
        outboundFriendRequests: PropTypes.array.isRequired
    };

    /*constructor(props) {
        super(props);
        //this.onInputChange = this.onInputChange.bind(this);
    }*/

    render() {
        const { isLoaded, friendsList, incomingFriendRequests, outboundFriendRequests } = this.props;

        return (
            <div className="friendslist">
                {!isLoaded ? (
                    <Loading />
                ) : (
                    <FriendsListList
                    friendsList={friendsList}
                        incomingFriendRequests={incomingFriendRequests}
                        outboundFriendRequests={outboundFriendRequests}
                    />
                )}
            </div>
        );
    }
}

export default FriendsList;
