import React from 'react'
import Typography from '@material-ui/core/Typography';

export default function Footer() {
    return (
        <div className='footer'>
            <Typography variant="body1" color="secondary">
                Created by Tim Philip and Designed by Maija Philip.
            </Typography>
        </div>
    );
};
