import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';      // According to docs, Required for side-effects
import config from './FirebaseConfig';

console.log('Initializing Firebase: starting with project ', config.projectId);

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

if (process.env.NODE_ENV !== 'production') {
    // For testing functions locally ...
    firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

const auth = firebase.auth();
const db = firebase.firestore();
firebase.functions();  // Initialize Cloud Functions through Firebase

const settings = { /* your settings... */ };
db.settings(settings);

console.log('Initializing Firebase: complete!');

export { auth, db };
