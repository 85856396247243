import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import GameImage from '../../components/GameImage';
import * as routes from '../../constants/routes';
import SessionDiscussion from './SessionDiscussion';
import ViewScores from './ViewScores';
import { toMyDateFormat } from '../../utils/MyDate';
import './ViewSession.css';

const styles = {
    footer: {
        textTransform: 'uppercase'
    }
};

class ViewSession extends React.Component {
    static propTypes = {
        isInvalidSession: PropTypes.bool.isRequired,
        sessionId: PropTypes.string.isRequired,
        sessionInfo: PropTypes.object.isRequired,
        userPlayerId: PropTypes.string.isRequired,
    };

    getSelectedExpansions = () => {
        const { sessionInfo } = this.props;

        if (sessionInfo && sessionInfo.expansions) {
            const list = sessionInfo.expansions.map((expansion, id) => {
                return (
                    <Typography key={id} variant="body1" color="primary">
                        {expansion.name}
                    </Typography>
                );
            });

            return (
                <React.Fragment>
                    <Typography variant="caption" color="primary">
                        with:{' '}
                    </Typography>
                    <div className="addSession_expansionslist">{list}</div>
                </React.Fragment>
            );
        }
    };

    render() {
        const { classes, discussionExists, isInvalidSession, sessionId, sessionInfo, userPlayerId } = this.props;

        if (isInvalidSession) {
            return <h3>Invalid Session Id</h3>;
        }
        // only the owner can edit the Session, and only after the cloud function has completed replicating ...
        const showEditButton = (userPlayerId === sessionInfo.ownerId) && !sessionInfo.mockSession;
        const when = toMyDateFormat(sessionInfo.date);
        const location = sessionInfo.location ? ` \u2013 ${sessionInfo.location}` : ''; // \u2013 is a long dash
        const footerString = `${when} ${location}`; // no dash if no location

        const editLink = routes.EDIT_SESSION.replace(':id', sessionId);
        return (
            <div className="viewsession">
                <div className="viewsession_top">
                    <div className="viewsession_left">
                        <div className="viewsession_game">
                            <GameImage game={sessionInfo.gameInfo} />
                        </div>
                        <div className="viewsession_expansions">{this.getSelectedExpansions()}</div>
                    </div>
                    <div className="viewsession_right">
                        <div className="viewsession_dateloc">
                            <Typography className={classes.footer} variant="body1" color="secondary">
                                {footerString}
                            </Typography>
                        </div>
                        {showEditButton ? (
                            <div className="viewsession_edit">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    aria-label="Edit Game"
                                    size="small"
                                    component={Link}
                                    to={editLink}
                                >
                                    EDIT GAME
                                </Button>
                            </div>
                        ) : (
                            ''
                        )}
                        <ViewScores scores={sessionInfo.scores} />
                    </div>
                </div>
                { discussionExists ? 
                <div className="viewsession_bottom">
                    <SessionDiscussion discussionId={sessionId} />
                </div>
                : '' }
            </div>
        );
    }
}

export default withStyles(styles)(ViewSession);
