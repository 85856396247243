import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddPlayerModal from '../../components/AddPlayerModal';
import FriendBox from './FriendBox';

import './FriendsList.css';

const styles = theme => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
});

class FriendListList extends React.Component {

    static propTypes = {
        friendsList: PropTypes.array.isRequired,
        incomingFriendRequests: PropTypes.array.isRequired,
        outboundFriendRequests: PropTypes.array.isRequired
    };

    constructor(props, context) {
        super(props, context);

        this.handleClickAddPlayer = this.handleClickAddPlayer.bind(this);
        this.handleCloseAddPlayerDialog = this.handleCloseAddPlayerDialog.bind(this);

        this.state = {
            showAddPlayerModal: false
        };
    }

    handleClickAddPlayer() {
        this.setState({
            showAddPlayerModal: true
        });
    }

    handleCloseAddPlayerDialog() {
        this.setState({
            showAddPlayerModal: false
        });
    }

    render() {
        const { friendsList, incomingFriendRequests, outboundFriendRequests, classes } = this.props;
        const { showAddPlayerModal } = this.state;

        return (
            <div className="friendslistlist">
                <FriendBox friendList={friendsList} friendType="friend" />
                <FriendBox friendList={incomingFriendRequests} friendType="incoming" />
                <FriendBox friendList={outboundFriendRequests} friendType="outgoing" />
                <AddPlayerModal 
                    open={showAddPlayerModal}
                    handleClose={this.handleCloseAddPlayerDialog}
                    handleSaveNewPlayer={this.handleCloseAddPlayerDialog}
                />
                <Fab
                    color="primary"
                    aria-label="Add"
                    className={classes.fab}
                    onClick={this.handleClickAddPlayer}
                >
                    <PersonAddIcon />
                </Fab>
            </div>
        );
    }
}

export default withStyles(styles)(FriendListList);
