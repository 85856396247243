import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import * as routes from '../constants/routes';

const styles = {
    avatar: {
        fontSize: '1.25rem'
    }
};

function PlayerAvatar(props) {
    const { classes, playerId, firstLetter, secondLetter, avatarUrl, nickName, nolink } = props;

    const link = routes.PLAYER_INFO.replace(':id', playerId);

    let avatar = (
        <Avatar className={classes.avatar} alt={nickName} src={avatarUrl}>
            {firstLetter.toUpperCase()}
            {secondLetter.toUpperCase()}
        </Avatar>
    );

    if (nolink) {
        return avatar;
    } else {
        return <Link to={link}>{avatar}</Link>;
    }
}

PlayerAvatar.propTypes = {
    playerId: PropTypes.string.isRequired,
    firstLetter: PropTypes.string.isRequired,
    secondLetter: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { playerId } = ownProps;

    let firstLetter = '.';
    let secondLetter = '';

    let playerInfo = state.firestore.data.playerList[playerId];
    if (!playerInfo || !playerInfo.nickName) {
        // TODO:  unclear how to resolve this cleanly
        console.error('Error in PlayerAvatar.mapStateToProps', playerId, playerInfo);
        playerInfo = { nickName: 'A A' };
    }
    const allNames = playerInfo.nickName.split(' ');

    // favor uppercase words if possible
    const uppercaseNames = allNames.filter(name => name[0] === name[0].toUpperCase());
    let names = uppercaseNames;
    if (names.length < 2) {
        names = allNames;
    }

    if (names.length > 0) {
        firstLetter = names[0][0];
    }
    if (names.length > 1) {
        secondLetter = names[1][0];
    }

    return {
        playerId,
        firstLetter,
        secondLetter,
        nickName: playerInfo.nickName,
        avatarUrl: playerInfo.avatarUrl
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlayerAvatar));
