import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as routes from '../../constants/routes';
import GameImage from '../../components/GameImage';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { TYPE_BYGAME } from './StatsList';
import './StatsBox.css';

// -----------------

function TdHeader(props) {
    const { label, align, active, direction, onClick } = props;

    // Possible states:
    // - not currently the active sort header
    // - currently the sort header, direction=desc
    // - currently the sort header, direction=asc
    let icon = ' ';
    if (active) {
        icon = direction === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />;
    }
    return (
        <React.Fragment>
            <td className='statsbox_tdsorticon'>{icon}</td>
            <td className='statsbox_tdheader' align={align} onClick={onClick}>
                {label}
            </td>
        </React.Fragment>
    );
}

// -----------------
// Sorting Functions
//
// order = enum: 'asc' | 'desc'
// orderBy = the field name in your data object to sort on
//
function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]); // copy the data
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]); // why this final map??
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
// -----------------

const styles = {
    table: {
        width: '100%',
        marginLeft: 10
    }
};

class StatsBox extends React.Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        gameInfo: PropTypes.object,
        playerInfo: PropTypes.object,
        listPlayerStats: PropTypes.array.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            order: 'desc',
            orderBy: 'numberTimesPlayed'
        };

        this.handleRequestSort = this.handleRequestSort.bind(this);
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    createSortHandler = property => event => {
        this.handleRequestSort(event, property);
    };

    render() {
        const { type, gameInfo, playerInfo, listPlayerStats, includeImages /*, classes*/ } = this.props;
        const { order, orderBy } = this.state;

        // Sort the list based on our current sort order and column
        const sortedList = stableSort(listPlayerStats, getSorting(order, orderBy));

        const list = sortedList.map((stats, index) => {
            let title = stats.name;
            let route = routes.GAME_INFO.replace(':id', stats.id);
            if (type === TYPE_BYGAME) {
                title = stats.name;
                route = routes.PLAYER_INFO.replace(':id', stats.id);
            }

            // It was scope="row" ... ????
            // <td className='statsbox_tdtitle' component="th" scope="row">
            return (
                <tr key={index} className='statsbox_tr'>
                    <td className='statsbox_tdsorticon'></td>
                    <td className='statsbox_tdtitle' component="th">
                        <Typography variant="body1" color="primary" component={Link} to={route}>
                            {title}
                        </Typography>
                    </td>
                    <td className='statsbox_tdsorticon'></td>
                    <td className='statsbox_tdwins' align="right">{stats.wins}</td>
                    <td className='statsbox_tdsorticon'></td>
                    <td className='statsbox_tdnumplayed' align="right">{stats.numberTimesPlayed}</td>
                    <td className='statsbox_tdsorticon'></td>
                    <td className='statsbox_tdwinrate' align="right">{((stats.wins / stats.numberTimesPlayed) * 100).toFixed(0)}%</td>
                </tr>
            );
        });

        let routeTo = null;
        let title = null;
        let leftImage = null;
        let sortField = null;
        if (type === TYPE_BYGAME) {
            sortField = 'playerName';
            title = gameInfo.name;
            if (includeImages) {
                leftImage = <GameImage game={gameInfo} />;
            }
            routeTo = routes.GAME_INFO.replace(':id', gameInfo.id);
        } else {
            sortField = 'gameName';
            title = playerInfo.nickName;
            leftImage = '';
            routeTo = routes.PLAYER_INFO.replace(':id', playerInfo.id);
        }

        const cols = [
            { id: sortField, align: 'left', disablePadding: false, label: 'Name' },
            { id: 'wins', align: 'right', disablePadding: false, label: 'Wins' },
            { id: 'numberTimesPlayed', align: 'right', disablePadding: false, label: 'Times Played' },
            { id: 'winRate', align: 'right', disablePadding: false, label: 'Win Rate' }
        ];

        return (
            <div className="feeditem">
                {leftImage}
                <div className="statsbox_scores">
                    <Typography variant="h6" color="primary" component={Link} to={routeTo}>
                        {title}
                    </Typography>
                    <table className="statsbox_table">
                        <thead>
                            <tr className="statsbox_trheader">
                                {cols.map(col => {
                                    return (
                                        <TdHeader
                                            key={col.id}
                                            active={orderBy === col.id}
                                            align={col.align}
                                            direction={order}
                                            onClick={this.createSortHandler(col.id)}
                                            label={col.label}
                                        />
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>{list}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(StatsBox);
