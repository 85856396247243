export const SIGN_UP = '/signup';
export const NEW_ACCOUNT = '/newaccount';
export const SIGN_IN = '/signin';
export const SIGN_OUT = '/signout';
export const LOGIN = '/login';
export const LOGOUT ='/logout';
export const PASSWORD_FORGET = '/pwforget';
export const PASSWORD_CHANGE = '/pwchange';
export const LANDING = '/';

export const FEED = '/app/activity';
export const SETTINGS = '/app/settings';
export const ADD_SESSION = '/app/addsession';
export const EDIT_SESSION = '/app/editsession/:id';
export const FRIENDS = '/app/friends';
export const PLAYER_INFO = '/app/playerinfo/:id';
export const GAME_INFO = '/app/gameinfo/:id';
export const SESSION_INFO = '/app/eventinfo/:id';
export const GAMELIST = '/app/mygames';