import * as actions from '../actions/actions';
import {
    PLAYER_FRIENDS_REQUEST, PLAYER_FRIENDS_SUCCESS, PLAYER_FRIENDS_FAILURE,
    ADD_FOLLOW_REQUEST, ADD_FOLLOW_SUCCESS, ADD_FOLLOW_FAILURE,
    IGNORE_FOLLOW_REQUEST, IGNORE_FOLLOW_SUCCESS, IGNORE_FOLLOW_FAILURE
} from '../actions/players';

// Note that these are USERS not PLAYERS.
// { userId: x, playedId: x, firstName: x, lastName: x}
const INITIAL_STATE = {
    isLoaded: false,        // should be true after any successful request (good for start-up)
    relationships: {
        friends: [],
        following: [],
        followers: []
    }
};

function friendReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actions.ADD_FRIEND_REQUEST:
        case actions.ADD_FRIEND_SUCCESS:
        case actions.ADD_FRIEND_FAILURE:
            return state;
            /*return Object.assign({}, null, {
                friendsList: action.errorMsg
            });*/

        case PLAYER_FRIENDS_REQUEST:
        case ADD_FOLLOW_REQUEST:
        case IGNORE_FOLLOW_REQUEST:
            return state;

        // The api signature is identical for all of these requests
        case PLAYER_FRIENDS_SUCCESS:
        case ADD_FOLLOW_SUCCESS:
        case IGNORE_FOLLOW_SUCCESS:
            return Object.assign({}, {
                playerList: { ...state.playerList }, // what player list?!
                isLoaded: true,
                relationships: { 
                    friends: action.response.friends ? action.response.friends : [],
                    following: action.response.following ? action.response.following : [],
                    followers: action.response.followers ? action.response.followers : []
                }
            });

        case PLAYER_FRIENDS_FAILURE:
        case ADD_FOLLOW_FAILURE:
        case IGNORE_FOLLOW_FAILURE:
            return state;

        default:
            return state;
    }
}

export default friendReducer;
