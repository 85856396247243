import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import * as routes from '../constants/routes';
import HrDiv from './HrDiv';
import Login from './LoginContainer';

// Note that feed.css has also been included (due to "main" including everything) ... so we are picking up styling from there too ...
import './AppHome.css';

const styles = theme => {
    return {
        icon: {
            color: theme.palette.primary.main
        },
        text: {
            color: theme.palette.primary.main
        }
    };
};

const AppHome = props => {
    const { classes } = props;

    return (
        <div>
            <div className="apphome_section">
                <div className="apphome_imagediv">
                    <img className="apphome_section_leftimage" src="/PeoplePlayingGame.jpg" alt="Fun with Friends" />
                </div>
                <div>
                    <Typography variant="h5" color="primary">
                        An App You'll Love
                    </Typography>
                    <Typography variant="subtitle1" color="inherit">
                        <ul>
                            <li>Keep track of Board Games you play</li>
                            <li>Automatically share with your Friends</li>
                            <li>Add Pictures and Comments</li>
                        </ul>
                    </Typography>
                </div>
            </div>

            <div className="apphome_section">
                <div>
                    <Typography variant="h5" color="primary">
                        Stats for Geeks
                    </Typography>
                    <Typography variant="subtitle1" color="inherit">
                        <ul>
                            <li>Keep track of who wins which game the most</li>
                            <li>Does the first player have an advantage? Find out!</li>
                        </ul>
                    </Typography>
                </div>
                <div className="apphome_imagediv">
                    <img className="apphome_section_rightimage" src="/StatsSample.png" alt="We love stats!" />
                </div>
            </div>

            <div className="apphome_section">
                <div className="apphome_imagediv">
                    <img className="apphome_section_leftimage" src="/SessionSample.png" alt="Fun with Friends" />
                </div>
                <div>
                    <Typography variant="h5" color="primary">
                        An App You'll Love
                    </Typography>
                    <Typography variant="subtitle1" color="inherit">
                        <ul>
                            <li>Keep track of Board Games you play</li>
                            <li>Automatically share with your Friends</li>
                            <li>Add Pictures and Comments</li>
                        </ul>
                    </Typography>
                </div>
            </div>

            <div className="apphome_section">
                <div className="apphome_section_bottom">
                    <Login />
                    <HrDiv label='or'/>
                    <Link to={routes.SIGN_UP}>
                    <button className="apphome_useemail" >
                        <span className={`${classes.icon} apphome_useemail_icon`}>
                            <EmailIcon />
                        </span>
                        <span className={`${classes.text} apphome_useemail_text`}>Use my email</span>
                    </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles, { withTheme: true })(AppHome);
