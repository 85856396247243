import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { isLoaded } from 'react-redux-firebase';
import moment from 'moment';
import Loading from '../../components/Loading';
import AddSessionContainer from './AddSessionContainer';
import { saveNewSession } from '../../firebase/handlers';

export class NewSession extends Component {
    static propTypes = {
        saveNewSession: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            sessionInfo: null
        };

        // Welcome to asyncronous loading!  Different timing takes different paths.
        // Really should look into React Hooks for this one I think.
        // If the profile is loaded before the constructor is called, then we init the session info here,
        // because componentDidUpdate() won't be called, since we already have all of our props.  DOH!
        // If it hasn't been loaded, then we will init it in the componentDidUpdate() below, once we
        // detect that the profile is loaded.  SICK!
        // ...
        // Of course another option would be to create a small (probably functional) Component that only gets
        // created once we are loaded, and *it* creates to default sessionInfo, and passes it to it's children.
        if (props.isLoaded) {
            this.state = {
                sessionInfo: this.getDefaultSessionInfo()
            };
        }
    }

    getDefaultSessionInfo = () => {
        const { userPlayerId, userNickName } = this.props;

        const indexSpace = userNickName.indexOf(' ');
        const userFirstName = userNickName.substring(0, indexSpace > 0 ? indexSpace : userNickName.length);
        // construct a default "new game" session to use
        // lots of opportunity to use smart defaults here to speed data entry!
        return {
            ownerId: userPlayerId,
            date: moment().format('YYYY-MM-DD'),
            //date: firebase.firestore.Timestamp.fromDate(new Date()),
            location: `${userFirstName}'s place`,
            notes: '',
            game: null, // TODO: select last game entered??
            expansions: null,
            lastUpdated: { seconds: 0 }, // server side time update
            scores: {
                [userPlayerId]:
                    // TODO:  if 2nd/Nth game of the day, use same players and game?!
                    {
                        playerId: userPlayerId,
                        position: 1,
                        score: 0,
                        playerInfo: {
                            nickName: userNickName
                        },
                        isWinner: false
                    }
            }
        };
    };

    // This is when we start thinking about React Hooks ...
    componentDidUpdate(prevProps) {
        // We do a one-time initializing of this data once the profile is loaded
        // This is annoyingly complicated because we want some data from the profile before we do the default initialization.
        if (!this.state.sessionInfo) {
            if (this.props.isLoaded) {
                this.setState({ sessionInfo: this.getDefaultSessionInfo() });
            }
        }
    }

    render() {
        const { /*isLoaded,*/ saveNewSession, history } = this.props;
        const { sessionInfo } = this.state;

        if (!sessionInfo) {
            return <Loading />;
        }

        return <AddSessionContainer sessionInfo={sessionInfo} saveSessionRequest={saveNewSession} history={history} />;
    }
}

const mapStateToProps = state => {
    const loaded = isLoaded(state.firebase.profile);

    const result = {
        isLoaded: loaded,
        userPlayerId: state.firebase.profile.pid,
        userNickName: state.firebase.profile.nickName
    };
    //console.warn('NewSession.mapStateToProps', result);
    return result;
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect(),
    withHandlers({
        saveNewSession
    })
)(NewSession);
