/**
 * https://stackoverflow.com/questions/12462318/find-a-value-in-an-array-of-objects-in-javascript
 * Represents a search through an array.
 * @function search
 * @param {Array} array - The array you wanna search trough
 * @param {string} key - The key to search for
 * @param {string} [prop] - The property name to find it in
 */
export const search = (array, key, prop) => {
    if (!array) return null;

    // Optional, but fallback to key['name'] if not selected
    prop = typeof prop === 'undefined' ? 'name' : prop;

    for (var i = 0; i < array.length; i++) {
        if (array[i][prop] === key) {
            return array[i];
        }
    }
    return null;
};

/**
 * Remove items from an array.
 * @function reduceList
 * @param {Array} startingList - The array of items you want to start with
 * @param {Array} itemsToRemove - An array of items you want removed from the list
 */
export const reduceList = (startingList, itemsToRemove) => {
    const reducedList = startingList.reduce((accumulator, currentValue) => {
        for (let i = 0; i < itemsToRemove.length; i++) {
            if (currentValue.id === itemsToRemove[i].id) {
                return accumulator;
            }
        }
        accumulator.push(currentValue);
        return accumulator;
    }, []);
    return reducedList;
};