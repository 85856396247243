const INITIAL_STATE = {
    authUser: null,
    authToken: null
};

function sessionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case 'AUTH_USER_SET':
            return Object.assign({}, null, {
                authUser: action.authUser,
                authToken: state.authToken
            });

        case 'AUTH_TOKEN_SET':
            return Object.assign({}, null, {
                authUser: state.authUser,
                authToken: action.authToken
            });

        default:
            return state;
    }
}

export default sessionReducer;
