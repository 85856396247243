import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => {
    return {
        content: {
            background: theme.palette.secondary.main
        },
        userContent: {
            background: theme.palette.primary.main,
            color: 'white'
        }
    };
};

export class GroupedComment extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,

        playerByAuthId: PropTypes.object.isRequired,
        userPlayerId: PropTypes.string.isRequired
    };

    render() {
        const { classes, playerByAuthId, userPlayerId, group } = this.props;

        /*        
        commentGroup = {
            author: comment.author,
            key: i + groupedComments.length + 1,
            comments: [comment]
        };
        */

        // If the user made these comments, use these styles
        let authorName = 'YOU';
        let contentClassName = `${classes.userContent} comment_content_user`;
        let authorClassName = 'comment_authorname_user';

        // if another user made these comments, instead use these styles
        if (group.author !== userPlayerId) {
            const player = playerByAuthId[group.author];

            authorName = player ? player.nickName : 'Player';
            contentClassName = `${classes.content} comment_content`;
            authorClassName = 'comment_authorname';
        }

        const list = group.comments.map((comment, index) => {
            return (
                <div key={index} className={contentClassName}>
                    <Typography variant="body1" color="inherit">
                        {comment.content}
                    </Typography>
                </div>
            );
        });

        return (
            <React.Fragment>
                <div key={group.id} className={authorClassName}>
                    <Typography variant="body1" color="secondary">
                        {authorName}
                    </Typography>
                </div>
                {list}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        playerByAuthId: state.firestore.data.playerList,
        userPlayerId: state.firebase.profile.pid
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(GroupedComment));
