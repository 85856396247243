const config = {
    apiKey: "AIzaSyDoeoIJiCHQlidxHt_PbE44cpkmzONRADQ",

    // See: https://firebase.google.com/docs/auth/web/google-signin#customizing-the-redirect-domain-for-google-sign-in
    // authDomain: "dockertest-231522.firebaseapp.com",
    authDomain: "auth.meeples.app",

    databaseURL: "https://dockertest-231522.firebaseio.com",
    projectId: "dockertest-231522",
    storageBucket: "dockertest-231522.appspot.com",
    messagingSenderId: "648524032749"
}

export default config;
