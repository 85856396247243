import {
    SEARCH_GAMES_REQUEST, SEARCH_GAMES_SUCCESS, SEARCH_GAMES_FAILURE,
    ADD_SESSION_REQUEST, ADD_SESSION_SUCCESS, ADD_SESSION_FAILURE,
    EDIT_SESSION_REQUEST, EDIT_SESSION_SUCCESS, EDIT_SESSION_FAILURE,
    DELETE_SESSION_REQUEST, DELETE_SESSION_SUCCESS, DELETE_SESSION_FAILURE,
    USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_INFO_FAILURE,
    RECENT_GAMES_REQUEST, RECENT_GAMES_SUCCESS, RECENT_GAMES_FAILURE,
    ACTIVITY_FEED_REQUEST, ACTIVITY_FEED_SUCCESS, ACTIVITY_FEED_FAILURE,
    GET_SESSION_REQUEST, GET_SESSION_SUCCESS, GET_SESSION_FAILURE,
    GAME_FEED_REQUEST, GAME_FEED_SUCCESS, GAME_FEED_FAILURE, 
    ADD_TO_WISHLIST_SUCCESS,
    REMOVE_FROM_WISHLIST_SUCCESS,
    ADD_TO_OWNEDLIST_SUCCESS,
    REMOVE_FROM_OWNEDLIST_SUCCESS,
    USER_GAMELIST_SUCCESS,
    ADD_CUSTOM_GAME_REQUEST, ADD_CUSTOM_GAME_SUCCESS, ADD_CUSTOM_GAME_FAILURE,
    ADD_FRIEND_REQUEST, ADD_FRIEND_SUCCESS, ADD_FRIEND_FAILURE,
    GET_GAME_SUCCESS
} from '../actions/actions';
import { search } from '../../utils/ArrayUtils';

/*
 * ADD_CUSTOM_GAME
 */
const customGameInitialState = {
    gameInfo: null,
    errorMsg: null,
};
export function customGame(state = customGameInitialState, action) {
    switch (action.type) {
        case ADD_CUSTOM_GAME_REQUEST:
            return Object.assign({}, {
                gameInfo: null,
                errorMsg: null,
            });

    case ADD_CUSTOM_GAME_SUCCESS:
        return Object.assign({}, {
            gameInfo: action.response,
            errorMsg: null,
        });

    case ADD_CUSTOM_GAME_FAILURE:
        return Object.assign({}, {
            gameInfo: null,
            errorMsg: action.errorMsg,
        });

    default:
        return state;

    }
}

/*
 * ADD_FRIEND
 */
const addFriendResponseInitialState = {
    playerInfo: null,
    errorMsg: null,
};
export function addFriendResponse(state = addFriendResponseInitialState, action) {
    switch (action.type) {
        case ADD_FRIEND_REQUEST:
            return Object.assign({}, {
                playerInfo: null,
                errorMsg: null,
            });

    case ADD_FRIEND_SUCCESS:
        return Object.assign({}, {
            playerInfo: action.response,
            errorMsg: null,
        });

    case ADD_FRIEND_FAILURE:
        return Object.assign({}, {
            playerInfo: null,
            errorMsg: action.errorMsg,
        });

    default:
        return state;

    }
}


/*
 * SEARCH_GAMES
 */
const searchGamesInitialState = {
    results: [],
    errorMsg: null,
};
export function searchGames(state = searchGamesInitialState, action) {
    switch (action.type) {
        case SEARCH_GAMES_REQUEST:
            return Object.assign({}, {
                results: [...state.results],
                errorMsg: null,
            });
        
        case SEARCH_GAMES_SUCCESS:
            return Object.assign({}, {
                results: [...action.response],
                errorMsg: null,
            });

        case SEARCH_GAMES_FAILURE:
            return Object.assign({}, {
                results: null,
                errorMsg: action.errorMsg,
            });

            // SIDEEFFECT!  When we add a Custom Game, it is off the search dialog, and we want the new game to show up in the results list, 
            // so we will add it in here.
        case ADD_CUSTOM_GAME_SUCCESS:
            return Object.assign({}, {
                results: [action.response,
                    ...state.results],
                errorMsg: null,
            });    

        default:
            return state;
    }
}

/**
 * ADD_SESSION
 */
const addSessionInitialState = {
    errorMsg: null,
    isSaving: false,    // used to know when to close the Add Session dialog
    isDeleting: false,
    lastSavedSessionId: null,

    // used to retrieve detailed item information after it is selected (search doesn't return expansions)
    // may or may not be up to date, so validate that the gameId is what you are looking for
    // any game detail info will also go into the listcache
    detailedGameInfo: null
};
export function addSession(state = addSessionInitialState, action) {
    switch (action.type) {
        case ADD_SESSION_REQUEST:
        case EDIT_SESSION_REQUEST:
            return Object.assign({}, state, {
                errorMsg: null,
                isSaving: true,
                lastSavedSessionId: null
            });

            // TODO:  this response has the session info, we should add it to our session cache, so that navigating there doesn't cause a request
        case ADD_SESSION_SUCCESS:
        case EDIT_SESSION_SUCCESS:
            return Object.assign({}, state, {
                errorMsg: null,
                isSaving: false,
                lastSavedSessionId: action.response.sessionId,
            });

        case ADD_SESSION_FAILURE:
        case EDIT_SESSION_FAILURE:
            return Object.assign({}, state, {
                errorMsg: action.errorMsg,
                isSaving: false,
                lastSavedSessionId: null
            });

        case DELETE_SESSION_REQUEST:
            return Object.assign({}, state, {
                errorMsg: null,
                isDeleting: true,
                lastSavedSessionId: null,
            });

        case DELETE_SESSION_SUCCESS:
            return Object.assign({}, state, {
                errorMsg: null,
                isDeleting: false,
                lastSavedSessionId: action.response.sessionId,
            });

        case DELETE_SESSION_FAILURE:
            return Object.assign({}, state, {
                errorMsg: action.errorMsg,
                isDeleting: false,
                lastSavedSessionId: null
            });

        case GET_GAME_SUCCESS:
            return Object.assign({}, state, {
                detailedGameInfo: action.response
            });        

        default:
            return state;
    }
}

/**
 * USER_INFO
 */
const userInfoInitialState = {
    playerId: null,
    firstName: "Me",
    lastName: null,

    // these lists are constrained to references that this specific user has used
    playswith: null,    // [ {player}, ... ]
    hasplayed: null,    // [ {game}, ... ]

    wishlist: null,
    ownedlist: null,
    otherplayedlist: null,  // hasplayed, minus wishlist and ownedlist
};
export function userInfo(state = userInfoInitialState, action) {
    switch (action.type) {
        case USER_INFO_REQUEST:
            return state;

        case USER_INFO_SUCCESS:
            return Object.assign({}, state, {
                playerId: action.response.playerId,
                firstName: action.response.firstName,
                lastName: action.response.lastName,
                playswith: action.response.playswith,
                hasplayed: action.response.hasplayed,
            });

            // These all return the same data ...
        case USER_GAMELIST_SUCCESS:
        case ADD_TO_WISHLIST_SUCCESS:   
        case REMOVE_FROM_WISHLIST_SUCCESS:
        case ADD_TO_OWNEDLIST_SUCCESS:
        case REMOVE_FROM_OWNEDLIST_SUCCESS:
            return Object.assign({}, state, {
                wishlist: action.response.wishlist,
                ownedlist: action.response.ownedlist,
                otherplayedlist: action.response.otherplayedlist
            });

        case USER_INFO_FAILURE:
            return state;

        default:
            return state;
    }
}

/**
 * RECENT_GAMES
 */
const recentGamesInitialState = {
    recentGamesList: []
    // TODO:  State?  loaded?  isloading?  error?
};
export function recentGames(state = recentGamesInitialState, action) {
    switch (action.type) {
        case RECENT_GAMES_REQUEST:
            return state;

        case RECENT_GAMES_SUCCESS:
            return Object.assign({}, {
                recentGamesList: [...action.response],
            });

        case RECENT_GAMES_FAILURE:
            return state;

            // SIDEEFFECT!  When we add a Custom Game, it is off the search dialog, and we want the new game to show up in the results list, 
            // so we will add it in here.
        case ADD_CUSTOM_GAME_SUCCESS:
            return Object.assign({}, {
                recentGamesList: [action.response,
                    ...state.recentGamesList]
            });    

        case ADD_SESSION_SUCCESS:
        case EDIT_SESSION_SUCCESS:
            // if the user adds or edits a session, then the recent games list may be invalid. resetting so we reload as necessary.
            return Object.assign({}, {
                recentGamesList: [],
            });

        default:
            return state;
    }
}

/**
 * ACTIVITY_FEED
 */
const activityFeedInitialState = {
    // TODO:  make this an object indexed by playerId so we don't need to use search()
    feedList: [],   // array of { playerId: X, feedData: {...} }
    errorMsg: null,

    // Potential to add "following" and "other players" as ActivityFeed's.
    // Actually, "following" is probably a different API, but any player (myself included) could be one API?

    // TODO:  State?  loaded?  isloading?  error?
};
export function activityFeed(state = activityFeedInitialState, action) {
    switch (action.type) {
        case ACTIVITY_FEED_REQUEST:
            return Object.assign({}, null, {
                feedList: [...state.feedList],
                errorMsg: null,
            });

        case ACTIVITY_FEED_SUCCESS:
        {
            const newFeedList = [...state.feedList];
            const existingFeed = search(newFeedList, action.requestId, 'playerId');
            if (existingFeed) {
                existingFeed.feedData = [...action.response];
            } else {
                newFeedList.push({
                    playerId: action.requestId,
                    feedData: [...action.response]
                });
            }
            return Object.assign({}, null, {
                feedList: newFeedList,
                errorMsg: null
            });
        }

        case ACTIVITY_FEED_FAILURE:
            return Object.assign({}, null, {
                feedList: [...state.feedList],
                errorMsg: action.errorMsg,
            });

        default:
            return state;
    }
}

/**
 * GAME_FEED
 */
const gameFeedInitialState = {
    // TODO:  make this an object indexed by playerId so we don't need to use search()
    feedList: [],   // array of objects { playerId: X, feedData: {...} }
    errorMsg: null,

    // Potential to add "following" and "other players" as ActivityFeed's.
    // Actually, "following" is probably a different API, but any player (myself included) could be one API?

    // TODO:  State?  loaded?  isloading?  error?
};
export function gameFeed(state = gameFeedInitialState, action) {
    switch (action.type) {
        case GAME_FEED_REQUEST:
            return Object.assign({}, null, {
                feedList: [...state.feedList],
                errorMsg: null,
            });

        case GAME_FEED_SUCCESS:
            return Object.assign({}, null, {
                feedList: [...state.feedList].concat([{
                    gameId: action.requestId,
                    feedData: [...action.response]
                }]),
                errorMsg: null
            });

        case GAME_FEED_FAILURE:
            return Object.assign({}, null, {
                feedList: [...state.feedList],
                errorMsg: action.errorMsg,
            });

        default:
            return state;
    }
}

/**
 * GET_SESSION
 */
const getSessionInitialState = {
    // object of { sessionId: {...sessionInfo} }
    sessionList: {}
};
export function getSession(state = getSessionInitialState, action) {
    switch (action.type) {
        case GET_SESSION_REQUEST:
            return state;

        case GET_SESSION_SUCCESS:
        {
            const newSessionList = Object.assign({}, null, { sessionList: state.sessionList });
            // add (or update) the entry
            newSessionList.sessionList[action.response.sessionId] = action.response;
            return newSessionList;
        }

        case GET_SESSION_FAILURE:
            return state;

            // Listen in on successful feed downloads and index those sessions into our store (e.g. basically a cache of references)
        case ACTIVITY_FEED_SUCCESS:
        {
            const newSessionList = Object.assign({}, null, { sessionList: state.sessionList });
            action.response.forEach((session) => {
                // add (or update) each entry
                newSessionList.sessionList[session.sessionId] = session;
            });
            return newSessionList;
        }

        default:
            return state;
    }
}
