import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addCustomGameRequest } from '../redux/actions/actions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

class AddGameModal extends React.Component {

    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
        handleSaveNewGame: PropTypes.func.isRequired,

        savedGameInfo: PropTypes.object,
        errorMsg: PropTypes.string,

        fullScreen: PropTypes.bool.isRequired // see withMobileDialog below
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            validationError: false,
            gameName: ''
        };
    }

    handleGameNameChange = (event) => {
        this.setState({
            gameName: event.target.value
        });
    }

    handleClose = () => {
        const { handleClose } = this.props;

        handleClose();
    };

    handleSave = () => {
        const { addCustomGameRequest } = this.props;
        const { gameName } = this.state;

        // Simple validation - we only need a first name
        if (!gameName || gameName.length === 0) {
            this.setState({ validationError: true });
            return;
        }

        addCustomGameRequest({
            name: gameName,
        });

        // See below ...
        //handleSaveNewGame();
    };

    // Ok, here is why I end up having to do this:  the "isOpen" flag is in our local state, not in redux.  
    // What Abrimov suggests is more complicated, but basically you put the isOpen state into redux, so that when we get something like 
    // a "new game saved" event, we can update the state to close the dialog.  Since mine is in state, I end up hacking things here to 
    // try to figure out when my props changed ... which is poor.
    // I think the Abrimov way (or something like it) would be better, but a lot more work right now, so I'm just going to hack away for now.
    // https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680
    // https://blog.isquaredsoftware.com/2017/07/practical-redux-part-10-managing-modals/

    componentDidUpdate(prevProps, prevState, snapshot) {
        // basically anywhere I use 'componentDidUpdate()' is highly suspect :)

        const { savedGameInfo, handleSaveNewGame } = this.props;
        if (savedGameInfo && !prevProps.savedGameInfo) {
            // Hey! We just got our first update that the game was saved!
            handleSaveNewGame(savedGameInfo);
        }
    }

    render() {
        const { open, fullScreen } = this.props;
        const { validationError } = this.state;

        return (
            <div>
                <Dialog open={open} fullScreen={fullScreen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create Custom Game</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            If you can't find the game you are looking for in our list, feel free to create your own Custom Game.
                        </DialogContentText>
                        <TextField margin="dense" id="gamename" label="Game Name" type="text" fullWidth onChange={this.handleGameNameChange} 
                            required={true} error={validationError} autoFocus/>

                        <DialogContentText>
                            FUNCTIONALITY NOT WORKING YET PETER!  :)
                        </DialogContentText>

                    </DialogContent>


                </Dialog>
            </div>
        );
    }
}

/*
Goes after </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSave} color="primary">
                            Add
                        </Button>
                    </DialogActions>

and before </Dialog


*/


const mapStateToProps = state => {
    return {
        // results from saving
        savedGameInfo: state.customGame.gameInfo,
        errorMsg: state.customGame.errorMsg
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addCustomGameRequest: (friendInfo) => dispatch(addCustomGameRequest(friendInfo))
    };
}

const AddGameModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withMobileDialog()(AddGameModal));

export default AddGameModalContainer;
