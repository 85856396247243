import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';

class SelectGameListItem extends Component {
    static propTypes = {
        gameInfo: PropTypes.object.isRequired,
        selected: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired
    };

    handleClick = () => {
        const { gameInfo, onClick } = this.props;

        onClick(gameInfo);
    };

    render() {
        const { gameInfo, selected } = this.props;

        return (
            <ListItem button selected={selected} onClick={this.handleClick}>
                <ListItemText primary={gameInfo.name} />
            </ListItem>
        );
    }
}

export default SelectGameListItem;
