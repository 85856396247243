import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as routes from '../../constants/routes';
import GameImage from '../../components/GameImage';
import { toMyDateFormat } from '../../utils/MyDate';
import PlayerAvatar from '../../components/PlayerAvatar';
import Expansions from '../../components/Expansions';

const styles = {
    title: {
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 20,   // have to leave room for scrollbars
        paddingBottom: 5,
        color: '#555555'
    },
    footer: {
        textTransform: 'uppercase',
        color: '#555555'
    },
};

class FeedItem extends React.Component {
    static propTypes = {
        session: PropTypes.object.isRequired,
        includeImages: PropTypes.bool.isRequired
    };

    getScoresArray = () => {
        const { session } = this.props;

        return Object.keys(session.scores).map(key => {
            return session.scores[key];
        }).sort((a, b) => a.position - b.position);
    }


    render() {
        const { session, includeImages, classes } = this.props;
        //console.log(`FeedItem.render() - ${JSON.stringify(session, null, 2)}`);

        const when = toMyDateFormat(session.date);
        const location = session.location ? ` - ${session.location}` : '';
        const footerString = `${when} ${location}`;     // no - if no location

        // eg: "Tim, Maija and Zoe played Kingdom Builder"
        const playerImages = [];
        let title = '';
        const numPlayers = session.scores.length;
        this.getScoresArray().forEach((score, index) => {
            if (0 === index) {
                title = `${score.playerInfo.nickName}`;
            } else {
                if (numPlayers - index > 1) {
                    title = `${title}, ${score.playerInfo.nickName}`
                } else {
                    title = `${title} and ${score.playerInfo.nickName}`
                }
            }
            playerImages.push(<PlayerAvatar key={index} playerId={score.playerId}/>)
        });
        title = `${title} played ${session.gameInfo.name}`;

        const eventlink = routes.SESSION_INFO.replace(':id', session.id);
        let gameImage = '';
        if (includeImages !== false) {
            gameImage = <GameImage game={session.gameInfo} link={eventlink}/>;
        }

        return (
            <div key={session.id} className="feeditem">
                <div className="feeditem_child">
                    <div className='playerimages'>{playerImages}</div>
                </div>
                <div className="feeditem_child">
                    <Typography variant="body1" color="secondary" className={classes.title}>
                        {title}
                    </Typography>
                </div>
                <div className="feeditem_child">
                    {gameImage}
                </div>
                <Expansions expansions={session.expansions}/>
                <div className="feeditem_footer">
                    <Typography variant="body1" color="secondary" className={classes.footer}>{footerString}</Typography>
                </div>                
            </div>
        );
    }
}

export default withStyles(styles)(FeedItem);

