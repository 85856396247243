import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

class PlayerScore extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        score: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,

        onClickRow: PropTypes.func.isRequired
    };

    onClickRow = () => {
        const { onClickRow, index } = this.props;
        onClickRow(index);
    }

    render() {
        const { classes, score, index } = this.props;

        return (
            <tr key={index + 1} className={`sessiontable_tr ${score.isWinner ? classes.winnerRow : classes.loserRow}`} onClick={this.onClickRow}>
                <td className="sessiontable_td">
                    <Typography variant="body1" color="inherit">
                        {score.nickName}
                    </Typography>
                </td>
                <td className="sessiontable_td" align="right">
                    <Typography variant="body1" color="inherit">
                        {score.score}
                    </Typography>
                </td>
            </tr>
        );
    }
}

export default PlayerScore;
