import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import AddComment from './AddComment';
import GroupedComment from './GroupedComment';

export class SessionDiscussion extends Component {
    static propTypes = {
        userId: PropTypes.string.isRequired,
        discussionId: PropTypes.string.isRequired,
        //discussion: PropTypes.object,
        discussion: PropTypes.array
    };

    render() {
        const { discussionId, discussion } = this.props;
        //console.log(this.props);

        if (!discussion) {
            return '';
        }

        //console.error('Discussion: ', JSON.stringify(discussion, null, 2));

        // Group the comments by author
        let groupedComments = [];
        let commentGroup = null;
        const iMax = discussion.length;
        for (let i=0; i<iMax; i++) {
            let comment = discussion[i];

            if (commentGroup && (commentGroup.author === comment.author)) {
                commentGroup.comments.push(comment);
            } else {
                // either we don't have a group, then create one, or the author doesn't match, then create a new group
                commentGroup = {
                    author: comment.author,
                    comments: [comment]
                };
                groupedComments.push(commentGroup);
            }
        }
        
        const result = groupedComments.map((group, index) => {
            return <GroupedComment key={index} group={group} />;
        });

        return (
            <div className='discussion'>
                {result}
                <AddComment discussionId={discussionId} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { discussionId } = ownProps;
    //console.log('Session Discussion: ', JSON.stringify(state.firestore.ordered, null, 2));

    const discussion =
        state &&
        state.firestore &&
        state.firestore.ordered &&
        state.firestore.ordered[`orderedSessionDiscussion${discussionId}`];

    return {
        userId: state.firebase.auth.uid,
        discussion: discussion ? discussion : null
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firestoreConnect(props => {
        //if (!props.uid) return []; // I think this means not logged in
        const { discussionId } = props;
        return [
            {
                collection: 'sessionDiscussion',
                doc: props.discussionId,
                subcollections: [{ collection: 'comments' }],
                orderBy: [
                    ['createDate']      // note: this becomes a required field, any record without this field won't be returned
                ],
                // we have multiple queries for this collection, and they all end up in "ordered", which causes ordering problems, so we give each one a custom store
                storeAs: `orderedSessionDiscussion${discussionId}`
            }
        ];
    })
)(SessionDiscussion);
