
export const EXPANSIONS_LOADED = 'EXPANSIONS_LOADED';

// expansions should be a data structure like:
// { gameId: [...expansions], gameId: [...expansions], gameIdWithNoExpansions: [] }
export const expansionsLoaded = (expansions) => {
    return {
        type: EXPANSIONS_LOADED,
        expansions
    };
};
