import moment from 'moment';

export function toMyDateFormat(sessionDate) {

    const mDateString = moment(sessionDate);
    const timediff = moment().diff(mDateString);    

    //const timediff = Date.now() - sessionDate;
    if (timediff < 0) {
        return 'Soon';
    } else if (timediff < ONE_DAY) {
        return 'Today';
    } else if (timediff < TWO_DAYS) {
        return 'Yesterday';
    } else if (timediff < ONE_WEEK) {
        return weekday[mDateString.day()];
    } else {
        return mDateString.format('LL');
    }    
}

// Helpful article: https://stackoverflow.com/questions/7709803/javascript-get-minutes-between-two-dates
const weekday = new Array(7);
weekday[0] =  "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

const ONE_MINUTE = 60000;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;
const TWO_DAYS = ONE_DAY * 2;
const ONE_WEEK = 7 * ONE_DAY;
