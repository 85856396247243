import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import BlockIcon from '@material-ui/icons/Block';
import ClearIcon from '@material-ui/icons/Clear';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PlayerAvater from '../../components/PlayerAvatar';
import * as routes from '../../constants/routes';
import firebase from 'firebase/app';

const styles = {
    friendDescription: {
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            margin: "5px",
          },
    }
};

const progressIndicator = () => {
    return (
        <div className='friendslist_progress'>
            <CircularProgress size={20} />
        </div>
    )
}

class FriendName extends React.Component {
    static propTypes = {
        friend: PropTypes.object.isRequired,
        friendType: PropTypes.oneOf(['friend', 'incoming', 'outgoing']),

        addFollowRequest: PropTypes.func.isRequired,
        ignoreFollowRequest: PropTypes.func.isRequired
    };

    constructor(props, context) {
        super(props, context);

        this.handleAddFollow = this.handleAddFollow.bind(this);
        this.handleIgnoreFollow = this.handleIgnoreFollow.bind(this);

        this.state = {
            serverRequestStarted: false
        };
    }

    handleAddFollow() {
        // NOTE:  leaving addFollowRequest there, because we will need to create dispatch
        //        methods to capture errors at a minimum, and maybe a spinner on request ...
        const { friend, addFollowRequest } = this.props;

        //addFollowRequest(friend.userId);

        this.setState({ serverRequestStarted: true });

        console.log('handleAddFollow: ', friend);
        var addMessage = firebase.functions().httpsCallable('addFriend');
        addMessage({ friendId: friend.id })
            .then(function(result) {
                // Read result of the Cloud Function.
                var sanitizedMessage = result.data;
                console.log('addFriend result: ', sanitizedMessage);
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                // ...
                console.error('addFriend failed!', code, message, details);
            });
    }

    handleIgnoreFollow() {
        const { friend, ignoreFollowRequest } = this.props;

        this.setState({ serverRequestStarted: true });

        //ignoreFollowRequest(friend.userId);

        console.log('handleIgnoreFollow: ', friend);
        var addMessage = firebase.functions().httpsCallable('removeFriend');
        addMessage({ friendId: friend.id })
            .then(function(result) {
                // Read result of the Cloud Function.
                var sanitizedMessage = result.data;
                console.log('removeFriend result: ', sanitizedMessage);
            })
            .catch(function(error) {
                // Getting the Error details.
                var code = error.code;
                var message = error.message;
                var details = error.details;
                // ...
                console.error('removeFriend failed!', code, message, details);
            });
    }

    render() {
        const { classes, friend, friendType } = this.props;
        const { serverRequestStarted } = this.state;

        let friendTypeContent = '';
        switch (friendType) {
            default:
            case 'friend':
                friendTypeContent = serverRequestStarted ? (
                    progressIndicator()
                ) : (
                    <Button variant="text" color="secondary" size="small" onClick={this.handleIgnoreFollow}>
                        <ClearIcon />
                    </Button>
                );
                break;
            case 'incoming':
                friendTypeContent = serverRequestStarted ? (
                    progressIndicator()
                ) : (
                    <React.Fragment>
                        <Button variant="text" color="secondary" size="small" onClick={this.handleAddFollow}>
                            <PersonAddIcon />
                            Approve
                        </Button>
                        <Button variant="text" color="secondary" size="small" onClick={this.handleIgnoreFollow}>
                            <ClearIcon />
                            Decline
                        </Button>
                    </React.Fragment>
                );
                break;
            case 'outgoing':
                friendTypeContent = serverRequestStarted ? (
                    progressIndicator()
                ) : (
                    <Button variant="text" color="secondary" onClick={this.handleIgnoreFollow}>
                        <BlockIcon />
                        Cancel Request
                    </Button>
                );
                break;
        }

        return (
            <div className="friendrow">
                <div className={classes.friendDescription}>
                    <PlayerAvater playerId={friend.id}/>
                    <Typography
                        variant="body1"
                        color="primary"
                        component={Link}
                        to={routes.PLAYER_INFO.replace(':id', friend.id)}
                    >
                        {friend.nickName}
                    </Typography>
                </div>
                <div>
                    {friendTypeContent}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(FriendName);
