import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography';
import './Expansions.css';

function Expansions(props) {
    const { expansions } = props;

    if (!expansions) {
        return null;
    }

    const list = expansions.map((expansion, id) => {
        return (
            <Typography key={id} variant="body1" color="primary">
                {expansion.name}
            </Typography>
        );
    });

    return (
        <div className="cmpnt_expansions">
            <Typography variant="caption" color="primary">
                with:{' '}
            </Typography>
            <div className="cmpnt_expansionslist">{list}</div>
        </div>
    );
}

Expansions.propTypes = {
    expansions: PropTypes.array     // can be null, in which case we won't render anything
};

export default Expansions
