import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => {
    return {
        alert: {
            backgroundColor: theme.palette.error.main
        }
    };
};

// mimic'd after bootstrap Alert class
// https://react-bootstrap.github.io/components/alerts/
export class Alert extends Component {
    static propTypes = {
        variant: PropTypes.oneOf(['error']).isRequired,
        message: PropTypes.string.isRequired,
        onDismiss: PropTypes.func.isRequired
    };

    render() {
        const { classes, /*variant,*/ message, onDismiss } = this.props;

        return (
            <div className={classes.alert} onClick={onDismiss}>
                {message}
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Alert);