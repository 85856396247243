import { Component } from 'react';
import { withRouter } from 'react-router';

// https://reacttraining.com/react-router/web/guides/scroll-restoration
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        // when we change routes ...
        if (this.props.location !== prevProps.location) {
            // scroll our window back to the top
            window.scrollTo(0, 0);

            // remove the focus (e.g. from a nav button)
            document.activeElement.blur();
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);
