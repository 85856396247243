// We will create a simple mechanism to store mulitple values
// in localStorage.
// Strictly speaking not necessary, but collects it all in one
// place nicely, and we could move this to firebase or elsewhere
// very easily.

const LOCAL_STORAGE_KEY = 'meeples.app';
class Cache {

    constructor() {
        try {
            this._data = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (this._data) {
                this._data = JSON.parse(this._data);
            } else {
                this._data = {};
            }
        } catch (err) {
            // If we get an error here, then the content in localStorage is 
            // most likely corrupt and we should just nuke it.
            console.error('Local storage corrupt.  Purging content.');
            this._data = {};
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this._data));
            // TODO:  log this error to the server with the original content?
        }
    }

    add(key, value) {
        this._data[key] = value;
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this._data));
    }

    get(key, defaultValue) {
        if (this._data[key]) {
            return this._data[key];
        }
        return defaultValue;
    }
}

// Goodness!  https://www.sitepoint.com/javascript-design-patterns-singleton/
const instance = new Cache();
Object.freeze(instance)

export default instance;
