import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import AddPlayerModal from '../../components/AddPlayerModal';
import * as routes from '../../constants/routes';
import './FTUView.css';

const styles = theme => {
    //console.error(JSON.stringify(theme, null, 2));
    return {
        tab: {
            backgroundColor: theme.palette.secondary.main,
            textTransform: 'lowercase'
        },
        paper: {
            padding: 0
        }
    };
};

export class FTUView extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleClickAddPlayer = this.handleClickAddPlayer.bind(this);
        this.handleCloseAddPlayerDialog = this.handleCloseAddPlayerDialog.bind(this);

        this.state = {
            showAddPlayerModal: false
        };
    }

    handleClickAddPlayer() {
        this.setState({
            showAddPlayerModal: true
        });
    }

    handleCloseAddPlayerDialog() {
        this.setState({
            showAddPlayerModal: false
        });
    }

    render() {
        //const { classes } = this.props;
        const { showAddPlayerModal } = this.state;

        return (
            <div className="ftu">
                <div className="ftu_row">
                    <Typography variant="h5" color="secondary">
                        Click the people icon to add your friends
                    </Typography>
                    <div className="ftu_row_button">
                        <Fab
                            color="primary"
                            aria-label="Add Friend"
                            onClick={this.handleClickAddPlayer}
                        >
                            <PersonAddIcon />
                        </Fab>
                    </div>
                </div>
                <div className="ftu_row">
                    <Typography variant="h5" color="secondary">
                        Click to post a new game
                    </Typography>
                    <div className="ftu_row_button">
                        <Fab
                            color="primary"
                            aria-label="Add Game"
                            component={Link}
                            to={routes.ADD_SESSION}
                        >
                            <AddIcon fontSize="large" />
                        </Fab>
                    </div>
                </div>

                <AddPlayerModal
                    open={showAddPlayerModal}
                    handleClose={this.handleCloseAddPlayerDialog}
                    handleSaveNewPlayer={this.handleCloseAddPlayerDialog}
                />
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(FTUView);
