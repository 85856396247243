import { connect } from 'react-redux';
import { addFollowRequest, ignoreFollowRequest } from '../../redux/actions/players';
import FriendName from './FriendName';

// This is here so we don't need to pass these dispatch functions down through 5 class levels.
const mapDispatchToProps = dispatch => {
    return {
        addFollowRequest: (id) => dispatch(addFollowRequest(id)),
        ignoreFollowRequest: (id) => dispatch(ignoreFollowRequest(id))
    };
}

const FriendNameContainer = connect(
    null,
    mapDispatchToProps
)(FriendName);

export default FriendNameContainer;
