import React from 'react';
import ErrorLogger from '../utils/ErrorLogger';

// Copied right from:
// https://reactjs.org/docs/error-boundaries.html

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // this will already be in the console, so just need to call our error logger
        //logError(error, info.componentStack);
        ErrorLogger(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went horribly wrong.  Please refresh your browser.</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
