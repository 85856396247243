
/**
 * Stats should look like this:
 *
const stats = [
    {
        wins: 0,
        numberTimesPlayed: 0,

        id: X (player or game),
        name: Y (player or game)
    }
];
*/

function incrementCounts(data, key1, key2, isWinner, name) {
    if (!data[key1]) {
        data[key1] = {};
    }
    if (!data[key1][key2]) {
        data[key1][key2] = {
            wins: isWinner ? 1 : 0,
            numberTimesPlayed: 1,
            id: key2,
            name: name
        };
    } else {
        data[key1][key2].numberTimesPlayed++;
        if (isWinner) {
            data[key1][key2].wins++;
        }
    }
}

export function generateStats(activityFeed, gameList, playerList) {
    const statsByPlayer = {};
    const statsByGame = {};

    activityFeed.forEach(session => {
        Object.keys(session.scores).forEach(key => {
            const score = session.scores[key];
            
            // our data requests are async, and sometimes we get them not in the order we'd perfer :)
            const gameName = gameList[session.gameId] ? gameList[session.gameId].name : '';
            const playerName = playerList[score.playerId] ? playerList[score.playerId].nickName : '';

            incrementCounts(statsByPlayer, score.playerId, session.gameId, score.isWinner, gameName);
            incrementCounts(statsByGame, session.gameId, score.playerId, score.isWinner, playerName);
        })
    });

    const result = { 
        byPlayer: statsByPlayer, 
        byGame: statsByGame 
    };
    //console.warn('generateStats', JSON.stringify(result, null, 2));
    return result;
}