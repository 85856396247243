import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import * as routes from '../../constants/routes';
import SelectGameModal from '../../components/SelectGameModal';
import { reduceList } from '../../utils/ArrayUtils';
import GridBox from './GridBox';

import './GameList.css';

const styles = theme => ({
    gridHeading: {
        lineHeight: '48px'
    },
    fab: {
        marginLeft: '20px'
    }
});

class GameListList extends React.Component {
    static propTypes = {
        wishlist: PropTypes.array,
        owned: PropTypes.array,
        played: PropTypes.array,
        fullGameList: PropTypes.array.isRequired,

        addToWishlist: PropTypes.func.isRequired,
        addToOwnedlist: PropTypes.func.isRequired
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            selectGameDialogOpen: false,
            onSelectGameCallback: this.selectWishListGame // this is just a default to get rid of the failed prop warning in SelectGameModal
        };
    }

    handleCloseSelectGame = () => {
        this.setState({
            selectGameDialogOpen: false
            //onSelectGameCallback: null,                   // don't clear it or we will get the empty prop warning in SelectGameModal
        });
    };

    handleClickAddWishListGame = () => {
        const { wishlist, fullGameList } = this.props;
        this.setState({
            selectGameDialogOpen: true,
            onSelectGameCallback: this.selectWishListGame,
            selectGameDefaultList: reduceList(fullGameList, wishlist)
        });
    };
    // The first parameter is the gameInfo selected by the user, letting you know they clicked on something.
    // The second parameter is a promise that is guaranteed to have the complete game info (in case they selected a new game),
    // and may be a few moments delayed.
    selectWishListGame = (gameInfo, promise) => {
        const { addToWishlist } = this.props;

        this.handleCloseSelectGame();
        promise.then(guaranteedGameInfo => {
            addToWishlist(guaranteedGameInfo);
        });
    };

    handleClickAddOwnedListGame = () => {
        const { owned, fullGameList } = this.props;
        this.setState({
            selectGameDialogOpen: true,
            onSelectGameCallback: this.selectOwnedListGame,
            selectGameDefaultList: reduceList(fullGameList, owned)
        });
    };
    // See selectWishListGame above for more information on parameters.
    selectOwnedListGame = (gameInfo, promise) => {
        const { addToOwnedlist } = this.props;

        this.handleCloseSelectGame();
        promise.then(guaranteedGameInfo => {
            addToOwnedlist(guaranteedGameInfo);
        });
    };

    // called during render() to create a gridlist
    createGridList(classes, title, listofgames, emptyListMessage, addGameCallback) {
        return (
            <div className="gamelist">
                <div className="gamelist_heading">
                    <Typography className={classes.gridHeading} variant="h5" color="inherit">
                        {title}
                        {addGameCallback ? (
                            <Fab
                                color="primary"
                                size="small"
                                aria-label="Add"
                                className={classes.fab}
                                onClick={addGameCallback}
                            >
                                <AddIcon />
                            </Fab>
                        ) : (
                            ''
                        )}
                    </Typography>
                </div>
                <div className="gamelist_grid">
                    {listofgames.length === 0 ? (
                        <div className="gamelist_heading">
                            <Typography className={classes.gridHeading} variant="body1" color="inherit">
                                {emptyListMessage}
                            </Typography>
                        </div>
                    ) : (
                        listofgames.map((gameInfo, index) => {
                            return (
                                <GridBox
                                    key={index}
                                    imgUrl={gameInfo.imageUrl}
                                    title={gameInfo.name}
                                    link={routes.GAME_INFO.replace(':id', gameInfo.id)}
                                />
                            );
                        })
                    )}
                </div>
            </div>
        );
    }

    render() {
        const { wishlist, owned, played, classes } = this.props;
        const { selectGameDialogOpen, onSelectGameCallback, selectGameDefaultList } = this.state;

        // You have no games on your wishlist ...

        //return <div className="gamelistlist">{list}</div>;
        return (
            <div className="gamelistview">
                {this.createGridList(
                    classes,
                    'Wishlist',
                    wishlist,
                    'You have no games in your wishlist.',
                    this.handleClickAddWishListGame
                )}
                {this.createGridList(
                    classes,
                    'Games you own',
                    owned,
                    'You have not marked any games as owned.',
                    this.handleClickAddOwnedListGame
                )}
                {this.createGridList(classes, 'Other games in your feed', played, 'You should play more games!', null)}
                <SelectGameModal
                    open={selectGameDialogOpen}
                    selectedGame={'-1'}
                    onCancel={this.handleCloseSelectGame}
                    onSelectGame={onSelectGameCallback}
                    defaultList={selectGameDefaultList}
                />
            </div>
        );
    }
}

export default withStyles(styles)(GameListList);
