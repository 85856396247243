import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SelectPlayerListItem from './SelectPlayerListItem';
import Fab from '@material-ui/core/Fab';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddPlayerModal from './AddPlayerModal';

const styles = theme => ({
    appBar: {
        position: 'relative'
    },
    flex: {
        flex: 1
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} />
  ));

class SelectPlayerModal extends Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSelectPlayer: PropTypes.func.isRequired,

        playerList: PropTypes.array.isRequired,     // we are passed in a modified player list that has removed players already in the game
        selectedPlayer: PropTypes.string            // null if no selection
    };

    constructor(props) {
        super(props);

        this.state = {
            showAddPlayerModal: false
        };
    }

    handleClickAddPlayer = () => {
        this.setState({
            showAddPlayerModal: true
        });
    }
    handleCloseAddPlayerDialog = () => {
        this.setState({
            showAddPlayerModal: false
        });
    }
    handlePlayerClick = (playerInfo) => {
        const { onSelectPlayer } = this.props;

        onSelectPlayer(playerInfo);
    }
    handleSaveNewPlayer = playerInfo => {
        this.handleCloseAddPlayerDialog();
        this.handlePlayerClick(playerInfo);
    };

    getPlayerList() {
        const { playerList, selectedPlayer } = this.props;

        const result = [];
        if (!playerList) {
            return result;
        }
        //for (const [playerId, playerInfo] of Object.entries(playerList)) {
        playerList.forEach(playerInfo => {
            result.push(
                <SelectPlayerListItem key={playerInfo.id} selected={playerInfo.id===selectedPlayer} playerInfo={playerInfo} onClick={this.handlePlayerClick} />
            );
            result.push(<Divider key={playerInfo.id + 'divider'}/>);
        });

        return result;
    }

    render() {
        const { classes, open, onCancel  } = this.props;
        const { showAddPlayerModal } = this.state;

        return (
            <Dialog fullScreen open={open} onClose={onCancel} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={onCancel} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Edit
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List>
                    {this.getPlayerList()}
                </List>
                <AddPlayerModal 
                    open={showAddPlayerModal}
                    handleClose={this.handleCloseAddPlayerDialog}
                    handleSaveNewPlayer={this.handleSaveNewPlayer} />
                <Fab
                    color="primary"
                    aria-label="Add"
                    className={classes.fab}
                    onClick={this.handleClickAddPlayer}
                >
                    <PersonAddIcon />
                </Fab>
            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SelectPlayerModal);
