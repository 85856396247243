import { CALL_API } from '../middleware/havocapi';
import { isEmptyObj } from '../../utils';

/*
 * New and Simplied client-side only version
 */
export const STATS_UPDATE = 'STATS_UPDATE';
export const updateStats = statsData => {
    return {
        type: STATS_UPDATE,
        statsData
    }
};


/*
 * ACTIVITY_FEED_STATS
 */
export const ACTIVITY_FEED_STATS_REQUEST = 'ACTIVITY_FEED_STATS_REQUEST';
export const ACTIVITY_FEED_STATS_SUCCESS = 'ACTIVITY_FEED_STATS_SUCCESS';
export const ACTIVITY_FEED_STATS_FAILURE = 'ACTIVITY_FEED_STATS_FAILURE';
export const ACTIVITY_FEED_STATS_MERGE_GAMEPLAYER_INFO = 'ACTIVITY_FEED_STATS_MERGE_GAMEPLAYER_INFO';
export const ACTIVITY_FEED_STATS_MERGE_GAME_INFO = 'ACTIVITY_FEED_STATS_MERGE_GAME_INFO';

export const activityFeedStatsRequest = playerId => {
    return {
        [CALL_API]: {
            types: [ACTIVITY_FEED_STATS_REQUEST, ACTIVITY_FEED_STATS_SUCCESS, ACTIVITY_FEED_STATS_FAILURE],
            endpoint: `players/statsbyplayer/${playerId}`,
            requestId: playerId
        }
    };
};

export const loadActivityFeedStatsThunk = playerId => (dispatch, getState) => {

    // TODO - how do we know if this is dirty? (e.g. Session Add or Edit?)
    const state = getState();
    if (state.statsState.rawData[playerId]) {
        return null;
    }

    // This whole "side-effect" of caching the gameList as we find it
    // is screwing us up.  I'm ending up meta-programming around it.
    // The reality is that we need the userList and gameList before
    // we call this.  If we made that explicit (e.g. on login) then
    // everything gets vastly simpler ...
    //const playerList = getState().playerList.playerList;
    //const gameList = getState().gameList.gameList;
    
    const playerList = state.listcache.playerList;
    const gameList = state.listcache.gameList;
    
    // if for some reason we don't have the playerList or gameList, then don't
    // bother continuing because it will fail
    if (!playerList || isEmptyObj(playerList) || !gameList || isEmptyObj(gameList)) {
        return null;
    }

    let response = dispatch(activityFeedStatsRequest(playerId));

    // When we are done, create a new event to merge playerInfo and gameInfo.
    // We are *INFERING* that they have been loaded already, given how
    // I structured the FeedList.jsx and StatsList.jsx classes, but if
    // that changes we may need to chain more calls together in this thunk.
    response.then(
        success => {
            return dispatch({
                type: ACTIVITY_FEED_STATS_MERGE_GAMEPLAYER_INFO,
                playerId: playerId,
                playerList,
                gameList
            });
        },
        error => {
            return error;
        }
    );
};
