import React from 'react';
import PropTypes from 'prop-types';
import FeedList from '../views/activityfeed/FeedList';
import 'react-rater/lib/react-rater.css';
import '../components/MyRater.css'; // to override the default colors - e.g. make them yellow
import Loading from '../components/Loading';

import Cache from '../utils/Cache';
const CACHE_LAST_TAB = 'CACHE_LAST_TAB';

class ViewGame extends React.Component {

    static propTypes = {
        gameId: PropTypes.string,
        activityFeed: PropTypes.array,      // null if it hasn't finished loading yet
        activityFeedStats: PropTypes.object,
        gameInfo: PropTypes.object,
    };

    render() {
        const { gameId, activityFeed, activityFeedStats } = this.props;

        if (!activityFeed) {
            return <Loading />
        }

        return (
            <div className="afDiv">
                <div className="gameActivityList">
                    <FeedList gameId={gameId} activityFeed={activityFeed} activityFeedStats={activityFeedStats}includeImages={false} defaultTabIndex={2} />
                </div>
            </div>
        );
    };
}

export default ViewGame;
