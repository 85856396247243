import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as routes from '../constants/routes';
import { IMAGE_THUMBNAIL_URL, IMAGE_BASE_URL } from '../constants';

class GameImage extends React.Component {
    static propTypes = {
        game: PropTypes.object, // under some refresh scenarios this might be null on the first render
        link: PropTypes.string // where clicking on the image should take you ...
    };

    render() {
        const { game, link } = this.props;

        if (!game) {
            return 'Loading...';
        }

        const { id, name, imageUrl } = game;

        const gamelink = link ? link : routes.GAME_INFO.replace(':id', id);

        if (imageUrl && imageUrl.length > 0) {
            const imgurl = IMAGE_THUMBNAIL_URL + imageUrl;
            const fullimgurl = IMAGE_BASE_URL + imageUrl;

            // TODO:  at some point we could look at the "sizes=X" attribute that goes along with srcSet, but
            //        this is difficult to do generically as GameImage's appear on several different pages/sizes.
            //        or is it just 2?  feed and new?

            return (
                <div className="gameimage">
                    <Link to={gamelink}>
                        <img
                            className="gameimage"
                            src={imgurl}
                            srcSet={`${imgurl} 600w, ${fullimgurl} 800w`}
                            alt={name}
                        />
                    </Link>
                </div>
            );
        } else {
            return (
                <div className="gameimage gameimage_placeholder">
                    <Link to={gamelink}>
                        <p className="dont-break-out">{name}</p>
                    </Link>
                </div>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {

    // Under some situations the game object we are passed won't have all the gameInfo in it.
    // If so, check if it is in the cache, and if so, use that version.
    if (ownProps.game) {
        if (ownProps.game.id && !ownProps.game.imageUrl) {
            if (state.gamelist[ownProps.game.id]) {
                return {
                    game: Object.assign({ id: ownProps.game.id }, state.gamelist[ownProps.game.id])
                };
            }
            if (state.firestore.data.gameList && state.firestore.data.gameList[ownProps.game.id]) {
                return {
                    game: Object.assign({id: ownProps.game.id}, state.firestore.data.gameList[ownProps.game.id])   // have to add in id when pulling from firestore.data ...
                };
            }
        }
    }

    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GameImage);
