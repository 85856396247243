import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { isLoaded } from 'react-redux-firebase';
import withAuthorization from '../withAuthorization';
import AppBar from './AppBar';
import Loading from './Loading';
import SystemErrorComponent from './SystemErrorComponent';
import ErrorBoundary from './ErrorBoundary';
import DataLoad from '../containers/DataLoad';

function constructErrorMessage(status, error, message) {
    let statusMsg = status ? `[${status}] ` : '';
    let errorMsg = error || '';
    let messageMsg = message || '';
    let separator = error && message ? ': ' : '';

    return `${statusMsg}${errorMsg}${separator}${messageMsg}`;
}

class PageBody extends React.Component {

    render() {
        const { fatalError, isLoaded, match } = this.props;

        // Could potentially put this higher in the app, but let's put it here for now
        if (fatalError.error || fatalError.message) {
            const { error, message, status } = fatalError;
            let errorMessage = constructErrorMessage(status, error, message);
            return (
                <div>
                    <h1>System Down</h1>
                    <div>
                        We apologize for this crappy page, but there's something more important for us to be fixing
                        right now!
                    </div>
                    <h3>{errorMessage}</h3>
                </div>
            );
        }

        // Pause child rendering until we know we have the auth state,
        // so that children don't have to worry about the timing of their
        // componentDidMount() service calls.
        //if (!authTokenSet || !userId) {
        if (!isLoaded) {
            return <Loading />;
        } else {
            // The outer ErrorBoundary is just in case the AppBar fails.
            // Probably want a different UI treatment for these two cases.
            return (
                <ErrorBoundary>
                    <AppBar match={match} />
                    <ErrorBoundary>
                        <div className="pagebody">
                            <SystemErrorComponent />
                            <DataLoad>
                                {this.props.children}
                            </DataLoad>
                        </div>
                    </ErrorBoundary>
                </ErrorBoundary>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        isLoaded: isLoaded(state.firebase.auth),
        fatalError: state.errorState.fatalError
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

const authCondition = authUser => !!authUser;

export default compose(withAuthorization(authCondition), connect(mapStateToProps, mapDispatchToProps))(PageBody);