
import { EXPANSIONS_LOADED } from '../actions/expansions';

/*
 * Expansions
 * { gameId: [...expansions], gameId: [...expansions] }
 */
const expansionsInitialState = {
};
export default function expansionsReducer(state = expansionsInitialState, action) {

    switch (action.type) {
        case EXPANSIONS_LOADED:
            return Object.assign({}, state, action.expansions);
            //return { expansions: Object.assign({}, state.expansions, action.expansions)};

    default:
        return state;
    }
}