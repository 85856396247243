import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { firebaseConnect, firestoreConnect, isLoaded } from 'react-redux-firebase';
import { loadMultipleExpansions } from '../../firebase/handlers';
import { expansionsLoaded } from '../../redux/actions/expansions';
import ActivityFeed from './ActivityFeed';
import { mergeData } from '../../utils/FeedMerge';
import { generateStats } from '../../utils/GenerateStats';

const mapStateToProps = state => {
    let playerId = null;
    if (isLoaded(state.firebase.profile)) {
        playerId = state.firebase.profile.pid;
    }

    // let's make it easier and don't render if we don't have these lists ...
    const loaded = isLoaded(state.firestore.data.feedList) && isLoaded(state.firestore.data.gameList) && isLoaded(state.firestore.data.playerList);

    // For new users these lists are all null
    const feedList = state.firestore.ordered.feedList || [];
    const playerList = state.firestore.data.playerList || {};
    const gameList = state.firestore.data.gameList || {};

    let mergedFeed = [];
    let activityFeedStats = null;
    if (loaded) {
        mergedFeed = mergeData(feedList, playerList, gameList);

        // It can take several seconds for the cloud function to update the feed when we create/edit/delete a session.
        // The listener will pick up the changes and update soon (but we don't have a good way to key off of that event).
        const lastSavedSessionId = state.saveState.lastSavedSessionId;
        const lastSavedSession = state.saveState.lastSavedSession;
        const lastDeletedSessionId = state.saveState.lastDeletedSessionId;

        // If the feed contains an element that we just deleted, remove it manually.
        if (lastDeletedSessionId) {
            const deletedIndex = mergedFeed.findIndex(session => session.id === lastDeletedSessionId);
            if (deletedIndex >= 0) {
                mergedFeed.splice(deletedIndex, 1);
                //console.error('Detected Deleted Session.');
            }
        }

        // If the feed contains an element that we just saved, then update the feed with our current data.
        if (lastSavedSessionId && lastSavedSession) {
            const sessionIndex = mergedFeed.findIndex(session => session.id === lastSavedSessionId);
            if (sessionIndex < 0) {

                // Note:  The playerList and gameList do not have this game ... could be new player or game ...
                //        so stats is failing to calculate on these newly merged games ... 
                //        We will calculate stats without this session until the cloud function updates us
                activityFeedStats = generateStats(mergedFeed, gameList, playerList);

                // We created a new session.
                const newMergedSession = mergeData([lastSavedSession], playerList, gameList).shift();
                mergedFeed.unshift(Object.assign({ id: lastSavedSessionId }, newMergedSession));  // add in the id field just in case they click on it
                //console.error('Detected New Session.', newMergedSession);

            } else {
                const oldSession = mergedFeed[sessionIndex];
                if (lastSavedSession.lastUpdated.seconds === oldSession.lastUpdated.seconds) {      // the update will increment the lastUpdated time
                    // We updated a session.
                    const editedMergedSession = mergeData([lastSavedSession], playerList, gameList).shift();
                    mergedFeed[sessionIndex] = editedMergedSession;
                    //console.error('Detected Edited Session.');
                }
            }
        }
    }
    // Some extra funky logic due to new sessions injected into the feed
    if (!activityFeedStats) {
        activityFeedStats = generateStats(mergedFeed, gameList, playerList);
    }

    const result = {
        isProfileLoaded: isLoaded(state.firebase.profile),
        userId: state.firebase.auth.uid,    // This is here for requests back to firebase.

        isFeedLoaded: loaded,
        playerId: playerId,                 // This is here for detecting new users.
        activityFeed: mergedFeed,
        activityFeedStats,

        expansionsList: state.expansions    // This property is using in the loadExpansions handler below.
    };
    //console.warn('Activity Feed State: ', result);
    return result;
};

const mapDispatchToProps = dispatch => {
    return {
       // We add this function to properties, and it is used in the loadExpansions handler below.
       expansionsLoaded: expansions => dispatch(expansionsLoaded(expansions)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect(),
    withHandlers({
        loadMultipleExpansions
    })
)(ActivityFeed);
