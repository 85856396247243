import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { isLoaded } from 'react-redux-firebase';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { saveEditSession } from '../../firebase/handlers';
import { mergeData } from '../../utils/FeedMerge';
import AddSessionContainer from './AddSessionContainer';
import Loading from '../../components/Loading';

export class EditSession extends Component {
    static propTypes = {
        isLoaded: PropTypes.bool.isRequired,
        isInvalidSession: PropTypes.bool.isRequired,
        sessionId: PropTypes.string.isRequired,
        sessionInfo: PropTypes.object, // will be present, but possibly null
        saveEditSession: PropTypes.func.isRequired,
    };

    // We need the function signature for "save()" to be the same so that AddSession doesn't need to know the difference.
    // But we need to add sessionId into the saveEdit call, so we have this middleman function here add it.
    saveSessionRequest = (sessionInfo, onCompleted, onError) => {
        const { sessionId, saveEditSession } = this.props;
        saveEditSession(sessionId, sessionInfo, onCompleted, onError);
    };

    render() {
        const { isLoaded, isInvalidSession, sessionId, sessionInfo, history } = this.props;

        // if we don't have the sessionInfo yet, hang on a sec while we make a request for it
        if (!isLoaded) {
            return <Loading />;
        }
        if (isInvalidSession) {
            return <h3>Invalid session</h3>;
        }

        return (
            <AddSessionContainer sessionId={sessionId} sessionInfo={sessionInfo} saveSessionRequest={this.saveSessionRequest} history={history} />
        );
    }
}

const mapStateToProps = (state, props) => {
    // let's make it easier and don't render if we don't have these lists ...
    const loaded =
        isLoaded(state.firestore.data.feedList) &&
        isLoaded(state.firestore.data.gameList) &&
        isLoaded(state.firestore.data.playerList);

    // We have to keep in mind that a malicious user could manipulate this id, so it may not be valid.
    const sessionId = props.match.params.id;
    let sessionInfo = {};
    let mergedSession = {};
    let invalidSession = false;
    if (loaded) {
        invalidSession = !state.firestore.data.feedList || !state.firestore.data.feedList[sessionId];
        if (!invalidSession) {
            sessionInfo = state.firestore.data.feedList[sessionId];
            mergedSession = mergeData(
                [sessionInfo],
                state.firestore.data.playerList,
                state.firestore.data.gameList
            ).shift();
        }
    }

    const result = {
        isLoaded: loaded,
        isInvalidSession: invalidSession,
        sessionId,
        sessionInfo: mergedSession,
    };
    //console.warn(`ViewSessionContainer: `, result, props);
    return result;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect(),
    withHandlers({
        saveEditSession
    })
)(EditSession);