import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Alert from '../../components/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import GameImage from '../../components/GameImage';
import SessionTable from './SessionTable';
import './AddSession.css';
import * as routes from '../../constants/routes';

const styles = {
    textField: {
        //background: 'white'
    },
    deleteButton: {
        '&:hover': {
            background: 'red',
            color: 'white'
        }
    }
};

class AddSession extends React.Component {
    static propTypes = {
        // passed from either EditSession or NewSession
        sessionId: PropTypes.string,        // will only be passed by EditSession
        sessionInfo: PropTypes.object.isRequired,
        saveSessionRequest: PropTypes.func.isRequired,
        deleteSession: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired, // used when navigating after save

        userPlayerId: PropTypes.string,
        userFirstName: PropTypes.string, // might be null
        playerList: PropTypes.array.isRequired,     // used when adding a new player
        expansionsList: PropTypes.object.isRequired,    // used when adding a new game, to see if we need to add the expansion

        // Save state
        isSaving: PropTypes.bool.isRequired,
        isDeleting: PropTypes.bool.isRequired,
        isCompleted: PropTypes.bool.isRequired,
        lastSavedSessionId: PropTypes.string,       // only populated after a save operation

        // Callback functions
        saveStarted: PropTypes.func.isRequired,
        saveCompleted: PropTypes.func.isRequired,
        deleteStarted: PropTypes.func.isRequired,
        deleteCompleted: PropTypes.func.isRequired,
        saveCleared: PropTypes.func.isRequired,         // reset the save/delete status
        errorEncountered: PropTypes.func.isRequired
    };

    constructor(props, context) {
        super(props, context);

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleDismissError = this.handleDismissError.bind(this);
        this.handleSave = this.handleSave.bind(this);

        // copy the information passed in the sessionInfo into the data structures we need in our state
        this.state = {
            selectedGame: null,
            selectedExpansions: null,
            date: moment().format('YYYY-MM-DD'),
            location: '',
            error: null,

            scores: [], // id, firstName, position, score, isWinner
        };

        const { userPlayerId } = this.props;
        if (userPlayerId) {
            Object.assign(this.state, this.getPlayerAsRow());
        }

        this.initStateFromSessionInfo();
    }
    getPlayerAsRow() {
        const { userFirstName } = this.props;

        const lastChar = userFirstName[userFirstName.length - 1];
        return {
            location: lastChar === 's' ? `${userFirstName}' place` : `${userFirstName}'s place`,
            scores: [{ firstName: userFirstName, position: 1, score: 0, isWinner: false }]
        };
    }

    // called to init this.state with the info passed to us in sessionInfo
    // called only during constructor, separated out just for developer sanity
    initStateFromSessionInfo() {
        const { sessionInfo } = this.props;

        //console.error('STARTING SESSION INFO', sessionInfo);
        // Why not do a map with a sort at the end?
        let scores = [];
        Object.keys(sessionInfo.scores).forEach(key => {
            const score = sessionInfo.scores[key];
            scores.push({
                scoreId: score.scoreId,
                score: score.score,
                position: score.position,
                isWinner: score.isWinner,
                playerId: score.playerId,
                nickName: score.playerInfo.nickName,    // flatten it to make scores easier ...?  // TODO:  do we still need to do this?
            });
        });
        scores.sort((a, b) => a.position - b.position);
        /*sessionInfo.scores
            .sort((a, b) => a.position - b.position)
            .forEach(function(score) {
                scores.push({
                    scoreId: score.scoreId,
                    score: score.score,
                    position: score.position,
                    isWinner: score.isWinner,
                    playerId: score.playerId,
                    nickName: score.playerInfo.nickName,    // flatten it to make scores easier ...?  // TODO:  do we still need to do this?
                });
            });*/

        // Note:  this is an extension of the Constructor, so don't call this.setState(), use Object.assign(this.state, {..});
        Object.assign(this.state, {
            ownerId: sessionInfo.ownerId,
            selectedGame: sessionInfo.gameInfo,
            selectedExpansions: sessionInfo.expansions,
            scores,
            //date: sessionInfo.date.toDate().toISOString().split('T')[0],     // TODO:  I think I can remove this once we upgrade MaterialUI ... although I don't want the time portion, so maybe this is still necessary
            //date: sessionInfo.date.toDate().replace(/-/g, '\/').replace(/T.+/, ''),
            date: sessionInfo.date,
            location: sessionInfo.location
        });

        //console.log('initStateFromSessionInfo() --- updated state', JSON.stringify(this.state, null, 2), JSON.stringify(sessionInfo, null, 2));
    }

    // this is the reverse of the above function, it takes our state data and creates
    // what we will send to the server (for both new and edit)
    getSessionRequestObject() {
        const { userAuthId, sessionInfo } = this.props;
        const { ownerId, selectedGame, selectedExpansions, scores, date, location } = this.state;

        const scoresMap = {};
        scores.forEach(value => {
            scoresMap[value.playerId] = {
                position: value.position,
                score: value.score,
                playerId: value.playerId,
                isWinner: value.isWinner
            };
        });

        const requestObject = {
            uid: userAuthId,        // must be added to any session created or updated
            ownerId,
            date,
            location: location,
            gameId: selectedGame.id,
            expansions: selectedExpansions || null,
            lastUpdated: sessionInfo.lastUpdated,        // just copy this field from the previous session
            scores: scoresMap
        };

        //console.log('sessionRequestObject', requestObject, JSON.stringify(requestObject, null, 2));
        return requestObject;
    }

    /*componentDidMount() {
        const { loadActivityFeed, userPlayerId } = this.props;

        // populate our cache of data, in specific the playerlist
        //loadActivityFeed(userPlayerId);
    }*/

    componentDidUpdate(prevProps) {
        const { lastSavedSessionId, isSaving, isCompleted, isDeleting, saveCleared, history } = this.props;

        // if we were saving, and are completed, then we should navigate away!
        if (isSaving && isCompleted) {
            const path = routes.SESSION_INFO.replace(':id', lastSavedSessionId);
            saveCleared();      // reset the save/clear status
            history.replace(path);
        }
        if (isDeleting && isCompleted) {
            saveCleared();      // reset the save/clear status
            history.replace(routes.FEED);
        }
    }

    handleCancel = () => {
        this.props.history.goBack();
    };

    handleSave() {
        const { selectedGame, scores } = this.state;
        const { saveCleared, saveSessionRequest, saveStarted, saveCompleted, errorEncountered } = this.props;

        // clear the save before we try again
        saveCleared();

        // Validation
        if (!selectedGame) {
            this.setState({ error: 'You must select a game.' });
            return;
        }
        if (!scores || scores.length === 0) {
            this.setState({ error: 'You must select at least one player.' });
            return;
        }

        const requestObject = this.getSessionRequestObject();
        saveStarted();  // update redux state so that we know we are about to save stuff
        saveSessionRequest(requestObject, saveCompleted, errorEncountered); // this callback changes between EditSession and NewSession

        // clear any errors that may still be hanging around (doing at the end of the function to avoid redraws?)
        this.handleDismissError();
    }

    handleDeleteSession = () => {
        const { sessionId, deleteSession, deleteStarted, deleteCompleted, errorEncountered } = this.props;

        deleteStarted();
        deleteSession(sessionId, deleteCompleted, errorEncountered);

        this.handleDismissError();
    };

    handleDateChange(event) {
        this.setState({ date: event.target.value });
    }
    handleLocationChange(event) {
        this.setState({ location: event.target.value });
    }
    handleDismissError() {
        this.setState({ error: null });
    }

    handleUpateScore = (index, score) => {
        const { scores } = this.state;
        const newScores = scores.slice();

        if (index === -1) {
            newScores.push(score);
        } else {
            newScores[index] = score;
        }
        this.setState({ scores: newScores });
    };
    handleDeleteScore = index => {
        const { scores } = this.state;
        const newScores = scores.slice();
        newScores.splice(index, 1);
        this.setState({ scores: newScores });
    }

    handleSelectGame = gameInfo => {
        const { expansionsList, loadExpansions } = this.props;

        console.warn('handleSelectGame', gameInfo);

        if (!expansionsList[gameInfo.id]) {
            loadExpansions(gameInfo.id);
        }

        // TODO: need to switch here from the gameInfo that AllGames gave us, to the full game (what if it isn't in our feed yet?!?!?)

        this.setState({
            selectedGame: gameInfo,
            selectedExpansions: null
        });
    };

    handleSelectExpansions = expansionsList => {
        this.setState({
            selectedExpansions: expansionsList
        });
    };

    getSelectedExpansions = () => {
        const { selectedExpansions } = this.state;

        if (selectedExpansions) {
            const list = selectedExpansions.map((expansion, id) => {
                return (
                    <Typography key={id} variant="body1" color="primary">
                        {expansion.name}
                    </Typography>
                );
            });

            return (
                <React.Fragment>
                    <Typography variant="caption" color="primary">
                        with:{' '}
                    </Typography>
                    <div className="addSession_expansionslist">{list}</div>
                </React.Fragment>
            );
        }
    };

    render() {
        const { sessionId, playerList, classes, isSaving, isDeleting } = this.props;
        const { selectedGame, selectedExpansions, date, location, scores, error } = this.state;

        // The expansionsList gets updated asyncronously, so we check it dynamically on render to validate
        // whether a game has expansions.
        /*if (selectedGame && expansionsList[selectedGame.id]) {
            console.warn('This game now has expansions!');
        }*/

        //  Note:  unlike other modals, we always render the SelectGameModal, so that if the user opens -> closes -> re-opens, it
        //         goes back to the same "search state" (e.g. search list) that it had open previously.
        return (
            <div className="addSessionWrapper">
                {selectedGame ? (
                    <div className="sessionGame">
                        <GameImage game={selectedGame} />
                    </div>
                ) : (
                    ''
                )}
                <div className="addSession">
                    <div className="addSessionRow gameNameRow">
                        <Typography variant="h6" color="primary">
                            {selectedGame ? selectedGame.name : ''}
                        </Typography>
                        <div className="addSession_expansionsdiv">{this.getSelectedExpansions()}</div>
                    </div>
                    {error ? <Alert variant="error" onDismiss={this.handleDismissError} message={error} /> : ''}
                    <div className="addSessionRow">
                        <SessionTable
                            scores={scores}
                            playerList={playerList}
                            onUpdateScore={this.handleUpateScore}
                            onDeleteScore={this.handleDeleteScore}
                            selectedGame={selectedGame}
                            selectedExpansions={selectedExpansions}
                            onSelectGame={this.handleSelectGame}
                            onSelectExpansions={this.handleSelectExpansions}
                        />
                    </div>
                    <div className="addSessionRow dateRow">
                        <TextField
                            id="date"
                            variant="outlined"
                            margin="dense" /*fullWidth*/
                            label="Date"
                            type="date"
                            defaultValue={date}
                            className={classes.textField}
                            onChange={this.handleDateChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </div>
                    <div className="addSessionRow locationRow">
                        <TextField
                            margin="dense"
                            id="location"
                            type="text"
                            fullWidth
                            className={classes.textField}
                            variant="outlined"
                            label="Location"
                            value={location}
                            onChange={this.handleLocationChange}
                            inputProps={{ maxLength: 100 }}
                        />
                    </div>
                    <div className="buttonRow">
                        <div>
                            {sessionId ? (
                                <Button
                                    className={classes.deleteButton}
                                    variant="contained"
                                    onClick={this.handleDeleteSession}
                                    disabled={isSaving || isDeleting}
                                >
                                    {isDeleting ? 'Deleting...' : 'Delete'}
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    onClick={this.handleCancel}
                                >
                                    Cancel
                                </Button>
                            )}
                        </div>
                        <div>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.handleSave}
                                disabled={isSaving || isDeleting}
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(AddSession);
