// Used on each Route to validate access to that page.
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { firebase } from './firebase';
import * as routes from './constants/routes';

const withAuthorization = condition => Component => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            firebase.auth.onAuthStateChanged(authUser => {
                if (!condition(authUser)) {
                    this.props.history.push(routes.SIGN_IN);
                }
            });
        }

        render() {
            // need to pass props so that PageBody works :)
            // Good article on HOC:  https://medium.com/dailyjs/react-composing-higher-order-components-hocs-3a5288e78f55
            //return this.props.authUser ? <Component {...this.props}/> : null;
            return <Component {...this.props}/>;
        }
    }

    const mapStateToProps = state => ({
        // NOTE:  Switched this to wait until we have an AuthToken, not just an AuthUser.  This is 
        //        so that componentDidMount() functions that make service calls have a valid
        //        AuthToken to make the call.  No point in continuing rendering without that.
        //authUser: state.sessionState.authUser
        //authUser: state.sessionState.authToken
    });

    return compose(
        withRouter,
        connect(mapStateToProps)
    )(WithAuthorization);
};

export default withAuthorization;
