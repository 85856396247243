import { CALL_API } from '../middleware/havocapi';

/*
 * PLAYER_FRIENDS
 */
export const PLAYER_FRIENDS_REQUEST = 'PLAYER_FRIENDS_REQUEST';
export const PLAYER_FRIENDS_SUCCESS = 'PLAYER_FRIENDS_SUCCESS';
export const PLAYER_FRIENDS_FAILURE = 'PLAYER_FRIENDS_FAILURE';

export const playerFriendsRequest = () => {
    return {
        [CALL_API]: {
            types: [PLAYER_FRIENDS_REQUEST, PLAYER_FRIENDS_SUCCESS, PLAYER_FRIENDS_FAILURE],
            endpoint: `user/relationships`
        }
    };
};

/*
 * ADD_FOLLOW
 */
export const ADD_FOLLOW_REQUEST = 'ADD_FOLLOW_REQUEST';
export const ADD_FOLLOW_SUCCESS = 'ADD_FOLLOW_SUCCESS';
export const ADD_FOLLOW_FAILURE = 'ADD_FOLLOW_FAILURE';

export const addFollowRequest = (userId) => {
    return {
        [CALL_API]: {
            types: [ADD_FOLLOW_REQUEST, ADD_FOLLOW_SUCCESS, ADD_FOLLOW_FAILURE],
            endpoint: `user/addfollow`,
            requestData: { userId: userId }
        }
    };
};

/*
 * IGNORE_FOLLOW
 */
export const IGNORE_FOLLOW_REQUEST = 'IGNORE_FOLLOW_REQUEST';
export const IGNORE_FOLLOW_SUCCESS = 'IGNORE_FOLLOW_SUCCESS';
export const IGNORE_FOLLOW_FAILURE = 'IGNORE_FOLLOW_FAILURE';

export const ignoreFollowRequest = (userId) => {
    return {
        [CALL_API]: {
            types: [IGNORE_FOLLOW_REQUEST, IGNORE_FOLLOW_SUCCESS, IGNORE_FOLLOW_FAILURE],
            endpoint: `user/ignorefollow`,
            requestData: { userId: userId }
        }
    };
};
