import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { saveCleared, saveStarted, saveCompleted, deleteStarted, deleteCompleted, errorEncountered } from '../../redux/actions/actions';
import { expansionsLoaded } from '../../redux/actions/expansions';
import { loadExpansions, deleteSession } from '../../firebase/handlers';
import AddSession from './AddSession';

const mapStateToProps = state => {

    // Merge friends and outbound friend list, those are the players we can add to sessions
    const friendsList = Array.from(state.firestore.data.userRelationships.friends).concat(state.firestore.data.userRelationships.outboundFriendRequests);
    const playerList = friendsList.map(friendId => {
        return Object.assign({ id: friendId }, state.firestore.data.playerList[friendId]);
    });
    //console.warn(playerList);

    const result =  {
        // user info
        userAuthId: state.firebase.auth.uid,
        userPlayerId: state.firebase.profile.pid,
        userFirstName: state.firebase.profile.nickName,
        playerList: playerList,        // used when adding a new player

        // save state
        serverErrorMsg: state.addSession.errorMsg,
        serverIsSaving: state.addSession.isSaving,
        serverIsDeleting: state.addSession.isDeleting,
        //lastSavedSessionId: state.addSession.lastSavedSessionId,

        isSaving: state.saveState.isSaving,
        isCompleted: state.saveState.isCompleted,
        isDeleting: state.saveState.isDeleting,
        lastSavedSessionId: state.saveState.lastSavedSessionId,

        expansionsList: state.expansions        // This property is using in the loadExpansions handler below.
    };
    //console.log('AddSessionContainer.mapStateToProps', result);
    return result;
};

const mapDispatchToProps = dispatch => {
    return {
       // We add this function to properties, and it is used in the loadExpansions handler below.
       expansionsLoaded: expansions => dispatch(expansionsLoaded(expansions)),

       saveCleared: () => dispatch(saveCleared()),
       saveStarted: () => dispatch(saveStarted()),
       saveCompleted: (sessionId, sessionInfo) => dispatch(saveCompleted(sessionId, sessionInfo)),
       deleteStarted: () => dispatch(deleteStarted()),
       deleteCompleted: (sessionId) => dispatch(deleteCompleted(sessionId)),
       errorEncountered: (userMessage, devMessage) => dispatch(errorEncountered(userMessage, devMessage))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect(),
    firestoreConnect(),
    withHandlers({
        loadExpansions,     // requires expansionsLoaded in props
        deleteSession       // requires deleteCompleted() and errorEncountered() in props
    })
)(AddSession);
