import { ALL_GAMES_URL } from '../constants';

let AllGamesData = null;

// Making this an option for now ... would like to test with and without it for performance comparison
const caseInsensitive = true;

export const search = searchText => {
    if (!AllGamesData) {
        console.error('Games data not initialized yet!');
    }
    if (!searchText || searchText.length === 0) return [];

    // TODO:  rather than convert to uppercase each time, how about just store the uppercase in AllGamesData (e.g. convert once).

    let searchTerm = searchText;
    if (caseInsensitive) {
        searchTerm = searchText.toUpperCase();
    }

    const results = [];
    for (let i = 0; i < AllGamesData.length; i++) {
        const gameInfo = AllGamesData[i];

        let gameName = gameInfo.name;
        if (caseInsensitive) {
            gameName = gameName.toUpperCase();
        }

        if (gameName.includes(searchTerm)) {
            results.push(gameInfo);

            // Optimization: only return 20 items, can abort search
            if (results.length > 20) return results;
        }
    }
    return results;
    // return AllGames.filter(gameInfo => gameInfo.name.startsWith(searchTerm));
};

const AllGamesURL = ALL_GAMES_URL;

export const loadAllGamesData = async () => {
    if (AllGamesData) return; // only need to load it once

    console.time('Fetching AllGames');
    const response = await fetch(AllGamesURL);
    console.timeEnd('Fetching AllGames');
    console.time('Parsing AllGames');
    AllGamesData = await response.json(); //extract JSON from the http response
    console.timeEnd('Parsing AllGames');
};