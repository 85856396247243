import { CALL_API } from '../middleware/havocapi';
import { search } from '../../utils/ArrayUtils';
import { loadActivityFeedStatsThunk } from './stats';

/**
 * New Stuff!
 */
export const SAVE_CLEARED = 'SAVE_CLEARED';
export const SAVE_STARTED = 'SAVE_STARTED';
export const SAVE_COMPLETED = 'SAVE_COMPLETED';
export const DELETE_STARTED = 'DELETE_STARTED';
export const DELETE_COMPLETED = 'DELETE_COMPLETED'
export const saveCleared = () => {
    return {
        type: SAVE_CLEARED
    };
};
export const saveStarted = () => {
    return {
        type: SAVE_STARTED
    };
};
export const saveCompleted = (sessionId, sessionInfo) => {
    return {
        type: SAVE_COMPLETED,
        sessionId,
        sessionInfo
    };
};
export const deleteStarted = () => {
    return {
        type: DELETE_STARTED
    };
}
export const deleteCompleted = (sessionId) => {
    return {
        type: DELETE_COMPLETED,
        sessionId
    };
}

/*
 * USER_LOGOUT
 */
export const USER_LOGOUT_ACTION = 'USER_LOGOUT';
export const purgeUserInfo = () => {
    return { type: USER_LOGOUT_ACTION };
};

/*
 * TEST
 */
export const TEST_REQUEST = 'TEST_REQUEST';
export const TEST_SUCCESS = 'TEST_SUCCESS';
export const TEST_FAILURE = 'TEST_FAILURE';

export const testRequest = friendInfo => {
    return {
        [CALL_API]: {
            types: [TEST_REQUEST, TEST_SUCCESS, TEST_FAILURE],
            endpoint: 'games/test',
            requestData: friendInfo
        }
    };
};

/*
 * ADD_FRIEND
 */
export const ADD_FRIEND_REQUEST = 'ADD_FRIEND_REQUEST';
export const ADD_FRIEND_SUCCESS = 'ADD_FRIEND_SUCCESS';
export const ADD_FRIEND_FAILURE = 'ADD_FRIEND_FAILURE';

export const addFriendRequest = friendInfo => {
    return {
        [CALL_API]: {
            types: [ADD_FRIEND_REQUEST, ADD_FRIEND_SUCCESS, ADD_FRIEND_FAILURE],
            endpoint: 'players/new',
            requestData: friendInfo
        }
    };
};

// Thunk action that chains an AddFriend to a LoadMyknows
export const saveFriendThunk = friendInfo => dispatch => {
    //console.log('save a friend: entered!', friendInfo);

    // This doesn't work when we dispatch into the havocapi middleware.  We need
    // to use the thunk version which chains into promises the way we need.
    let response = dispatch(addFriendRequest(friendInfo));
    //let response = dispatch(havocThunk(addFriendRequest(friendInfo)));

    response.then(
        success => {
            return dispatch(userInfoRequest()); // player will come back on this list
        },
        error => {
            return error;
        }
    );
};

/*
 * ADD_TO_WISHLIST
 */
export const ADD_TO_WISHLIST_REQUEST = 'ADD_TO_WISHLIST_REQUEST';
export const ADD_TO_WISHLIST_SUCCESS = 'ADD_TO_WISHLIST_SUCCESS';
export const ADD_TO_WISHLIST_FAILURE = 'ADD_TO_WISHLIST_FAILURE';

export const addToWishlistRequest = gameInfo => {
    return {
        [CALL_API]: {
            types: [ADD_TO_WISHLIST_REQUEST, ADD_TO_WISHLIST_SUCCESS, ADD_TO_WISHLIST_FAILURE],
            endpoint: 'user/addtowishlist',
            requestData: gameInfo
        }
    };
};

/*
 * REMOVE_FROM_WISHLIST
 */
export const REMOVE_FROM_WISHLIST_REQUEST = 'REMOVE_FROM_WISHLIST_REQUEST';
export const REMOVE_FROM_WISHLIST_SUCCESS = 'REMOVE_FROM_WISHLIST_SUCCESS';
export const REMOVE_FROM_WISHLIST_FAILURE = 'REMOVE_FROM_WISHLIST_FAILURE';

export const removeFromWishlistRequest = gameInfo => {
    return {
        [CALL_API]: {
            types: [REMOVE_FROM_WISHLIST_REQUEST, REMOVE_FROM_WISHLIST_SUCCESS, REMOVE_FROM_WISHLIST_FAILURE],
            endpoint: 'user/removefromwishlist',
            requestData: gameInfo
        }
    };
};

/*
 * ADD_TO_OWNEDLIST
 */
export const ADD_TO_OWNEDLIST_REQUEST = 'ADD_TO_OWNEDLIST_REQUEST';
export const ADD_TO_OWNEDLIST_SUCCESS = 'ADD_TO_OWNEDLIST_SUCCESS';
export const ADD_TO_OWNEDLIST_FAILURE = 'ADD_TO_OWNEDLIST_FAILURE';

export const addToOwnedlistRequest = gameInfo => {
    return {
        [CALL_API]: {
            types: [ADD_TO_OWNEDLIST_REQUEST, ADD_TO_OWNEDLIST_SUCCESS, ADD_TO_OWNEDLIST_FAILURE],
            endpoint: 'user/addtoownedlist',
            requestData: gameInfo
        }
    };
};

/*
 * REMOVE_FROM_OWNEDLIST
 */
export const REMOVE_FROM_OWNEDLIST_REQUEST = 'REMOVE_FROM_OWNEDLIST_REQUEST';
export const REMOVE_FROM_OWNEDLIST_SUCCESS = 'REMOVE_FROM_OWNEDLIST_SUCCESS';
export const REMOVE_FROM_OWNEDLIST_FAILURE = 'REMOVE_FROM_OWNEDLIST_FAILURE';

export const removeFromOwnedlistRequest = gameInfo => {
    return {
        [CALL_API]: {
            types: [REMOVE_FROM_OWNEDLIST_REQUEST, REMOVE_FROM_OWNEDLIST_SUCCESS, REMOVE_FROM_OWNEDLIST_FAILURE],
            endpoint: 'user/removefromownedlist',
            requestData: gameInfo
        }
    };
};

/*
 * ADD_CUSTOM_GAME
 */
export const ADD_CUSTOM_GAME_REQUEST = 'ADD_CUSTOM_GAME_REQUEST';
export const ADD_CUSTOM_GAME_SUCCESS = 'ADD_CUSTOM_GAME_SUCCESS';
export const ADD_CUSTOM_GAME_FAILURE = 'ADD_CUSTOM_GAME_FAILURE';

export const addCustomGameRequest = gameInfo => {
    return {
        [CALL_API]: {
            types: [ADD_CUSTOM_GAME_REQUEST, ADD_CUSTOM_GAME_SUCCESS, ADD_CUSTOM_GAME_FAILURE],
            endpoint: 'games/addcustomgame',
            requestData: gameInfo
        }
    };
};

/*
 * SEARCH_GAMES
 */
export const SEARCH_GAMES_REQUEST = 'SEARCH_GAMES_REQUEST';
export const SEARCH_GAMES_SUCCESS = 'SEARCH_GAMES_SUCCESS';
export const SEARCH_GAMES_FAILURE = 'SEARCH_GAMES_FAILURE';

export const searchGamesRequest = searchTerm => {
    return {
        [CALL_API]: {
            types: [SEARCH_GAMES_REQUEST, SEARCH_GAMES_SUCCESS, SEARCH_GAMES_FAILURE],
            endpoint: `games/search/${searchTerm}`
        }
    };
};

/*
 * ADD_SESSION
 */
export const ADD_SESSION_REQUEST = 'ADD_SESSION_REQUEST';
export const ADD_SESSION_SUCCESS = 'ADD_SESSION_SUCCESS';
export const ADD_SESSION_FAILURE = 'ADD_SESSION_FAILURE';

/*
export const addSessionRequest = (sessionInfo) => {
    return {
        [CALL_API]: {
            types: [ ADD_SESSION_REQUEST, ADD_SESSION_SUCCESS, ADD_SESSION_FAILURE ],
            endpoint: 'sessions/new',
            requestData: sessionInfo
        }
    };
};

// Thunk action that chains an AddSession to re-loading the Activity Feed
export const addSessionThunk = (sessionInfo) => (dispatch, getState) => {

    let playerId = getState().userInfo.playerId;

    // now that we have Thunk out our promises, here we go:
    let response = dispatch(addSessionRequest(sessionInfo));
    //let response = dispatch(havocThunk(addFriendRequest(friendInfo)));

    response.then(
        success => {
            return dispatch(activityFeedRequest(playerId));
        },
        error => {
            return error;
        }
    );
}*/

/*
 * EDIT_SESSION
 */
export const EDIT_SESSION_REQUEST = 'EDIT_SESSION_REQUEST';
export const EDIT_SESSION_SUCCESS = 'EDIT_SESSION_SUCCESS';
export const EDIT_SESSION_FAILURE = 'EDIT_SESSION_FAILURE';

export const editSessionRequest = sessionInfo => {
    return {
        [CALL_API]: {
            types: [EDIT_SESSION_REQUEST, EDIT_SESSION_SUCCESS, EDIT_SESSION_FAILURE],
            endpoint: 'sessions/edit',
            requestData: sessionInfo
        }
    };
};

// Thunk action that chains an AddSession to re-loading the Activity Feed
export const editSessionThunk = sessionInfo => (dispatch, getState) => {
    let playerId = getState().userInfo.playerId;

    dispatch(editSessionRequest(sessionInfo)).then(
        success => {
            return dispatch(activityFeedRequest(playerId));
        },
        error => {
            return error;
        }
    );
};

/*
 * DELETE_SESSION
 */
export const DELETE_SESSION_REQUEST = 'DELETE_SESSION_REQUEST';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAILURE = 'DELETE_SESSION_FAILURE';

export const deleteSessionRequest = sessionId => {
    return {
        [CALL_API]: {
            types: [DELETE_SESSION_REQUEST, DELETE_SESSION_SUCCESS, DELETE_SESSION_FAILURE],
            endpoint: 'sessions/delete',
            requestData: { sessionId }
        }
    };
};

// Thunk action that chains an AddSession to re-loading the Activity Feed
export const deleteSessionThunk = sessionId => (dispatch, getState) => {
    let playerId = getState().userInfo.playerId;

    dispatch(deleteSessionRequest(sessionId)).then(
        success => {
            return dispatch(activityFeedRequest(playerId));
        },
        error => {
            return error;
        }
    );
};

/*
 * USER_INFO
 */
export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

export const userInfoRequest = () => {
    return {
        [CALL_API]: {
            types: [USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_INFO_FAILURE],
            endpoint: 'user'
        }
    };
};

// Fetches unless it is cached.
export const loadUserInfo = () => (dispatch, getState) => {
    if (getState().userInfo.playerId) {
        return null;
    }
    return dispatch(userInfoRequest());
};

/*
 * USER_GAMELIST
 */
export const USER_GAMELIST_REQUEST = 'USER_GAMELIST_REQUEST';
export const USER_GAMELIST_SUCCESS = 'USER_GAMELIST_SUCCESS';
export const USER_GAMELIST_FAILURE = 'USER_GAMELIST_FAILURE';

export const userGameListRequest = () => {
    return {
        [CALL_API]: {
            types: [USER_GAMELIST_REQUEST, USER_GAMELIST_SUCCESS, USER_GAMELIST_FAILURE],
            endpoint: 'user/gamelist'
        }
    };
};

export const loadGameList = () => (dispatch, getState) => {
    // as long as this isn't null, we should have loaded it at least once
    if (getState().userInfo.otherplayedlist) {
        return null;
    }
    return dispatch(userGameListRequest());
};

/*
 * RECENT_GAMES
 */
export const RECENT_GAMES_REQUEST = 'RECENT_GAMES_REQUEST';
export const RECENT_GAMES_SUCCESS = 'RECENT_GAMES_SUCCESS';
export const RECENT_GAMES_FAILURE = 'RECENT_GAMES_FAILURE';

export const recentGamesRequest = () => {
    return {
        [CALL_API]: {
            types: [RECENT_GAMES_REQUEST, RECENT_GAMES_SUCCESS, RECENT_GAMES_FAILURE],
            endpoint: 'players/recentgames'
        }
    };
};

// Fetches unless it is cached.
export const loadRecentGames = () => (dispatch, getState) => {
    let recentGamesList = getState().recentGames.recentGamesList;
    if (!recentGamesList || recentGamesList.length === 0) {
        return dispatch(recentGamesRequest());
    }
    return null;
};

/*
 * ACTIVITY_FEED
 */
export const ACTIVITY_FEED_REQUEST = 'ACTIVITY_FEED_REQUEST';
export const ACTIVITY_FEED_SUCCESS = 'ACTIVITY_FEED_SUCCESS';
export const ACTIVITY_FEED_FAILURE = 'ACTIVITY_FEED_FAILURE';

export const activityFeedRequest = playerId => {
    const endpoint = `players/feedbyplayer/${playerId}`;
    return {
        [CALL_API]: {
            types: [ACTIVITY_FEED_REQUEST, ACTIVITY_FEED_SUCCESS, ACTIVITY_FEED_FAILURE],
            endpoint: endpoint,
            requestId: playerId // havocapi will put this into the _SUCCESS and _FAILURE calls so we can associate it back
        }
    };
};

// TODO: how do we know if we should request an updated version??
// Fetches unless it is cached.
export const loadActivityFeed = playerId => (dispatch, getState) => {
    let feedList = getState().activityFeed.feedList;
    const feedInfo = search(feedList, playerId, 'playerId');
    if (!feedInfo) {
        return dispatch(activityFeedRequest(playerId));
    }
    return null;
};

/*
 * GAME_FEED
 */
export const GAME_FEED_REQUEST = 'GAME_FEED_REQUEST';
export const GAME_FEED_SUCCESS = 'GAME_FEED_SUCCESS';
export const GAME_FEED_FAILURE = 'GAME_FEED_FAILURE';

export const gameFeedRequest = gameId => {
    const endpoint = `games/sessions/${gameId}`;
    return {
        [CALL_API]: {
            types: [GAME_FEED_REQUEST, GAME_FEED_SUCCESS, GAME_FEED_FAILURE],
            endpoint: endpoint,
            requestId: gameId // havocapi will put this into the _SUCCESS and _FAILURE calls so we can associate it back
        }
    };
};
// Triple Thunk Action!
export const gameFeedThunk = gameId => (dispatch, getState) => {
    const playerId = getState().userInfo.playerId;
    dispatch(gameFeedRequest(gameId)).then(
        success => {
            // just load the stats for this player, and we will get the game stats from there
            dispatch(loadActivityFeedStatsThunk(playerId));
        },
        error => {
            return error;
        }
    );
};

// Fetches unless it is cached.
export const loadGameFeed = gameId => (dispatch, getState) => {
    let feedList = getState().gameFeed.feedList;
    const feedInfo = search(feedList, gameId, 'gameId');
    if (!feedInfo) {
        return dispatch(gameFeedThunk(gameId));
    }
    return null;
};

/*
 * GET_SESSION
 */
export const GET_SESSION_REQUEST = 'GET_SESSION_REQUEST';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAILURE = 'GET_SESSION_FAILURE';

export const getSessionRequest = sessionId => {
    return {
        [CALL_API]: {
            types: [GET_SESSION_REQUEST, GET_SESSION_SUCCESS, GET_SESSION_FAILURE],
            endpoint: `sessions/${sessionId}`
        }
    };
};

// Fetches unless it is cached.
export const loadSession = sessionId => (dispatch, getState) => {
    // shouldn't each caller check the cache? hmmm ...
    const sessionList = getState().getSession.sessionList;
    const sessionInfo = search(sessionList, sessionId, 'sessionId');
    if (!sessionInfo) {
        return dispatch(getSessionRequest(sessionId));
    }
    return null;
};

/*
 * GET_GAME
 *
 * This is an unusual Action.  It does not have its own reducer,
 * it uses the GAME_LIST reducer which listens to various Actions
 * and builds up a cache of game references.  If we can't find
 * a game in the cache, this Action will be kicked off.
 * (Happens during refresh on app/gameinfo views.)
 */
export const GET_GAME_REQUEST = 'GET_GAME_REQUEST';
export const GET_GAME_SUCCESS = 'GET_GAME_SUCCESS';
export const GET_GAME_FAILURE = 'GET_GAME_FAILURE';

export const getGameRequest = gameId => {
    return {
        [CALL_API]: {
            types: [GET_GAME_REQUEST, GET_GAME_SUCCESS, GET_GAME_FAILURE],
            endpoint: `games/${gameId}`,
            requestId: gameId
        }
    };
};

// Fetches unless it is cached.
export const loadGame = gameId => (dispatch, getState) => {
    const gameList = getState().listcache.gameList;
    const gameInfo = gameList[gameId];
    if (!gameInfo) {
        return dispatch(getGameRequest(gameId));
    }
    return null;
};

/*
 * action creators
 */
export const LOAD_FEED_REQUEST = 'LOAD_FEED_REQUEST';
export const LOAD_FEED_SUCCESS = 'LOAD_FEED_SUCCESS';
export const LOAD_FEED_FAILURE = 'LOAD_FEED_FAILURE';

export function loadFeedRequest() {
    return {
        type: LOAD_FEED_REQUEST
    };
}
export function loadFeedSuccess(feedData) {
    return {
        type: LOAD_FEED_SUCCESS,
        feedData
    };
}
export function loadFeedFailure(errorMsg) {
    return {
        type: LOAD_FEED_FAILURE,
        errorMsg
    };
}

/*
 * SYSTEM ERROR
 */
export const CLEAR_SYSTEM_ERROR = 'CLEAR_SYSTEM_ERROR';

export const clearSystemError = () => {
    return {
        type: CLEAR_SYSTEM_ERROR
    };
};

// New one added ... not sure how it fits in yet ...
export const ERROR_ENCOUNTERED = 'ERROR_ENCOUNTERED';
export const errorEncountered = (userMessage, devMessage) => {
    return {
        type: ERROR_ENCOUNTERED,
        userMessage,
        devMessage
    };
}
