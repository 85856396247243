import { connect } from 'react-redux';
import StatsList from './StatsList';

const mapStateToProps = (state, props) => {

    let gameId = props.gameId;
    let playerId = props.playerId;
    let showAllPlayers = false;     // for main user we show all players, for other players we only show them

    if (!playerId) {
        playerId = state.firebase.profile.pid;
        showAllPlayers = true;      // if no specific player was selected, show them all
    }

    // having a gameId instructs us to index stats by gameId, and takes precedence over playerId
    if (gameId) {
        playerId = null;
    }
   
    return {
        gameId,
        playerId,
        showAllPlayers,
        gameList: state.firestore.data.gameList,
        playerList: state.firestore.data.playerList
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

const StatsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(StatsList);

export default StatsListContainer;
