import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import SelectGameListItem from './SelectGameListItem';

const styles = theme => ({
    appBar: {
        position: 'relative'
    },
    flex: {
        flex: 1
    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} />
  ));

class SelectExpansionsModal extends Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        onSelectExpansions: PropTypes.func.isRequired,

        selectedGame: PropTypes.object.isRequired, // should never show this modal if we don't have a selectedGame
        selectedExpansions: PropTypes.array,
        expansionsList: PropTypes.array,        // The expansions for this game.
    };

    constructor(props) {
        super(props);

        this.state = {
            // It isn't a common practice to copy props into state, but since the user is editing, it makes sense here
            selectedExpansions: props.selectedExpansions || []
        };
    }

    handleExpansionClick = expansionInfo => {
        const { selectedExpansions } = this.state;

        // If it is already selected, then unselect it (toggle)
        // search array, remove if found, else append
        let found = false;
        const newExpansionList = selectedExpansions.filter(expansion => {
            if (expansion.id === expansionInfo.id) {
                found = true;
                return false;
            }
            return true;
        });
        if (!found) {
            newExpansionList.push(expansionInfo);
        }

        this.setState({
            selectedExpansions: newExpansionList
        });
    };

    handleSaveClicked = () => {
        const { onSelectExpansions } = this.props;
        const { selectedExpansions } = this.state;

        onSelectExpansions(selectedExpansions);
    };

    // This is O(n^2), which is not great, but the lists should be short, so it should be fine.
    // Alternative would be to create a duplicate of the object passed in the prop in the constructor,
    // maintain a "isSelected" flag, and return a filtered list.  Which is doable, but more work.  :)
    // Solution 2:  use an array of booleans whose indexes match ...
    expansionIsSelected(expansion) {
        const { selectedExpansions } = this.state;
        for (let i = 0; i < selectedExpansions.length; i++) {
            if (selectedExpansions[i].id === expansion.id) {
                return true;
            }
        }
        return false;
    }

    getExpansionsList() {
        const { expansionsList } = this.props;

        return expansionsList && expansionsList
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(expansion => {
                return (
                    <React.Fragment key={expansion.id}>
                        <SelectGameListItem
                            selected={this.expansionIsSelected(expansion)}
                            gameInfo={expansion}
                            onClick={this.handleExpansionClick}
                        />
                        <Divider />
                    </React.Fragment>
                );
            });
    }

    render() {
        const { classes, open, onCancel } = this.props;

        return (
            <Dialog fullScreen open={open} onClose={onCancel} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={onCancel} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Edit
                        </Typography>
                        <Button color="inherit" onClick={this.handleSaveClicked}>
                            Add Expansions
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>{this.getExpansionsList()}</List>
            </Dialog>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SelectExpansionsModal);
