import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { firebaseConnect, firestoreConnect, isLoaded } from 'react-redux-firebase';
import FriendsList from './FriendsList';


// given an array of ids, and a player list, it will create an array of objects representing those players
// if it can't find a player in the playerList it will skip that player (assuming that it is loading ...)
const constructPlayerList = (arrayIds, playerList) => {
    if (!arrayIds || !playerList) return [];
    
    const result = [];
    arrayIds.forEach(playerId => {
        if (playerList[playerId]) {
            result.push(Object.assign({}, playerList[playerId], { id: playerId }));
        } else {
            console.error('Could not find information for playerId: ', playerId);
        }
    });
    return result;
}

const mapStateToProps = state => {

    const userRelationships = state.firestore.data.userRelationships || {};
    const playersList = state.firestore.data.playerList || [];

    return {
        userId: state.firebase.auth.uid,        // This is here for requests back to firebase within FriendsList
        isLoaded:  isLoaded(state.firestore.data.userRelationships),

        friendsList: constructPlayerList(userRelationships.friends, playersList),
        incomingFriendRequests: constructPlayerList(userRelationships.incomingFriendRequests, playersList),
        outboundFriendRequests: constructPlayerList(userRelationships.outboundFriendRequests, playersList),
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firebaseConnect(),
    firestoreConnect(),
    withHandlers({
    })
)(FriendsList);
