class TwoDimMap {

    constructor() {
        this.root = {};
    }

    addObj(key1, key2, value) {
        if (this.root[key1]) {
            if (this.root[key1][key2]) {
                throw new Error('Duplicate keys used');
            } else {
                this.root[key1][key2] = value;
            }
        } else {
            this.root[key1] = {};
            this.root[key1][key2] = value;
        }
    }

    getRoot() {
        return this.root;
    }
}

export default TwoDimMap;