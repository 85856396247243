import Login from './Login';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';

const mapStateToProps = state => {
    return {
        isLoading: !isLoaded(state.firebase.auth),
        isLoggedIn: !isEmpty(state.firebase.auth)
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firebaseConnect()   // this is here to put firebase on the properties, enabling us to call props.firebase.login() ...
)(Login);

