// Modelled after redux example code:
// https://github.com/reduxjs/redux/blob/master/examples/real-world/src/middleware/api.js
import { API_ROOT } from '../../constants';

//import firebase from 'firebase/app';

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';

// Note:  also defined in ErrorLogger.js, should pull out commonly
// const API_ROOT = 'http://localhost:8080/api/';

// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint, accessToken, data) => {
    const fullUrl = endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

    if (data) {
        // "POST" request
        return fetch(fullUrl, {
            headers: {
                'X-Firebase-Auth': accessToken,
                'content-type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        }).then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }

                return json;
            })
        );
    } else {
        // "GET" request
        return fetch(fullUrl, {
            headers: {
                'X-Firebase-Auth': accessToken
            }
        }).then(response =>
            response.json().then(json => {
                if (!response.ok) {
                    return Promise.reject(json);
                }

                return json;
            })
        );
    }
};

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
    const callAPI = action[CALL_API];
    //console.log('havocapi: ', action);
    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    // endpoint - where we are going
    // requestData - any POST data we need
    // requestId - information we need in the SUCCESS/FAILURE call to let us map it back to the request we made (feed request is an example)
    let { endpoint, requestData, requestId } = callAPI;
    const { types } = callAPI;

    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState());
    }
    if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.');
    }
    if (!Array.isArray(types) || types.length !== 3) {
        throw new Error('Expected an array of three action types.');
    }
    if (!types.every(type => typeof type === 'string')) {
        throw new Error('Expected action types to be strings.');
    }

    const accessToken = store.getState().sessionState.authToken;
    if (!accessToken) {
        throw new Error('Must have auth token.');
    }

    // we pull the accessToken from firebase
    // NOTE:  see withAuthentication and onIdTokenChanged() for more info.
/*    return firebase
        .auth()
        .currentUser.getIdToken(/*true)   // not necessary to force refresh each time, firebase will update it if necessary
        .then(function(accessToken) {
            if (!accessToken) {
                throw new Error('Must have accessToken.');
            }
*/
            const actionWith = data => {
                const finalAction = Object.assign({}, action, data);
                delete finalAction[CALL_API];
                return finalAction;
            };

            const [requestType, successType, failureType] = types;

            // Dispatch the ..._REQUEST
            const requestInfo = { type: requestType };
            if (requestData) {
                requestInfo['requestData'] = requestData;
            }
            next(actionWith(requestInfo));

            return callApi(endpoint, accessToken, requestData).then(
                response => {
                    return Promise.resolve(next(
                        actionWith({
                            response,
                            type: successType,
                            requestId: requestId
                        }))
                    );
                },
                error => {
                    // We can't use "//return Promise.reject(next(" below, since
                    // redux-thunk doesn't actually handle Promise.reject.  Here is 
                    // a good article on it:  http://thecodebarbarian.com/async-await-with-react-and-redux-thunk
                    // This also isn't a "failure", since we are building to support _FAILURE
                    // cases right from the start.  Our UI needs to handle possible failures,
                    // and if get a failure here, then there should already be an error on the server
                    // so we don't need to send additional logging about it.
                    return Promise.resolve(next(
                        actionWith({
                            type: failureType,
                            status: error.status,
                            error: error.error,
                            message: error.message,
                            errorMsg: error.message || error || 'Something bad happened',
                            requestId: requestId
                        }))
                    );
                }
            );
        //});
};

// A thunk version that I used to debug Promise chains.
/*
export function havocThunk(action) {

    return function(dispatch) {
        const callAPI = action[CALL_API];
        //console.log('havoc THUNK: ', action);
        if (typeof callAPI === 'undefined') {
            throw new Error('Invalid action parameter.');
        }

        // endpoint - where we are going
        // requestData - any POST data we need
        // requestId - information we need in the SUCCESS/FAILURE call to let us map it back to the request we made (feed request is an example)
        let { endpoint, requestData, requestId } = callAPI;
        const { types } = callAPI;

        if (typeof endpoint !== 'string') {
            throw new Error('Specify a string endpoint URL.');
        }
        if (!Array.isArray(types) || types.length !== 3) {
            throw new Error('Expected an array of three action types.');
        }
        if (!types.every(type => typeof type === 'string')) {
            throw new Error('Expected action types to be strings.');
        }

        // we pull the accessToken from firebase
        return firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function(accessToken) {
                //let accessToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjM1OWJjZGNmZTY2MmQzZjFjMDlkZTFjYmEzMGQ5OWY3ZjRmOThkNjkiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZmlyLWF1dGhzdGFydGVyLTk3YmJmIiwiYXVkIjoiZmlyLWF1dGhzdGFydGVyLTk3YmJmIiwiYXV0aF90aW1lIjoxNTQxODk3OTQ0LCJ1c2VyX2lkIjoieEVTSTZGMjFhR01kdTN2YmZaMFNtZXZpZHBuMSIsInN1YiI6InhFU0k2RjIxYUdNZHUzdmJmWjBTbWV2aWRwbjEiLCJpYXQiOjE1NDI0ODYxNDgsImV4cCI6MTU0MjQ4OTc0OCwiZW1haWwiOiJ0aW0ucGhpbGlwQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInRpbS5waGlsaXBAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.i3Sfdpi10e8tojeVZCnicq7NKA2Rl43f3Qjw4S7RDc3lPOgXTVTFFXQjyEsxIMLE6W_jON8o9mKmW4DDF8pvNOX0SOXbCW6-7WIjn_ILiNS1m1dJr7GpZNg2_PBrqGx0KoFNt-X4Oau7-RPuuWlQBMbDg6kF5leBqp95h_wRyzxji1jarjGECVtt34GTP0fPODf6NVOiPu1tQLnq2x1fODB3Zg-XGp7nFXCexNfHh_MhaEUL1ZdN1KkjN9IHjg8STLsAk9S3Xm4_duQzrtMYp3GOeDlvfb2Ap9Z8MtGmGyEQsriD633lSIjo3HYpdGiI2Fz7x82NikqA8P5DxyQsrw";
                if (!accessToken) {
                    throw new Error('Must have accessToken.');
                }

                const actionWith = data => {
                    const finalAction = Object.assign({}, action, data);
                    delete finalAction[CALL_API];
                    return finalAction;
                };

                const [requestType, successType, failureType] = types;

                // Dispatch the ..._REQUEST
                const requestInfo = { type: requestType };
                if (requestData) {
                    requestInfo['requestData'] = requestData;
                }
                dispatch(actionWith(requestInfo));

                return callApi(endpoint, accessToken, requestData).then(
                    response => {
                        return Promise.resolve(dispatch(
                            actionWith({
                                response,
                                type: successType,
                                requestId: requestId
                            }))
                        )},
                    error => {
                        return Promise.reject(dispatch(
                            actionWith({
                                type: failureType,
                                errorMsg: error.message || error || 'Something bad happened',
                                requestId: requestId
                            })));
                    }
                );
            });
        };
};
*/