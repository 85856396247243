import { connect } from 'react-redux';
import { mergeData } from '../../utils/FeedMerge';
import ViewSession from './ViewSession';

const mapStateToProps = (state, props) => {
    // For new users these lists are all null
    const feedList = state.firestore.data.feedList || {};
    const playerList = state.firestore.data.playerList || {};
    const gameList = state.firestore.data.gameList || {};

    const lastSavedSessionId = state.saveState.lastSavedSessionId;
    const lastSavedSession = state.saveState.lastSavedSession;

    // We have to keep in mind that a malicious user could manipulate this id, so it may not be valid.
    // We also need to keep in mind that after a save/edit/delete, it can take several seconds for the cloud
    // function to propogate the data from /session to /feed.  So we cover for it through some complicated logic here.
    const sessionId = props.match.params.id;
    let sessionInfo = {};
    let mergedSession = {};
    let discussionExists = false;

    let invalidSession = !feedList[sessionId];
    if (!invalidSession) {
        sessionInfo = feedList[sessionId];

        // The discussion listener will fail if we start it before the discussion is created by the cloud function.
        // So we delay loading it until we get the feed information updated.
        discussionExists = true;

        // If we just edited this session, then it has more up to date information ...
        if (sessionId === lastSavedSessionId) {
            if (lastSavedSession.lastUpdated.seconds === sessionInfo.lastUpdated.seconds) {
                // the update will increment the lastUpdated time
                sessionInfo = lastSavedSession;
            }
        }

        mergedSession = mergeData([sessionInfo], playerList, gameList).shift();
    } else if (lastSavedSession) {
        if (sessionId === lastSavedSessionId) {
            invalidSession = false;
            mergedSession = mergeData([lastSavedSession], playerList, gameList).shift();
        }
    }

    const result = {
        isInvalidSession: invalidSession,
        discussionExists,
        sessionId,
        sessionInfo: mergedSession,
        userPlayerId: state.firebase.profile.pid
    };
    //console.warn(`ViewSessionContainer: `, result, props);
    return result;
};

const mapDispatchToProps = dispatch => {
    return {};
};

const ViewSessionContainer = connect(mapStateToProps, mapDispatchToProps)(ViewSession);

export default ViewSessionContainer;
