import React from 'react';

class About extends React.Component {

  responseGoogle(googleUser) {
    var id_token = googleUser.getAuthResponse().id_token;
    var googleId = googleUser.getId();

    console.log({googleId});
    console.log({accessToken: id_token});
  }

  render() {
    return (
      <div>
        <h1>About</h1>
        <p>This is some basic text that is really long to see if it will show up</p>
      </div>
    );
  }
}

export default About;
