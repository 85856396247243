import React from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

function FTUInit(props) {
    return (
        <div className="ftu">
            <div className="ftu_row">
                <Typography variant="h5" color="secondary">
                    Please wait while we set up your new account ...
                </Typography>
            </div>
            <div className="ftu_row">
                <LinearProgress />
            </div>
        </div>
    );
}

export default FTUInit;
