import React from 'react';
import Typography from '@material-ui/core/Typography';
import StatsBox from './StatsBox';

export const TYPE_BYGAME = 'BY_GAME';
export const TYPE_BYPLAYER = 'BY_PLAYER';

function StatsList(props) {
    const { type, gameId, playerId, stats, gameList, playerList, showAllPlayers } = props;

    // A gameId means we are displaying game information and playerId should be null
    // use this to do the game layout.

    const statsList = stats;
    if (!statsList) {
        return 'Calculating...';
    }

    // TODO:  Ordering!
    //  - order game list by most played
    //  - order player lists by most played ... highest win-rate ... ?

    const list = [];
    let index = 0;
    for (var key1 in statsList) {
        if (statsList.hasOwnProperty(key1)) {
            if (type === TYPE_BYPLAYER && !showAllPlayers) {
                // if we are on a player page, then the only player we should see is the current one
                if (playerId && key1 !== `${playerId}`) {
                    // our keys are strings
                    continue;
                }
            }

            const listPlayerStats = [];

            let gameInfo = null;
            let playerInfo = null;
            // if we are iterating byGameId, then the game is always the same
            // ditto if byPlayerId.  We gather both and pass to StatsBox, and
            // give it the TYPE to pick the correct one.
            for (var key2 in statsList[key1]) {
                if (statsList[key1].hasOwnProperty(key2)) {
                    const stats = statsList[key1][key2];
                    gameInfo = stats.gameInfo;
                    playerInfo = stats.playerInfo;
                    listPlayerStats.push(stats);
                }
            }

            if (type === TYPE_BYPLAYER) {
                // we have to add the id into the object when coming from the lookup
                playerInfo = Object.assign({ id: key1 }, playerList[key1]);
            } else {
                gameInfo = Object.assign({ id: key1 }, gameList[key1]);
            }

            list.push(
                <StatsBox
                    key={index++}
                    type={type}
                    gameInfo={gameInfo}
                    playerInfo={playerInfo}
                    listPlayerStats={listPlayerStats}
                    includeImages={gameId == null}
                />
            );
        }
    }
    if (list.length === 0) {
        list.push(<Typography key="0" variant="body1" color="primary">No games.</Typography>);
    }

    return <div className="statslist">{list}</div>;
}

export default StatsList;
