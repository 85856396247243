import { connect } from 'react-redux';
import { mergeData } from '../utils/FeedMerge';
import { generateStats } from '../utils/GenerateStats';
import ViewGame from './ViewGame';

const mapStateToProps = (state, props) => {
    // Converting to a Number is important, or equality won't work (our 'search' function won't find it)
    const gameId = props.match.params.id; //Number(props.match.params.id);       // player id should come from the link (via Route)

    // TODO:  the gameId comes as a param - it could be hacked with, need to handle invalid state (just like view session)

    const feedList = state.firestore.ordered.feedList || [];
    const gameList = state.firestore.data.gameList || {};
    const playerList = state.firestore.data.playerList || {};

    // Filter the feed to only this game ...
    const filteredFeed = feedList.filter(sessionInfo => sessionInfo.gameId === gameId);
    const mergedFilteredFeed = mergeData(filteredFeed, playerList, gameList);
    const activityFeedStats = generateStats(mergedFilteredFeed, gameList, playerList);
    const gameInfo = gameList[gameId];

    return {
        gameId: gameId,
        activityFeed: mergedFilteredFeed,
        activityFeedStats,
        gameInfo,
    };
}

const mapDispatchToProps = dispatch => {
    return {
    };
}

const ViewGameContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewGame);

export default ViewGameContainer;
