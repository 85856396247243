import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GoogleButton from './GoogleButton';
import FacebookButton from './FacebookButton';
import * as routes from '../constants/routes';
import Loading from '../components/Loading';

export class Login extends Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        isLoggedIn: PropTypes.bool.isRequired,
        firebase: PropTypes.shape({
            login: PropTypes.func.isRequired
            //logout: PropTypes.func.isRequired
        })
        //clearFirestore: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            loggingIn: false,
            error: null
        };
    }

    // This is here to support multiple tabs.  The firebase client detects that you are now authenticated,
    // and updates its' state.  We add a bunch of extra logic to make sure we aren't navigating during a normal
    // login event (e.g. the user clicked the button) where we want our callbacks to complete first.
    componentDidUpdate(prevProps) {
        const { isLoggedIn, history } = this.props;
        const { loggingIn } = this.state;

        if (!prevProps.isLoggedIn && isLoggedIn && !loggingIn) {
            history.push(routes.FEED);
        }
    }

    handleClickGoogle = () => {
        // TODO:  we no longer have the extra "createNewUser()" call going to havoc, so we can probably change this now ...

        // Note:  If you change from popup to redirect, then the createNewUser() call below won't happen,
        //        since "redirect" means we are no longer on our page.  The user will just return in an 
        //        authenticated state.
        //        We can probably change the redirect return address to solve this, but let's not get into that yet.
        this.doLogin({ provider: 'google', type: 'popup' }); // 'popup' or 'redirect'
    };

    handleClickFacebook = () => {
        this.doLogin({ provider: 'facebook', type: 'popup' });
    };

    doLogin = loginProps => {
        const { history } = this.props;

        // We set this here so that componentDidUpdate() doesn't navigate us while we are processing the login.
        // The firebase login events will update our loggedin state before we have a chance to complete the
        // callbacks below (in particular, createNewUser).
        this.setState({
            loggingIn: true
        });

        this.props.firebase
            .login(loginProps)
            .then(response => {
                history.push(routes.FEED);      // Let's go home!
            })
            .catch(error => {
                console.error('Error:', error);
                this.setState({
                    ...this.state,
                    loggingIn: false,
                    error
                });
            });
    };

    render() {
        const { isLoading, isLoggedIn } = this.props;
        const { loggingIn } = this.state;

        if (isLoading) {
            return (
                <div className="login">
                    <h3>Loading ...</h3>
                </div>
            );
        }
        if (!isLoggedIn) {
            return (
                <div className="login">
                    <GoogleButton onClick={this.handleClickGoogle} />
                    <FacebookButton onClick={this.handleClickFacebook} />
                </div>
            );
        }
        if (isLoggedIn && !loggingIn) {
            return <React.Fragment>Logging in ...<Loading /></React.Fragment>
        }
        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

export default withRouter(Login); // withRouter history to props
