import { createMuiTheme } from '@material-ui/core/styles';

/*
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: pink,
        background: {
            default: "#fff"
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 25,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    }
});
*/
const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#0E2339'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // ?? light: '#555555',  Try putting Maija's colors here??
            main: '#D0D0D0'
            // dark: will be calculated from palette.secondary.main,
            // contrastText:
        },
        // error: will use the default color
        /*
        error: {
            light: palette.error[300],
            main: palette.error[500],
            dark: palette.error[700],
            contrastText: getContrastText(palette.error[500]),
        },
        */
        background: {
            default: '#ffffff'
        }
    },
    /*    typography: {
        fontFamily: '"Comic Sans',
        fontSize: 25,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    }*/
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 16,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    },
    /*  Neither of these work, hacked it below.
    overrides: {
        MuiButton: { // Name of the component ⚛️ / style sheet
            text: { // Name of the rule
                color: 'white', // Some CSS
                textTransform: "lowercase"
            },
        },
        button: {
            textTransform: "lowercase"
        }
    }*/
});

// ha!  There's probably a way to do this via overrides, but I haven't figured it out yet.  This works.
theme.typography.button.textTransform = '';//"lowercase";

export default theme;
