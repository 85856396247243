//export const API_ROOT = 'http://35.185.215.14:8080/api/';
export const API_ROOT = 'https://api.meeples.app/api/';

// The ideal would be ...
// export const API_ROOT = 'https://api.meeples.com/v1/';

export const IMAGE_BASE_URL = 'https://images.meeples.app/';
export const IMAGE_THUMBNAIL_URL = 'https://images.meeples.app/thumbnail200/';

//export const ALL_GAMES_URL = 'https://firebasestorage.googleapis.com/v0/b/dockertest-231522.appspot.com/o/AllGames.compressed.js?alt=media&token=60a70fe9-fbe8-4a3d-8ac3-e91dcafc84a5';
export const ALL_GAMES_URL = 'https://assets.meeples.app/AllGames.compressed.js';
//export const ALL_GAMES_URL = 'https://assets.meeples.app/AllGames2.compressed.js';