import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import "./FacebookButton.css";

function FacebookButton(props) {
    const { onClick } = props;


    return (
        <button onClick={onClick} className="facebook_btn">
            <span className="facebook_icon_wrapper">
                <FontAwesomeIcon icon={faFacebookSquare} fixedWidth size="2x"/>
            </span>
            <span className="facebook_text">Sign in with Facebook</span>
        </button>
    );
}

export default FacebookButton;