import {combineReducers} from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import sessionReducer from './session';
import { USER_LOGOUT_ACTION } from '../actions/actions';
import { customGame, addFriendResponse, searchGames,addSession,userInfo,recentGames,activityFeed,gameFeed, getSession } from './games';
import errorReducer from './errors';
import relationshipsReducer from './relationships';
import statsReducer from './stats';
import listcache from './listcache';
import expansions from './expansions';
import saveState from './savestate';
import gamelist from './gamelist';

const appReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    searchGames,
    customGame,
    addFriendResponse,
    addSession,
    userInfo,
    recentGames,
    activityFeed,
    gameFeed,
    getSession,
    sessionState: sessionReducer,
    errorState: errorReducer,
    friendsState: relationshipsReducer,
    statsState: statsReducer,
    listcache,
    expansions,
    saveState,
    gamelist
});

// Special support to purge/reset the redux store when the user logs out
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
export const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT_ACTION) {
        state = undefined
    }
  
    return appReducer(state, action)
}

export const initialState = {};
