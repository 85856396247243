import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PlayerAvatar from './PlayerAvatar';

class SelectPlayerListItem extends Component {
    static propTypes = {
        playerInfo: PropTypes.object.isRequired,
        selected: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired
    };

    handleClick = () => {
        const { playerInfo, onClick } = this.props;

        onClick(playerInfo);
    };

    render() {
        const { playerInfo, selected } = this.props;

        //console.warn(`SelectPlayerListItem: `, playerInfo);

        return (
            <ListItem button selected={selected} onClick={this.handleClick}>
                <ListItemAvatar>
                    <PlayerAvatar playerId={playerInfo.id} nolink/>
                </ListItemAvatar>
                <ListItemText primary={playerInfo.nickName} />
            </ListItem>
        );
    }
}

export default SelectPlayerListItem;
