import * as actions from '../actions/actions';

const INITIAL_STATE = {

    // errors like this we can recover from
    supportableError: {
        userMessage: null,
        devMessage: null
    },

    // errors like this we cannot recover from (e.g. auth outage)
    fatalError: {
        error: null,
        message: null,
        status: null
    }
};

function errorReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case actions.ERROR_ENCOUNTERED:
            return Object.assign({}, {
                supportableError: {
                    userMessage: action.userMessage,
                    devMessage: action.devMessage,
                },
                fatalError: {
                    ...state.fatalError
                }
        });

        // fatalError cases:
        case actions.USER_INFO_FAILURE:
        return Object.assign({}, {
            fatalError: {
                error: action.error,
                message: action.message,
                status: action.status
            },
            supportableError: {
                ...state.supportableError
            }
        });


        // supportableError cases:
        case actions.ADD_FRIEND_FAILURE:
            return Object.assign({}, {
                supportableError: {
                    error: action.error,
                    message: action.message,
                    status: action.status
                },
                fatalError: {
                    ...state.fatalError
                }
        });

        // upon success we clear out the error ...
        case actions.USER_INFO_SUCCESS:
        case actions.ADD_FRIEND_SUCCESS:
            return Object.assign({}, INITIAL_STATE);

        case actions.CLEAR_SYSTEM_ERROR:
            return Object.assign({}, INITIAL_STATE);

        default: {
            // Let's create some generic catch here so that we don't have to put in every
            // singe darn error.
            if (action.type.endsWith("_FAILURE")) {
                return Object.assign({}, {
                    supportableError: {
                        error: action.error,
                        message: action.message,
                        status: action.status
                    },
                    fatalError: {
                        ...state.fatalError
                    }
                });

            } else if (action.type.endsWith("_SUCCESS")) {
                return Object.assign({}, INITIAL_STATE);
            }

            // otherwise we don't care
            return state;
        }
    }
}

export default errorReducer;
