import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { firebaseConnect, firestoreConnect, isLoaded } from 'react-redux-firebase';
import { addToWishlist, removeFromWishlist, addToOwnedlist, removeFromOwnedlist } from '../../firebase/handlers';
import Loading from '../../components/Loading';
import Button from '@material-ui/core/Button';
import Rater from 'react-rater'; // https://www.npmjs.com/package/react-rater
import GameImage from '../../components/GameImage';


export class GameInfoTab extends Component {
    static propTypes = {
        isLoaded: PropTypes.bool.isRequired,
        gameId: PropTypes.string.isRequired,
        gameInfo: PropTypes.object.isRequired,
        isOnWishlist: PropTypes.bool.isRequired,
        isOnOwnedlist: PropTypes.bool.isRequired,

        // Methods
        addToWishlist: PropTypes.func.isRequired,
        removeFromWishlist: PropTypes.func.isRequired,
        addToOwnedlist: PropTypes.func.isRequired,
        removeFromOwnedlist: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.onClickAddToWishlist = this.onClickAddToWishlist.bind(this);
        this.onClickRemoveFromWishlist = this.onClickRemoveFromWishlist.bind(this);
        this.onClickAddToOwnedlist = this.onClickAddToOwnedlist.bind(this);
        this.onClickRemoveFromOwnedlist = this.onClickRemoveFromOwnedlist.bind(this);
    }

    onClickAddToWishlist() {
        const { gameInfo, addToWishlist } = this.props;
        
        addToWishlist(gameInfo);
    }
    onClickRemoveFromWishlist() {
        const { gameInfo, removeFromWishlist } = this.props;
        
        removeFromWishlist(gameInfo);
    }

    onClickAddToOwnedlist() {
        const { gameInfo, addToOwnedlist } = this.props;
        
        addToOwnedlist(gameInfo);
    }
    onClickRemoveFromOwnedlist() {
        const { gameInfo, removeFromOwnedlist } = this.props;
        
        removeFromOwnedlist(gameInfo);
    }

    render() {
        const { isLoaded, gameInfo, isOnWishlist, isOnOwnedlist } = this.props;

        if (!isLoaded) {
            return <Loading />;
        }
        let wishlist = <div><Button color="inherit" onClick={this.onClickAddToWishlist}>Add to Wishlist</Button></div>;
        if (isOnWishlist) {
            wishlist = <div><Button color="inherit" onClick={this.onClickRemoveFromWishlist}>Remove from Wishlist</Button></div>;
        }
        let ownedlist = <Button color="inherit" onClick={this.onClickAddToOwnedlist}>I own this game</Button>;
        if (isOnOwnedlist) {
            ownedlist = <Button color="inherit" onClick={this.onClickRemoveFromOwnedlist}>Oops, I don't own this game</Button>;
            wishlist = '';  // don't need to show wishlist link
        }

        return (
            <div>
                <div className="gameActivityHeader">
                    <div className="gameActivityHeader_child">&nbsp;</div>
                    <div className="gameActivityHeader_child">
                        <div className="div_gameimage"><GameImage game={gameInfo} /></div>
                        <Rater total={5} rating={2} interactive={true} />
                        {wishlist}
                    </div>
                    <div className="gameActivityHeader_child gameActivityHeader_right">
                        {ownedlist}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {

    const { gameId } = props;

    const isOnList = (gameId, list) => {
        if (!gameId || !list) return false;
        return list.find(element => element === gameId) != null;
    }

    //const gameInfo = state.listcache.gameList[gameId];
    //const isOnWishlist = search(state.userInfo.wishlist, gameId, 'gameId') != null;
    //const isOnOwnedlist = search(state.userInfo.ownedlist, gameId, 'gameId') != null;

    const loaded = isLoaded(state.firebase.profile) && isLoaded(state.firestore.data.gameList);
    const gameInfo = isLoaded(state.firestore.data.gameList) ? state.firestore.data.gameList[gameId] : {};
    const isOnWishlist = isOnList(gameId, state.firebase.profile.gamesWishlist);
    const isOnOwnedlist = isOnList(gameId, state.firebase.profile.gamesOwnedlist);

    const result = {
        isLoaded: loaded,
        gameId,
        gameInfo: Object.assign({id: gameId}, gameInfo),    // since we get this from firestore.data, we need to add the id field in
        isOnWishlist,
        isOnOwnedlist,

        // Required as properties for the handlers below
        userId: isLoaded(state.firebase.auth) ? state.firebase.auth.uid : '',
    };
    //console.warn('GameInfoTab', JSON.stringify(result, null, 2));
    return result;
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    firebaseConnect(),
    firestoreConnect(),
    withHandlers({
        addToWishlist,
        removeFromWishlist,
        addToOwnedlist,
        removeFromOwnedlist
    })
)(GameInfoTab);
