import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import { auth } from '../../firebase';
import ErrorLogger from '../../utils/ErrorLogger';
import './Settings.css';

const styles = theme => ({
    root: {
        width: '100%'
    },
    lineHeight: {
        lineHeight: '2em'
    }
});

class Settings extends React.Component {
    static propTypes = {
        userInfo: PropTypes.object.isRequired,

        purgeUserInfo: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.onClickLogout = this.onClickLogout.bind(this);
        this.onClickTest = this.onClickTest.bind(this);
    }

    onClickLogout = () => {
        const { purgeUserInfo } = this.props;

        purgeUserInfo();
        auth.doSignOut();
    };

    onClickTest() {
        const { firestore } = this.props;

        ErrorLogger(new Error('foo'));

        // /feeds/jMDHBoS8l3SIFhc2tPqgUYZBgbo1/sessions/RHsQlufdcHPfAzWrkXoQ
        return (
            firestore
                .collection('feeds')
                .doc('jMDHBoS8l3SIFhc2tPqgUYZBgbo1')
                .collection('sessions')
                .where(`scores.jHNFiIq5oo87OseSABRv.playerId`, '==', 'jHNFiIq5oo87OseSABRv')
                // this one works .where(`newScores.jHNFiIq5oo87OseSABRv.score`, '>=', 0)
                .get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        const sessionInfo = doc.data();
                        console.warn('sessionInfo', sessionInfo);
                    });

                    console.warn('Get anything???');

                    // We made it!
                    return true;
                }).catch(error => {
                    ErrorLogger(error);
                })
        );
    }

    createTestRow() {
        if (process.env.NODE_ENV === 'development') {
            return (
                <React.Fragment>
                    {this.createRow(
                        3,
                        <div>
                            <Button variant="outlined" color="primary" onClick={this.onClickTest}>
                                Run Test
                            </Button>
                            <div className="logotesttoo" />
                        </div>
                    )}
                    {this.createRow(
                        4,
                        <div>
                            Boo!
                        </div>
                    )}
                </React.Fragment>
            );
        } else {
            return '';
        }
    }

    createFontRows() {
        if (process.env.NODE_ENV === 'development') {
            return (
                <div className="settings settings_typography">
                    {[
                        'inherit',
                        'srOnly',
                        'button',
                        'caption',
                        'body1',

                        'body1',
                        'subtitle1',
                        'h6',
                        'h5',
                        'h4',
                        'h3',
                        'h2',
                        'h1'
                    ].map((variant, index) => {
                        return this.createRow(
                            index,
                            <Typography variant={variant} color="inherit">
                                {variant}
                            </Typography>
                        );
                    })}
                </div>
            );
        }
    }

    createRow(index, leftContent, rightContent) {
        return (
            <div key={index} className="settings_row">
                <div>{leftContent}</div>
                <div>{rightContent}</div>
            </div>
        );
    }

    render() {
        const { classes, userInfo } = this.props;

        if (!userInfo) {
            return 'Loading ..';
        }

        return (
            <div className="settingsWrapper">
                <div className="settings">
                    {this.createRow(
                        1,
                        <div>
                            <Typography variant="subtitle1" color="inherit" className={classes.lineHeight}>
                                You are logged in as {userInfo.nickName ? userInfo.nickName : ''}
                            </Typography>
                            <Typography variant="caption" color="inherit">
                                {userInfo.email ? userInfo.email : ''}
                            </Typography>
                        </div>,
                        <Button variant="outlined" color="primary" onClick={this.onClickLogout}>
                            Logout
                        </Button>
                    )}
                    {this.createTestRow()}
                </div>
                {this.createFontRows()}
            </div>
        );
    }
}

/*
                    {this.createRow(
                        2,
                        <div>
                            <Typography variant="subtitle1" color="inherit" className={classes.lineHeight}>
                                Import my Board Game Geek games
                            </Typography>
                            <Typography variant="caption" color="inherit">
                                (Not implemented yet, coming soon!)
                            </Typography>
                        </div>,
                        <Switch disabled value="checked" />
                    )}
*/

export default withStyles(styles)(Settings);
